import { Fragment, useState } from 'react'
import { VolleyballIcon } from '../../assets/SvgIcons'
import { useClubCardIndexesData } from '../../hooks/useClubsData'
import classNames from '../../utils/classNames'
import { Link, useNavigate } from 'react-router-dom'
import { Listbox, Transition } from '@headlessui/react'
import { CardIndexT } from '../../models/CardIndex'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import getSeason from '../../utils/getSeason'

const ageGroups = [
  {
    id: 1,
    name: 'Детски волейбол - момчета',
    sex: 0,
    age: 11,
  },
  {
    id: 2,
    name: 'Мини волейбол - момчета',
    sex: 0,
    age: 12,
  },
  {
    id: 3,
    name: 'Момчета под 14 г.',
    sex: 0,
    age: 13,
  },
  {
    id: 4,
    name: 'Момчета под 16 г.',
    sex: 0,
    age: 15,
  },
  {
    id: 5,
    name: 'Момчета под 18 г.',
    sex: 0,
    age: 17,
  },
  {
    id: 6,
    name: 'Юноши',
    sex: 0,
    age: 19,
  },
  {
    id: 7,
    name: 'Мъже',
    sex: 0,
    age: 255,
  },
  {
    id: 8,
    name: 'Детски волейбол - момичета',
    sex: 1,
    age: 11,
  },
  {
    id: 9,
    name: 'Мини волейбол - момичета',
    sex: 1,
    age: 12,
  },
  {
    id: 10,
    name: 'Момичета под 14 г.',
    sex: 1,
    age: 13,
  },
  {
    id: 11,
    name: 'Момичета под 16 г.',
    sex: 1,
    age: 15,
  },
  {
    id: 12,
    name: 'Момичета под 18 г.',
    sex: 1,
    age: 17,
  },
  {
    id: 13,
    name: 'Девойки',
    sex: 1,
    age: 19,
  },
  {
    id: 14,
    name: 'Жени',
    sex: 1,
    age: 255,
  },
]

type ClubCardIndexesProps = {
  clubId: string
}

const ClubCardIndexes = ({ clubId }: ClubCardIndexesProps) => {
  const [selectedYear, setSelectedYear] = useState(getSeason)
  const navigate = useNavigate()
  const {
    isError,
    data: ClubCardIndexes,
    isLoading,
  } = useClubCardIndexesData(clubId)

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (isError) {
    return <div>Error fetching club card indexes</div>
  }

  if (!ClubCardIndexes) {
    return <div>Players not found</div>
  }

  const getUniqueYears = (indexes: CardIndexT[]) => {
    const uniqueYears = [] as number[]

    indexes.forEach((object) => {
      const year = object.year
      if (!uniqueYears.includes(year)) {
        uniqueYears.push(year)
      }
    })

    return uniqueYears
  }

  return (
    <>
      <section className='relative mt-10 rounded-lg bg-white px-4 pb-10 shadow sm:px-6'>
        <header className='-mx-6 flex items-center justify-between border-b border-gray-200 p-6 py-5'>
          <div className='inline-flex items-center'>
            <VolleyballIcon />
            <h3 className='text-2xl font-medium leading-6 text-gray-900'>
              Картотеки
            </h3>
          </div>
          <div className='inline-flex items-center'>
            <div className='top-16 w-72'>
              <Listbox value={selectedYear} onChange={setSelectedYear}>
                <div className='relative mt-1'>
                  <Listbox.Button className='relative w-full cursor-default rounded-lg border bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm'>
                    <span className='block truncate'>
                      Сезон {selectedYear} / {selectedYear + 1}
                    </span>
                    <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
                      <ChevronUpDownIcon
                        className='h-5 w-5 text-gray-400'
                        aria-hidden='true'
                      />
                    </span>
                  </Listbox.Button>
                  <Transition
                    as={Fragment}
                    leave='transition ease-in duration-100'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                  >
                    <Listbox.Options className='absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
                      {getUniqueYears(ClubCardIndexes).map((year, idx) => (
                        <Listbox.Option
                          key={idx}
                          className={({ active }) =>
                            `relative cursor-default select-none py-2 pl-10 pr-4 ${
                              active
                                ? 'bg-amber-100 text-amber-900'
                                : 'text-gray-900'
                            }`
                          }
                          value={year}
                        >
                          {({ selected }) => (
                            <>
                              <span
                                className={`block truncate ${
                                  selected ? 'font-medium' : 'font-normal'
                                }`}
                              >
                                Сезон {year} / {year + 1}
                              </span>
                              {selected ? (
                                <span className='absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600'>
                                  <CheckIcon
                                    className='h-5 w-5'
                                    aria-hidden='true'
                                  />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </Listbox>
            </div>
          </div>
          <div className='mt-4 sm:mt-0 sm:ml-16 sm:flex-none'>
            <button
              type='button'
              className='inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto'
              onClick={() =>
                navigate('/cardindexes/create', {
                  state: { clubId: parseInt(clubId) },
                })
              }
            >
              Добави нов лиценз
            </button>
          </div>
        </header>

        <div className='-mx-4 mt-6 ring-1 ring-gray-300 sm:mx-0 sm:rounded-lg'>
          <table className='min-w-full divide-y divide-gray-300'>
            <thead>
              <tr className='divide-x'>
                <th
                  scope='col'
                  className='w-1/3 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:table-cell lg:w-1/4'
                >
                  Възрастова група
                </th>
                <th
                  scope='col'
                  className='hidden px-3 py-3.5 text-center text-sm font-semibold text-gray-900 lg:table-cell'
                >
                  Сезон {selectedYear - 1}/{selectedYear}
                </th>
                <th
                  scope='col'
                  className='px-3 py-3.5 text-center text-sm  font-semibold text-gray-900'
                >
                  Сезон {selectedYear}/{selectedYear + 1}
                </th>
              </tr>
            </thead>
            <tbody>
              {ageGroups.map((ageGroup) => {
                const prevYearIndexes = ClubCardIndexes.filter(
                  (ci) =>
                    ci.sex === ageGroup.sex &&
                    ci.age === ageGroup.age &&
                    ci.year === selectedYear - 1,
                )
                const currentYearIndexes = ClubCardIndexes.filter(
                  (ci) =>
                    ci.sex === ageGroup.sex &&
                    ci.age === ageGroup.age &&
                    ci.year === selectedYear,
                )

                return (
                  <tr
                    key={ageGroup.id}
                    className={classNames(
                      ageGroup.id !== 0 ? '' : 'border-t border-transparent',
                      'divide-x border-t border-gray-200',
                    )}
                  >
                    <td className='px-3 py-3.5 text-sm sm:pl-6'>
                      <div className='font-medium text-gray-900'>
                        {ageGroup.name}
                      </div>
                    </td>
                    <td className='hidden px-3 py-3.5 text-center text-sm text-gray-500 lg:table-cell'>
                      {prevYearIndexes.length > 0
                        ? prevYearIndexes.map((ci) => (
                            <Fragment key={ci.id}>
                              <Link
                                className='hover:text-blue-500'
                                to={`/cardindexes/${ci.id}`}
                              >
                                Лиценз #{ci.id}
                              </Link>
                              <br />
                            </Fragment>
                          ))
                        : '-'}
                    </td>
                    <td className='px-3 py-3.5 text-center text-sm text-gray-500'>
                      {currentYearIndexes.length > 0
                        ? currentYearIndexes.map((ci) => (
                            <Fragment key={ci.id}>
                              <Link
                                className='hover:text-blue-500'
                                to={`/cardindexes/${ci.id}`}
                              >
                                Лиценз #{ci.id}
                              </Link>
                              <br />
                            </Fragment>
                          ))
                        : '-'}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </section>
    </>
  )
}

export default ClubCardIndexes
