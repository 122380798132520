import classNames from '../../../utils/classNames'
import { RadioGroup } from '@headlessui/react'
import { CheckCircleIcon } from '@heroicons/react/20/solid'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

const transferTypes = [
  {
    id: 0,
    title: 'Смяна на клубната принадлежност',
    description:
      'При смяната на клубна принадлежност, състезателните права преминават към новия клуб окончателно.',
    users: '',
  },
  {
    id: 1,
    title: 'Временно преотстъпване',
    description:
      'При временното преотстъпване, състезателните права преминават към посочения клуб за определен период от време.',
    users: '',
  },
]

const TransferTypeSelect = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const [selectedTransferType, setSelectedTransferType] = useState(
    {} as (typeof transferTypes)[number],
  )

  useEffect(() => {
    if (selectedTransferType) {
      setSearchParams((prev) => {
        prev.set('type', String(selectedTransferType.id))
        if (selectedTransferType.id === 1) {
          prev.delete('expiration')
        }
        return Array.from(prev.entries())
      })
    }
  }, [selectedTransferType])

  return (
    <>
      <RadioGroup
        value={selectedTransferType}
        onChange={setSelectedTransferType}
      >
        <RadioGroup.Label className='text-base font-semibold leading-6 text-gray-900'>
          Изберете вид на трансфера
        </RadioGroup.Label>

        <div className='mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4'>
          {transferTypes.map((transferType) => (
            <RadioGroup.Option
              key={transferType.id}
              value={transferType}
              className={({ active }) =>
                classNames(
                  active
                    ? 'border-indigo-600 ring-2 ring-indigo-600'
                    : 'border-gray-300',
                  'relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none',
                )
              }
            >
              {({ checked, active }) => (
                <>
                  <span className='flex flex-1'>
                    <span className='flex flex-col'>
                      <RadioGroup.Label
                        as='span'
                        className='block text-sm font-medium text-gray-900'
                      >
                        {transferType.title}
                      </RadioGroup.Label>
                      <RadioGroup.Description
                        as='span'
                        className='mt-1 flex items-center text-sm text-gray-500'
                      >
                        {transferType.description}
                      </RadioGroup.Description>
                      <RadioGroup.Description
                        as='span'
                        className='mt-6 text-sm font-medium text-gray-900'
                      >
                        {transferType.users}
                      </RadioGroup.Description>
                    </span>
                  </span>
                  <CheckCircleIcon
                    className={classNames(
                      !checked ? 'invisible' : '',
                      'h-5 w-5 text-indigo-600',
                    )}
                    aria-hidden='true'
                  />
                  <span
                    className={classNames(
                      active ? 'border' : 'border-2',
                      checked ? 'border-indigo-600' : 'border-transparent',
                      'pointer-events-none absolute -inset-px rounded-lg',
                    )}
                    aria-hidden='true'
                  />
                </>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
    </>
  )
}

export default TransferTypeSelect
