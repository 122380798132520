import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { coachService } from '../services/CoachService'
import { notify } from '../components/shared/Notification'
import { useNavigate } from 'react-router'

export function useCoachesData() {
  return useQuery({
    queryKey: ['fetch-coaches'],
    queryFn: coachService.getAll,
  })
}

export function useCoachData(id: string) {
  return useQuery({
    queryKey: ['fetch-coach', id],
    queryFn: () => coachService.getById(id),
  })
}

export function useAddCoach() {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (coach: FormData) => coachService.addCoach(coach),
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ['fetch-coaches'] })
      notify.success('Треньорът е добавен успешно')
      navigate('/coaches/' + res.id)
    },
    onError: (err) => {
      console.log(err)
      notify.error('Възникна грешка при добавянето на треньора!')
    },
  })
}

export function useUpdateCoach() {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (coach: FormData) => coachService.updateCoach(coach),
    onSuccess: (res, variables) => {
      const id = variables.get('id')
      queryClient.invalidateQueries({ queryKey: ['fetch-coach', id] })
      notify.success('Треньорът е променен успешно')
      navigate('/coaches/' + id)
    },
    onError: (err) => {
      console.log(err)
      notify.error('Възникна грешка при промяната на треньора!')
    },
  })
}

export function useCoachEducationLevelsData() {
  return useQuery({
    queryKey: ['fetch-coach-education-levels'],
    queryFn: coachService.getEducationLevels,
  })
}

export function useCoachCardIndexesData(id: number) {
  return useQuery({
    queryKey: ['fetch-coach-card-indexes', id],
    queryFn: () => coachService.getCardIndexes(id),
  })
}

export function useCoachEducations(id: string) {
  return useQuery({
    queryKey: ['fetch-coach-educations', id],
    queryFn: () => coachService.getEducations(id),
  })
}

export function useAddCoachEducation() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (coach: FormData) => coachService.addEducation(coach),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['fetch-coach-educations'],
      })
      notify.success('Документът за образование е добавен успешно')
    },
    onError: (err) => {
      console.log(err)
      notify.error(
        'Възникна грешка при добавяне на документ за образование на треньора!',
      )
    },
  })
}
