import { Switch } from '@headlessui/react'
import { EmailIcon, SpinningCircle } from '../../assets/SvgIcons'
import { useResetUserPasswordData } from '../../hooks/useUsersData'

type PasswordResetProps = {
  userId: string
}

const PasswordReset = ({ userId }: PasswordResetProps) => {
  const passwordReset = useResetUserPasswordData()

  const handlePasswordReset = () => {
    passwordReset.mutate(userId)
  }

  return (
    <div className='bg-white shadow sm:rounded-lg'>
      <div className='px-4 py-5 sm:p-6'>
        <h3 className='text-base font-semibold leading-6 text-gray-900'>
          Генериране на нова парола
        </h3>
        <div className='mt-2 sm:flex sm:items-start sm:justify-between'>
          <div className='max-w-xl text-sm text-gray-500'>
            <p>
              Системата ще генерира нова парола за потребителя и ще му я изпрати
              по имейл.
            </p>
          </div>
          <div className='mt-5 sm:ml-6 sm:mt-0 sm:flex sm:flex-shrink-0 sm:items-center'>
            <button
              type='button'
              className='inline-flex items-center justify-center gap-1 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:cursor-wait disabled:bg-gray-300'
              onClick={() => handlePasswordReset()}
              disabled={passwordReset.isLoading}
            >
              {passwordReset.isLoading ? (
                <>
                  <SpinningCircle /> <span>Изпращане...</span>
                </>
              ) : (
                <>
                  <EmailIcon /> <span>Изпрати</span>
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PasswordReset
