import { Form, Input } from '../../core/ui/TextField'
import Button from '../../core/ui/Button'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  PlayerUpdateFormSchema,
  PlayerUpdateFormSchemaType,
} from '../../../models/FormDataSchemas'

type PlayerUpdateFormProps = {
  initialData: PlayerUpdateFormSchemaType
  onSubmit: (data: PlayerUpdateFormSchemaType) => void
}

const formElements = [
  { name: 'firstName', label: 'Име', type: 'text' },
  { name: 'middleName', label: 'Презиме', type: 'text' },
  { name: 'lastName', label: 'Фамилия', type: 'text' },
  { name: 'egn', label: 'ЕГН', type: 'text' },
  { name: 'nationality', label: 'Националност', type: 'text' },
  { name: 'cityName', label: 'Град', type: 'text' },
]

const PlayerUpdateForm = ({ initialData, onSubmit }: PlayerUpdateFormProps) => {
  const validation = zodResolver(PlayerUpdateFormSchema)

  return (
    <Form<PlayerUpdateFormSchemaType>
      onSubmit={onSubmit}
      initialData={initialData}
      resolver={validation}
    >
      {({ register, formState: { errors, isSubmitting } }) => (
        <>
          <div className='grid grid-cols-6 gap-6'>
            {formElements.map((element, idx) => (
              <div key={idx} className='col-span-6'>
                <Input
                  {...register(
                    element.name as keyof PlayerUpdateFormSchemaType,
                  )}
                  type={element.type}
                  name={element.name}
                  label={element.label}
                  error={
                    errors[element.name as keyof PlayerUpdateFormSchemaType]
                      ?.message
                  }
                />
              </div>
            ))}

            <Button type='submit' disabled={isSubmitting}>
              Запази
            </Button>
          </div>
        </>
      )}
    </Form>
  )
}

export default PlayerUpdateForm
