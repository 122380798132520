import { useMemo, MouseEvent } from 'react'
import {
  IPlayerTransfer,
  IPlayerTransferProgress,
  IPlayerTransferStatus,
} from '../../models/PlayerTransfer'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  Row,
  useReactTable,
} from '@tanstack/react-table'
import { useNavigate } from 'react-router'
import fuzzyFilter from '../../utils/fuzzyFilter'
import TableImage from '../shared/TableImage'
import { Link } from 'react-router-dom'
import { LinkIcon } from '../../assets/SvgIcons'
import Pagination from '../Players/Pagination'
import TransferStatus from './TransferStatus'
import TransferProgress from './TransferProgress'
import TransferType from './TransferType'
import TransferDate from './TransferDate'

type TransferTableProps = {
  data: IPlayerTransfer[]
  filter: string
  setFilter: React.Dispatch<React.SetStateAction<string>>
}

const TransferTable: React.FC<TransferTableProps> = ({
  data,
  filter,
  setFilter,
}) => {
  const columnVisibility = {
    id: false,
    playerId: false,
    playerPhotoId: false,
    playerNumber: false,
  }
  const columnHelper = createColumnHelper<IPlayerTransfer>()
  const navigate = useNavigate()

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.id, {
        id: 'id',
      }),
      columnHelper.accessor((row) => row.player?.id, {
        id: 'playerId',
      }),
      columnHelper.accessor((row) => row.player?.photoId, {
        id: 'playerPhotoId',
      }),
      columnHelper.accessor((row) => row.player?.number, {
        id: 'playerNumber',
      }),

      columnHelper.accessor(
        (row) => `${row.player?.firstName} ${row.player?.lastName}`,
        {
          id: 'playerName',
          header: () => <span>Състезател</span>,
          cell: (info) => (
            <div className='flex gap-3 font-normal text-gray-900'>
              <div className='relative h-10 w-10'>
                <TableImage
                  className='h-full w-full rounded-full object-cover object-center text-gray-300'
                  imageType='person'
                  photoId={info.row.getValue('playerPhotoId')}
                />
              </div>
              <div className='text-sm'>
                <div className='peer flex cursor-pointer gap-2 text-gray-700 hover:text-blue-500'>
                  <Link to={`/players/${info.row.getValue('playerId')}`}>
                    <div className='font-medium transition-colors'>
                      {info.renderValue()}
                    </div>
                  </Link>
                  <Link to={`/players/${info.row.getValue('playerId')}`}>
                    <div className='rounded-full bg-blue-50 p-1 text-blue-500 transition-colors hover:bg-blue-500 hover:text-white'>
                      <LinkIcon />
                    </div>
                  </Link>
                </div>
                <div className='text-gray-400'>
                  СЕК Номер: {info.row.getValue('playerNumber')}
                </div>
              </div>
            </div>
          ),
        },
      ),

      columnHelper.accessor((row) => row.type, {
        id: 'type',
        header: () => <span>Вид на трансфера</span>,
        cell: (info) => {
          return <TransferType type={info.getValue()} />
        },
      }),

      columnHelper.accessor(
        (row) =>
          new Object({
            startDate: row.startDate,
            endDate: row.endDate,
            isConfirmed:
              row.isConfirmedByAdmin &&
              row.isConfirmedByPlayer &&
              row.isConfirmedByFromClub &&
              row.isConfirmedByToClub,
            type: row.type,
          }) as IPlayerTransferStatus,
        {
          id: 'status',
          header: () => <span>Статус</span>,
          cell: (info) => {
            return <TransferStatus data={info.getValue()} />
          },
        },
      ),

      columnHelper.accessor(
        (row) =>
          new Object({
            isConfirmedByAdmin: row.isConfirmedByAdmin,
            isConfirmedByPlayer: row.isConfirmedByPlayer,
            isConfirmedByFromClub: row.isConfirmedByFromClub,
            isConfirmedByToClub: row.isConfirmedByToClub,
          }) as IPlayerTransferProgress,
        {
          id: 'progress',
          header: () => <span>Потвърден</span>,
          cell: (info) => {
            return <TransferProgress data={info.getValue()} />
          },
        },
      ),

      columnHelper.accessor(
        (row) =>
          new Object({
            date: row.startDate,
            type: row.type,
          }) as { date: Date; type: number },
        {
          id: 'startDate',
          header: () => <span>От</span>,
          cell: (info) => <TransferDate data={info.getValue()} />,
        },
      ),

      columnHelper.accessor(
        (row) =>
          new Object({
            date: row.startDate,
            type: row.type,
          }) as { date: Date; type: number },
        {
          id: 'endDate',
          header: () => <span>До</span>,
          cell: (info) => <TransferDate data={info.getValue()} />,
        },
      ),

      columnHelper.accessor((row) => row.fromClub, {
        id: 'fromClub',
        header: () => <span>От клуб</span>,
        cell: (info) => {
          const clubId = info.getValue()?.id
          if (!clubId) return '-'
          return (
            <Link to={`/club/${clubId}`}>
              <span className='cursor-pointer rounded-full bg-blue-50 px-3 py-2 text-xs font-semibold text-blue-500 hover:underline'>
                {info.getValue()?.fullName}
              </span>
            </Link>
          )
        },
      }),

      columnHelper.accessor((row) => row.toClub, {
        id: 'toClub',
        header: () => <span>В Клуб</span>,
        cell: (info) => {
          const clubId = info.getValue()?.id
          if (!clubId) return '-'
          return (
            <Link to={`/club/${clubId}`}>
              <span className='cursor-pointer rounded-full bg-blue-50 px-3 py-2 text-xs font-semibold text-blue-500 hover:underline'>
                {info.getValue()?.fullName}
              </span>
            </Link>
          )
        },
      }),
    ],
    [columnHelper],
  )

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnVisibility,
      globalFilter: filter,
    },
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  })

  const handleClick = (e: MouseEvent, row: Row<IPlayerTransfer>) => {
    e.preventDefault()
    if (e.detail === 2) {
      navigate(`/transfers/${row.original.id}`)
    }
  }

  return (
    <div className='mt-8 flex flex-col'>
      <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
        <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
          <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
            <table className='w-full border-collapse border-b border-gray-100 bg-white text-left text-sm text-gray-500'>
              <thead className='bg-gray-50'>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <th
                        scope='col'
                        className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                        key={header.id}
                      >
                        {header.isPlaceholder ? null : (
                          <div
                            {...{
                              className: header.column.getCanSort()
                                ? 'cursor-pointer select-none'
                                : '',
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                            {{
                              asc: ' 🔼',
                              desc: ' 🔽',
                            }[header.column.getIsSorted() as string] ?? null}
                          </div>
                        )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody className='divide-y divide-gray-100 border-t border-gray-200 bg-white'>
                {data.length === 0 ? (
                  <tr className='p-2 text-center'>
                    <td
                      className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'
                      colSpan={5}
                    >
                      Няма намерени трансфери
                    </td>
                  </tr>
                ) : (
                  table.getRowModel().rows.map((row) => (
                    <tr
                      key={row.id}
                      className='cursor-pointer hover:bg-gray-50'
                      onClick={(e) => handleClick(e, row)}
                    >
                      {row.getVisibleCells().map((cell) => (
                        <td
                          key={cell.id}
                          className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </td>
                      ))}
                    </tr>
                  ))
                )}
              </tbody>
            </table>
            <Pagination table={table} data={data} displayPages={6} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default TransferTable
