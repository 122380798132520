import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { clubService } from '../services/ClubService'
import { notify } from '../components/shared/Notification'
import { ISearchInput } from '../models/BaseModels'
import { useNavigate } from 'react-router'

export function useClubsSearchData(userSearchInput: ISearchInput) {
  const { keyword, page, pageSize, orderBy, desc } = userSearchInput
  return useQuery({
    queryKey: ['search-clubs', keyword, page, pageSize, orderBy, desc],
    queryFn: () =>
      clubService.get({
        page,
        pageSize,
        orderBy,
        desc,
        keyword,
      }),
    onError(err) {
      notify.error('Възникна грешка! Моля, опитайте отново по-късно.')
      console.error(err)
    },
  })
}

export function useClubsData() {
  return useQuery({
    queryKey: ['fetch-clubs'],
    queryFn: clubService.getAll,
  })
}

export function useClubData(id: string) {
  return useQuery({
    queryKey: ['fetch-club', id],
    queryFn: () => clubService.getById(id),
  })
}

export function useClubCreate() {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (club: FormData) => clubService.addClub(club),
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ['fetch-clubs'] })
      notify.success('Клубът е добавен успешно')
      navigate('/clubs/' + res.id)
    },
    onError: (err) => {
      console.log(err)
      notify.error('Възникна грешка при добавянето на клуба!')
    },
  })
}

export function useClubUpdate(id: string) {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (club: FormData) => clubService.update(id, club),
    onSuccess: () => {
      queryClient.invalidateQueries([
        { queryKey: ['fetch-club', id] },
        { queryKey: ['fetch-clubs'] },
      ])
      notify.success('Клубът е обновен успешно!')
      navigate('/clubs/' + id)
    },
    onError: (err) => {
      console.log(err)
      notify.error('Възникна грешка при обновяването на клуб!')
    },
  })
}

export function useClubPlayersData(id: string) {
  return useQuery({
    queryKey: ['fetch-club-players', id],
    queryFn: () => clubService.getClubPlayers(id),
  })
}

export function useClubOptionsData() {
  return useQuery({
    queryKey: ['fetch-club-options'],
    queryFn: clubService.getClubOptions,
  })
}

export function useCoachOptionsData(clubId: number) {
  return useQuery({
    queryKey: ['fetch-coach-options', clubId],
    queryFn: () => clubService.getClubCoachOptions(clubId),
  })
}

export function useClubCardIndexesData(clubId: string) {
  return useQuery({
    queryKey: ['fetch-club-cardindexes', clubId],
    queryFn: () => clubService.getClubCardIndexes(clubId),
  })
}
