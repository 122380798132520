import React, { useEffect, useState } from 'react'
import {
  CreatePlayerDevelopmentFormSchema,
  CreatePlayerDevelopmentFormSchemaType,
} from '../../models/FormDataSchemas'
import { zodResolver } from '@hookform/resolvers/zod'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useParams } from 'react-router'
import DatePicker, {
  DateRangeType,
  DateValueType,
} from 'react-tailwindcss-datepicker'
import { InputField, SelectField } from '../shared/Inputs'
import { useAddPlayerDevelopment } from '../../hooks/usePlayersData'
import { SpinningCircle } from '../../assets/SvgIcons'

type AddPlayerDevelopmentFormProps = {
  setShowModal?: React.Dispatch<React.SetStateAction<boolean>>
}

const AddPlayerDevelopmentForm = ({
  setShowModal,
}: AddPlayerDevelopmentFormProps) => {
  const { id: playerId } = useParams() as { id: string }
  const addPlayerDevelopment = useAddPlayerDevelopment()

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<CreatePlayerDevelopmentFormSchemaType>({
    resolver: zodResolver(CreatePlayerDevelopmentFormSchema),
  })

  const [dateValue, setDateValue] = useState<DateRangeType>({
    startDate: null,
    endDate: null,
  })

  const handleDateChange = (newValue: DateValueType) => {
    if (newValue)
      setDateValue({ startDate: newValue.startDate, endDate: newValue.endDate })
  }

  const onSubmit: SubmitHandler<CreatePlayerDevelopmentFormSchemaType> = (
    values,
  ) => {
    const formData = new FormData()
    formData.append('playerId', playerId)

    const { ...playerDevelopment } = values

    for (const key in playerDevelopment) {
      formData.append(
        key,
        playerDevelopment[key as keyof typeof playerDevelopment].toString(),
      )
    }

    addPlayerDevelopment.mutate(formData)
  }

  useEffect(() => {
    if (addPlayerDevelopment.isSuccess) {
      setShowModal && setShowModal(false)
    }
  }, [addPlayerDevelopment.isSuccess, setShowModal])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='grid grid-cols-8 gap-6'>
        <div className='col-span-8 md:col-span-8 xl:col-span-8'>
          <SelectField<CreatePlayerDevelopmentFormSchemaType>
            label='Позиция'
            name='position'
            isOptional={false}
            register={register}
            optionsFor='player-positions'
          />
        </div>

        <div className='col-span-8 md:col-span-8 xl:col-span-4'>
          <label
            htmlFor='date'
            className='block text-sm font-medium leading-6 text-gray-900'
          >
            Дата на измерването
          </label>

          <Controller
            control={control}
            name='date'
            render={({ field }) => (
              <DatePicker
                primaryColor={'indigo'}
                useRange={false}
                asSingle={true}
                popoverDirection='up'
                placeholder='Избери дата'
                displayFormat={'DD/MM/YYYY'}
                maxDate={new Date()}
                containerClassName='relative w-full text-gray-700 mt-2'
                inputClassName='relative w-full tracking-wide rounded-md border-0 bg-white py-1.5 pl-4 pr-14 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 disabled:cursor-not-allowed sm:text-sm sm:leading-6'
                value={dateValue}
                onChange={(newValue) => {
                  handleDateChange(newValue)
                  return field.onChange(
                    new Date(newValue?.startDate || '').toJSON(),
                  )
                }}
              />
            )}
          />
        </div>

        <div className='col-span-8 md:col-span-8 xl:col-span-4'>
          <InputField<CreatePlayerDevelopmentFormSchemaType>
            label='Височина (см)'
            name='height'
            type='number'
            register={register}
            error={errors.height?.message?.toString()}
          />
        </div>

        <div className='col-span-8 md:col-span-8 xl:col-span-4'>
          <InputField<CreatePlayerDevelopmentFormSchemaType>
            label='Тегло (кг)'
            name='weight'
            type='number'
            register={register}
            error={errors.weight?.message?.toString()}
          />
        </div>

        <div className='col-span-8 md:col-span-8 xl:col-span-4'>
          <InputField<CreatePlayerDevelopmentFormSchemaType>
            label='Разтег (см)'
            name='fullExtent'
            type='number'
            register={register}
            error={errors.fullExtent?.message?.toString()}
          />
        </div>

        <div className='col-span-8 md:col-span-8 xl:col-span-4'>
          <InputField<CreatePlayerDevelopmentFormSchemaType>
            label='Атака (см)'
            name='attack'
            type='number'
            register={register}
            error={errors.attack?.message?.toString()}
          />
        </div>

        <div className='col-span-8 md:col-span-8 xl:col-span-4'>
          <InputField<CreatePlayerDevelopmentFormSchemaType>
            label='Блокада (см)'
            name='block'
            type='number'
            register={register}
            error={errors.block?.message?.toString()}
          />
        </div>
      </div>

      <div className='mt-4 text-right'>
        <button
          type='submit'
          disabled={isSubmitting}
          className='inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
        >
          {isSubmitting ? <SpinningCircle /> : null}
          Добави
        </button>
      </div>
    </form>
  )
}

export default AddPlayerDevelopmentForm
