import { Link } from 'react-router-dom'
import {
  useRemoveUserClubData,
  useUserClubsData,
} from '../../hooks/useUsersData'
import AddClubToUser from './AddClubToUser'

type UserClubClaimsProps = {
  userId: string
}

const UserClubClaims = ({ userId }: UserClubClaimsProps) => {
  const { data: clubs, isLoading, isError } = useUserClubsData(userId)
  const removeClub = useRemoveUserClubData()

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (isError) {
    return <div>Error fetching user clubs..</div>
  }

  if (!clubs) {
    return <div>No clubs</div>
  }

  const handleRemove = (clubId: number) => {
    if (userId) removeClub.mutate({ userId, clubId })
  }

  return (
    <div>
      <div className='shadow'>
        <div className='space-y-6 rounded-md bg-white px-4 py-6 sm:p-6'>
          <div>
            <h3 className='text-lg font-semibold text-gray-900'>Клубове</h3>
            <p className='mt-1 text-sm text-gray-500'>
              Списък на клубовете до които потребителят има достъп
            </p>
          </div>

          <div className='inline-flex flex-wrap gap-3'>
            {clubs.length === 0 ? (
              <span
                key='no-clubs'
                className='inline-flex items-center gap-x-0.5 rounded-md bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10'
              >
                Няма добавени клубове
              </span>
            ) : (
              clubs.map((club) => (
                <span
                  key={club.id}
                  className='inline-flex items-center gap-x-0.5 rounded-md bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10'
                >
                  <Link to={`/clubs/${club.id}`} className='hover:underline'>
                    {club.fullName}
                  </Link>
                  <button
                    type='button'
                    className='group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-indigo-600/20'
                    onClick={() => handleRemove(club.id)}
                  >
                    <span className='sr-only'>Remove</span>
                    <svg
                      viewBox='0 0 14 14'
                      className='h-3.5 w-3.5 stroke-indigo-600/50 group-hover:stroke-indigo-600/75'
                    >
                      <path d='M4 4l6 6m0-6l-6 6' />
                    </svg>
                    <span className='absolute -inset-1' />
                  </button>
                </span>
              ))
            )}
          </div>

          <AddClubToUser addedClubs={clubs} />
        </div>
      </div>
    </div>
  )
}

export default UserClubClaims
