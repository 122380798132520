import { useMemo } from 'react'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  Row,
  useReactTable,
} from '@tanstack/react-table'
import { Player } from '../../models/Player'
import Pagination from '../Players/Pagination'
import { LinkIcon } from '@heroicons/react/20/solid'
import PlayerImage from '../shared/TableImage'
import { Link, useNavigate } from 'react-router-dom'
import { IClub } from '../../models/Club'
import fuzzyFilter from '../../utils/fuzzyFilter'
import ExpiringContractSendNotificationButton from './SendNotificationButton'

type ExpiringTransfersTableProps = {
  data: Player[]
  globalFilter: string
  setGlobalFilter: React.Dispatch<React.SetStateAction<string>>
}

const ExpiringTransfersTable = ({
  data,
  globalFilter,
  setGlobalFilter,
}: ExpiringTransfersTableProps) => {
  // const columnVisibility = {
  //   number: false,
  //   photoId: false,
  //   id: false,
  //   ownerExpireDate: false,
  // }
  // const columnHelper = createColumnHelper<Player>()
  // const navigate = useNavigate()

  // const columns = useMemo(
  //   () => [
  //     columnHelper.accessor((row) => row.id, {
  //       id: 'id',
  //       header: () => <span>id</span>,
  //     }),
  //     columnHelper.accessor((row) => row.ownerExpireDate, {
  //       id: 'ownerExpireDate',
  //       header: () => <span>ownerExpireDate</span>,
  //     }),
  //     columnHelper.accessor((row) => row.number, {
  //       id: 'number',
  //       header: () => <span>number</span>,
  //     }),
  //     columnHelper.accessor((row) => row.photoId, {
  //       id: 'photoId',
  //       header: () => <span>photoId</span>,
  //     }),
  //     columnHelper.accessor((row) => `${row.firstName} ${row.lastName}`, {
  //       id: 'name',
  //       cell: (info) => {
  //         return (
  //           <div className='flex gap-3 font-normal text-gray-900'>
  //             <div className='relative h-10 w-10'>
  //               <PlayerImage
  //                 className='h-full w-full rounded-full object-cover object-center text-gray-300'
  //                 photoId={info.row.getValue<string>('photoId')}
  //               />
  //             </div>
  //             <div className='text-sm'>
  //               <div className='peer flex cursor-pointer gap-2 text-gray-700 hover:text-blue-500'>
  //                 <Link to={`/players/${info.row.getValue('id')}`}>
  //                   <div className='font-medium transition-colors'>
  //                     {info.renderValue()}
  //                   </div>
  //                 </Link>
  //                 <Link to={`/players/${info.row.getValue('id')}`}>
  //                   <div className='rounded-full bg-blue-50 p-1 text-blue-500 transition-colors hover:bg-blue-500 hover:text-white'>
  //                     <LinkIcon />
  //                   </div>
  //                 </Link>
  //               </div>
  //               <div className='text-gray-400'>
  //                 СЕК Номер: {info.row.getValue('number')}
  //               </div>
  //             </div>
  //           </div>
  //         )
  //       },
  //       header: () => <span>Име</span>,
  //     }),
  //     columnHelper.accessor((row) => row.currentClub, {
  //       id: 'currentClub',
  //       cell: (info) => {
  //         const clubId = info.row.getValue<IClub>('currentClub')?.id
  //         if (!clubId) return '-'
  //         return (
  //           <Link to={`/clubs/${info.row.getValue<IClub>('currentClub')?.id}`}>
  //             <span className='cursor-pointer rounded-full bg-blue-50 px-3 py-2 text-xs font-semibold text-blue-500 hover:underline'>
  //               {info.getValue().fullName}
  //             </span>
  //           </Link>
  //         )
  //       },
  //       header: () => <span>Настоящ клуб</span>,
  //     }),
  //     columnHelper.accessor((row) => row.transferExpireDate, {
  //       id: 'contractUntil',
  //       cell: (info) => {
  //         const val =
  //           info.getValue() ?? info.row.getValue('ownerExpireDate') ?? '-'
  //         return val === '-'
  //           ? val
  //           : new Date(val).toLocaleDateString('bg-BG', {
  //               day: '2-digit',
  //               month: '2-digit',
  //               year: 'numeric',
  //             })
  //       },
  //       header: () => <span>Договор до</span>,
  //     }),

  //     columnHelper.accessor((row) => row.id, {
  //       id: 'actions',
  //       cell: (info) => (
  //         <ExpiringContractSendNotificationButton id={info.getValue()} />
  //       ),
  //       header: () => <span>Действия</span>,
  //     }),
  //   ],
  //   [],
  // )

  // const handleClick = (
  //   e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  //   row: Row<Player>,
  // ) => {
  //   e.preventDefault()
  //   if (e.detail === 2) {
  //     navigate(`/players/${row.original.id}`)
  //   }
  // }

  // const table = useReactTable({
  //   data,
  //   columns,
  //   filterFns: {
  //     fuzzy: fuzzyFilter,
  //   },
  //   state: {
  //     columnVisibility,
  //     globalFilter,
  //   },
  //   getFilteredRowModel: getFilteredRowModel(),
  //   onGlobalFilterChange: setGlobalFilter,
  //   globalFilterFn: fuzzyFilter,
  //   getCoreRowModel: getCoreRowModel(),
  //   getPaginationRowModel: getPaginationRowModel(),
  // })

  // return (
  //   <>
  //     <div className='mt-8 flex flex-col'>
  //       <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
  //         <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
  //           <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
  //             <table className='w-full border-collapse border-b border-gray-100 bg-white text-left text-sm text-gray-500'>
  //               <thead className='bg-gray-50'>
  //                 {table.getHeaderGroups().map((headerGroup) => (
  //                   <tr key={headerGroup.id}>
  //                     {headerGroup.headers.map((header) => (
  //                       <th
  //                         key={header.id}
  //                         className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
  //                       >
  //                         {header.isPlaceholder
  //                           ? null
  //                           : flexRender(
  //                               header.column.columnDef.header,
  //                               header.getContext(),
  //                             )}
  //                       </th>
  //                     ))}
  //                   </tr>
  //                 ))}
  //               </thead>
  //               <tbody className='divide-y divide-gray-100 border-t border-gray-200 bg-white'>
  //                 {table.getRowModel().rows.map((row) => (
  //                   <tr
  //                     key={row.id}
  //                     className='cursor-pointer hover:bg-gray-50'
  //                     onClick={(e) => handleClick(e, row)}
  //                   >
  //                     {row.getVisibleCells().map((cell) => (
  //                       <td
  //                         key={cell.id}
  //                         className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'
  //                       >
  //                         {flexRender(
  //                           cell.column.columnDef.cell,
  //                           cell.getContext(),
  //                         )}
  //                       </td>
  //                     ))}
  //                   </tr>
  //                 ))}
  //               </tbody>
  //             </table>
  //             <Pagination table={table} data={data} displayPages={6} />
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </>
  // )

  return <></>
}

export default ExpiringTransfersTable
