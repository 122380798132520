import { PlayerDetailsT } from '../../models/Player'
import PlayerDevelopmentList from '../PlayerDevelopments/PlayerDevelopmentList'
import PlayerProfileCard from './PlayerProfileCard'
import PlayerDocumentList from '../PlayerDocuments/PlayerDocumentList'

type PlayerProfileProps = {
  player: PlayerDetailsT
}

const PlayerProfile = ({ player }: PlayerProfileProps) => {
  return (
    <>
      <PlayerProfileCard player={player} />

      <PlayerDevelopmentList id={player.id} />

      <PlayerDocumentList id={player.id} />
    </>
  )
}

export default PlayerProfile
