import { TransferTypeT } from '../models/PlayerTransfer'

export const ageGroups = [
  {
    value: 0,
    sex: 0,
    age: 11,
    label: 'Момчета - Детски Волейбол',
  },
  {
    value: 1,
    sex: 0,
    age: 12,
    label: 'Момчета - Мини Волейбол',
  },
  {
    value: 2,
    sex: 0,
    age: 13,
    label: 'Момчета под 14 г.',
  },
  {
    value: 3,
    sex: 0,
    age: 15,
    label: 'Момчета под 16 г.',
  },
  {
    value: 4,
    sex: 0,
    age: 17,
    label: 'Момчета под 18 г.',
  },
  {
    value: 5,
    sex: 0,
    age: 19,
    label: 'Момчета под 20 г.',
  },
  {
    value: 6,
    sex: 0,
    age: 255,
    label: 'Мъже',
  },
  {
    value: 7,
    sex: 1,
    age: 11,
    label: 'Момичета - Детски Волейбол',
  },
  {
    value: 8,
    sex: 1,
    age: 12,
    label: 'Момичета - Мини Волейбол',
  },
  {
    value: 9,
    sex: 1,
    age: 13,
    label: 'Момичета под 14 г.',
  },
  {
    value: 10,
    sex: 1,
    age: 15,
    label: 'Момичета под 16 г.',
  },
  {
    value: 11,
    sex: 1,
    age: 17,
    label: 'Момичета под 18 г.',
  },
  {
    value: 12,
    sex: 1,
    age: 19,
    label: 'Момичета под 20 г.',
  },
  {
    value: 13,
    sex: 1,
    age: 255,
    label: 'Жени',
  },
]

export const educationLevels = [
  { value: 0, label: 'Средно' },
  { value: 1, label: 'Средно специално' },
  { value: 2, label: 'Висше' },
]

export const playerPositions = [
  { value: 0, label: 'Разпределител' },
  { value: 1, label: 'Диагонал на разпределителя' },
  { value: 2, label: 'Централен блокировач' },
  { value: 3, label: 'Посрещач' },
  { value: 4, label: 'Либеро' },
]

export const playerDocumentTypes = [
  { value: 0, label: 'Декларация от родител' },
  { value: 1, label: 'Договор за вътрешен трансфер' },
  { value: 2, label: 'Договор за спортна подготовка и квалификация' },
  { value: 3, label: 'Договор на аматьор' },
  { value: 4, label: 'Договор на професионалист' },
]

export const playerTransferDocumentTypes = [
  { value: 0, label: 'Договор' },
  { value: 1, label: 'ITC Сертификат' },
]

export const transferTypes = [
  {
    id: '5ac964d9-508f-478d-807f-aef96d9358ce',
    title: 'Начало',
    description: '',
    transferTypeId: null,
    documents: [],
    types: [
      {
        id: 'da1ee0a8-89fe-4855-8545-c9f95b149197',
        title: 'Вътрешен трансфер',
        description:
          'При смяна на клубна принадлежност или временно преотстъпване на състезател в друг клуб',
        transferTypeId: null,
        documents: [],
        types: [
          {
            id: 'bca0e214-7051-4ad0-a39a-c935975fa4aa',
            title: 'Смяна на клубна принадлежност',
            description:
              'При смяна на клубната принадлежност, състезателните права преминават в новия клуб за неограничен срок, след сключване на договор за вътрешен трансфер между двата клуба, или след предоставяне на служебна бележка от клуба отдаващ състезателя.',
            transferTypeId: 1,
            documents: [1],
            types: null,
          },
          {
            id: '2c8e4ab3-33b9-4320-86a2-989cdcbbf86b',
            title: 'Временно преотстъпване',
            description:
              'При временно преотстъпване на състезател в друг клуб, състезателните права се връщат в клуба отдаващ след изтичане на срока на договора за вътрешен трансфер.',
            transferTypeId: 2,
            documents: [1],
            types: null,
          },
        ],
      },
      {
        id: '47fa3986-1253-43c3-a90f-efa25e1e618b',
        title: 'Международен трансфер',
        description:
          'При трансфер на чужд състезател в български клуб или български състезател в чужбина',
        transferTypeId: null,
        documents: [],
        types: [
          {
            id: '039a5216-0b04-4659-93e6-aaa13606a7d9',
            title: 'Чужд състезател в български клуб',
            description: '',
            documents: [],
            types: [
              {
                id: '296a806a-5c67-42f5-b736-6ac978c5b2a7',
                title: 'Състезател от ЕС',
                description: 'Състезателят е гражданин на държава членка на ЕС',
                transferTypeId: 3,
                documents: [1, 2],
                types: null,
              },
              {
                id: 'f31fbc77-a762-48cd-9a86-5158e650ef50',
                title: 'Състезател от трети страни',
                description: 'Състезателят е гражданин на държава извън ЕС',
                transferTypeId: 4,
                documents: [1, 2, 3],
                types: null,
              },
            ],
            transferTypeId: null,
          },
          {
            id: 'acbe8d77-9696-4ffc-a3f7-d85c08fdaf20',
            title: 'Трансфер на български състезател в чужбина',
            description: '',
            transferTypeId: 5,
            documents: [],
            types: null,
          },
        ],
      },
    ],
  },
] as TransferTypeT[]

export const transferDocuments = [
  {
    id: 1,
    name: 'contract',
    title: 'Договор/Декларация',
    description: 'Договор за вътрешен трансфер или Декларация за преотстъпване',
    file: null,
  },
  {
    id: 2,
    name: 'certificate',
    title: 'Сертификат за международен трансфер (ITC) от FIVB',
    description: '',
    file: null,
  },
  {
    id: 3,
    name: 'visa',
    title: 'Документ за пребиваване в България',
    description: '',
    file: null,
  },
]
