import { MouseEvent, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  Row,
  useReactTable,
} from '@tanstack/react-table'
import { LinkIcon } from '../../assets/SvgIcons'
import { Link } from 'react-router-dom'
import Pagination from './Pagination'
import PlayerTableDropdown from './PlayerTableDropdown'
import { Player } from '../../models/Player'
import { IClub } from '../../models/Club'
import TableImage from '../shared/TableImage'
import fuzzyFilter from '../../utils/fuzzyFilter'

type PlayersTableProps = {
  data: Player[]
  globalFilter: string
  setGlobalFilter: React.Dispatch<React.SetStateAction<string>>
}

export default function PlayersTable({
  data,
  globalFilter,
  setGlobalFilter,
}: PlayersTableProps) {
  const columnVisibility = { number: false, photoId: false, id: false }
  const columnHelper = createColumnHelper<Player>()
  const navigate = useNavigate()

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.id, {
        id: 'id',
        header: () => <span>id</span>,
      }),
      columnHelper.accessor((row) => row.number, {
        id: 'number',
        header: () => <span>number</span>,
      }),
      columnHelper.accessor((row) => row.photoId, {
        id: 'photoId',
        header: () => <span>photoId</span>,
      }),
      columnHelper.accessor(
        (row) =>
          `${row.firstName} ${row.middleName?.concat(' ')}${row.lastName}`,
        {
          id: 'name',
          cell: (info) => {
            return (
              <div className='flex gap-3 font-normal text-gray-900'>
                <div className='relative h-10 w-10'>
                  <TableImage
                    className='h-full w-full rounded-full object-cover object-center text-gray-300'
                    imageType='person'
                    photoId={info.row.getValue<string>('photoId')}
                  />
                </div>
                <div className='text-sm'>
                  <div className='peer flex cursor-pointer gap-2 text-gray-700 hover:text-blue-500'>
                    <Link to={`/players/${info.row.getValue('id')}`}>
                      <div className='font-medium transition-colors'>
                        {info.renderValue()}
                      </div>
                    </Link>
                    <Link to={`/players/${info.row.getValue('id')}`}>
                      <div className='rounded-full bg-blue-50 p-1 text-blue-500 transition-colors hover:bg-blue-500 hover:text-white'>
                        <LinkIcon />
                      </div>
                    </Link>
                  </div>
                  <div className='text-gray-400'>
                    СЕК Номер: {info.row.getValue('number')}
                  </div>
                </div>
              </div>
            )
          },
          header: () => <span>Име</span>,
        },
      ),
      columnHelper.accessor((row) => row.birthDate, {
        id: 'birthDate',
        cell: (info) =>
          new Date(info.getValue()).toLocaleDateString('bg-BG', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          }),
        header: () => <span>Рождена дата</span>,
      }),
      columnHelper.accessor((row) => row.currentClub, {
        id: 'currentClub',
        cell: (info) => {
          const clubId = info.row.getValue<IClub>('currentClub')?.id
          if (!clubId) return '-'
          return (
            <Link to={`/clubs/${info.row.getValue<IClub>('currentClub')?.id}`}>
              <span className='cursor-pointer rounded-full bg-blue-50 px-3 py-2 text-xs font-semibold text-blue-500 hover:underline'>
                {info.getValue()?.fullName}
              </span>
            </Link>
          )
        },
        header: () => <span>Настоящ клуб</span>,
      }),

      columnHelper.accessor((row) => row.lastName, {
        id: 'actions',
        cell: (info) => (
          <div className='flex justify-center gap-4'>
            <PlayerTableDropdown id={info.row.getValue('id')} />
          </div>
        ),
        header: () => <span>Действия</span>,
      }),
    ],
    [],
  )

  const handleClick = (e: MouseEvent, row: Row<Player>) => {
    e.preventDefault()
    if (e.detail === 2) {
      navigate(`/players/${row.original.id}`)
    }
  }

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnVisibility,
      globalFilter,
    },
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    // getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  })

  return (
    <>
      <div className='mt-8 flex flex-col'>
        <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
            <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
              <table className='w-full border-collapse border-b border-gray-100 bg-white text-left text-sm text-gray-500'>
                <thead className='bg-gray-50'>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <th
                          scope='col'
                          className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                          key={header.id}
                        >
                          {header.isPlaceholder ? null : (
                            <div
                            // {...{
                            //   className: header.column.getCanSort()
                            //     ? 'cursor-pointer select-none'
                            //     : '',
                            //   onClick:
                            //     header.column.getToggleSortingHandler(),
                            // }}
                            >
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext(),
                              )}
                              {/* {{
                                asc: ' 🔼',
                                desc: ' 🔽',
                              }[header.column.getIsSorted() as string] ?? null} */}
                            </div>
                          )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody className='divide-y divide-gray-100 border-t border-gray-200 bg-white'>
                  {data.length === 0 ? (
                    <tr className='p-2 text-center'>
                      <td
                        className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'
                        colSpan={5}
                      >
                        Няма добавени състезатели
                      </td>
                    </tr>
                  ) : (
                    table.getRowModel().rows.map((row) => (
                      <tr
                        key={row.id}
                        className='cursor-pointer hover:bg-gray-50'
                        onClick={(e) => handleClick(e, row)}
                      >
                        {row.getVisibleCells().map((cell) => (
                          <td
                            key={cell.id}
                            className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </td>
                        ))}
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
              <Pagination table={table} data={data} displayPages={6} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
