import {
  IPlayerSearch,
  PlayerDevelopmentT,
  PlayerDocumentT,
} from '../models/Player'
import { deleteData, getData, postData, putData } from './AxiosHelper'
import { Player, PlayerDetailsT } from '../models/Player'
import { playerDocumentTypes, playerPositions } from '../utils/staticData'
import { ISearchInput } from '../models/BaseModels'
import { IPlayerTransfer } from '../models/PlayerTransfer'

const get = ({ page, pageSize, orderBy, desc, keyword }: ISearchInput) => {
  let url = `/api/players/search?page=${page}&pagesize=${pageSize}`

  if (keyword && keyword.length > 0) url += '&keyword=' + keyword

  if (orderBy) url += '&orderBy=' + orderBy

  if (desc) url += '&desc=' + desc

  return getData(url, true) as Promise<IPlayerSearch>
}

const getAll = () => getData('/api/players', true) as Promise<Player[]>

const getById = (id: string | undefined) =>
  getData(`/api/players/${id}`, true) as Promise<PlayerDetailsT>

const create = (player: FormData) =>
  postData('/api/players', player, true) as Promise<PlayerDetailsT>

const update = (id: string, player: FormData) =>
  putData(`/api/players/${id}`, player, true) as Promise<PlayerDetailsT>

const deletePlayer = (id: string) =>
  deleteData(`/api/players/${id}`) as Promise<PlayerDetailsT>

const getPlayerDevelopments = (id: number | undefined) =>
  getData(`/api/players/${id}/developments`, true) as Promise<
    PlayerDevelopmentT[]
  >

const addPlayerDevelopment = (development: FormData) =>
  postData(
    '/api/players/developments',
    development,
    true,
  ) as Promise<PlayerDevelopmentT>

const addPlayerDocument = (document: FormData) =>
  postData('/api/players/documents', document, true) as Promise<PlayerDocumentT>

const getPlayerPositions = () => Promise.resolve(playerPositions)
const getPlayerDocumentTypes = () => Promise.resolve(playerDocumentTypes)

const getPlayersWithExpiringContracts = () =>
  getData('/api/players/expiring-contracts', true) as Promise<Player[]>

const getPlayerDocuments = (id: number) =>
  getData(`/api/players/${id}/documents`, true) as Promise<PlayerDocumentT[]>

const getPlayerTransfers = (id: number) =>
  getData(`/api/players/${id}/transfers`, true) as Promise<IPlayerTransfer[]>

export const playerService = {
  get,
  getAll,
  getById,
  create,
  update,
  deletePlayer,
  getPlayerDevelopments,
  addPlayerDevelopment,
  getPlayerPositions,
  getPlayerDocumentTypes,
  getPlayersWithExpiringContracts,
  getPlayerDocuments,
  addPlayerDocument,
  getPlayerTransfers,
}
