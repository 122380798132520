import { useContext } from 'react'
import { QueryClientProvider, QueryClient } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import Login from './components/Authentication/Login'
import Home from './components/Home/Home'
import { AuthenticationContext } from './context/AuthenticationContext'
import ListClubs from './components/Clubs/ListClubs'
import ListCoaches from './components/Coaches/ListCoaches'
import SidebarLayout from './layouts/SidebarLayout'
import ListUsers from './components/Users/ListUsers'
import ListPlayers from './components/Players/ListPlayers'
import CardIndex from './components/CardIndexes/CardIndex'
import ClubDetails from './components/Clubs/ClubDetails'
import PlayerCreate from './components/Players/PlayerCreate'
import ClubCreate from './components/Clubs/ClubCreate'
import AddCoach from './components/Coaches/AddCoach'
import CreateUser from './components/Users/CreateUser'
import UserDetails from './components/Users/UserDetails'
import CoachProfile from './components/Coaches/CoachProfile'
import AddCardIndex from './components/CardIndexes/AddCardIndex'
import UpdateCoach from './components/Coaches/UpdateCoach'
import TransferList from './components/Transfers/TransferList'
import PlayerDetails from './components/Players/PlayerDetails'
import Logout from './components/Authentication/Logout'
import { IUser } from './models/User'
import CardIndexPlayerDelete from './components/CardIndexes/CardIndexPlayerDelete'
import PlayerUpdate from './components/Players/PlayerUpdate'
import TransferCreate from './components/Transfers/v2/TransferCreate'
import TransferDetails from './components/Transfers/TransferDetails'
import ClubUpdate from './components/Clubs/ClubUpdate'
import CardIndexPrint from './components/CardIndexes/CardIndexPrint'

interface RestrictedRouteProps {
  component: React.ComponentType
  authUser: IUser | null
  [key: string]: any
}

const RestrictedRoute: React.FC<RestrictedRouteProps> = ({
  component: Component,
  authUser,
  ...rest
}) => {
  return authUser ? <Component /> : <Login redirectTo={rest.location} />
}

const queryClient = new QueryClient()

function App() {
  const context = useContext(AuthenticationContext)
  const location = useLocation()
  const authUser = context.getUser()

  return (
    <QueryClientProvider client={queryClient}>
      <Routes>
        <Route
          element={
            <RestrictedRoute
              component={SidebarLayout}
              authUser={authUser}
              location={location.pathname}
            />
          }
        >
          <Route path='/' element={<Navigate to='/players' />} />
          <Route path='/players' element={<ListPlayers />} />
          <Route path='/players/:id' element={<PlayerDetails />} />
          <Route path='/players/:id/edit' element={<PlayerUpdate />} />
          <Route path='/players/create' element={<PlayerCreate />} />

          <Route path='/transfers' element={<TransferList />} />
          <Route path='/transfers/expiring' element={<Home />} />
          <Route path='/transfers/:id' element={<TransferDetails />} />
          <Route path='/transfers/create' element={<TransferCreate />} />

          <Route path='/cardindexes/:id' element={<CardIndex />} />
          <Route path='/cardindexes/:id/print' element={<CardIndexPrint />} />
          <Route
            path='/cardindexes/:id/delete/:playerId'
            element={<CardIndexPlayerDelete />}
          />
          <Route path='/cardindexes/create' element={<AddCardIndex />} />

          <Route path='/clubs' element={<ListClubs />} />
          <Route path='/clubs/:id' element={<ClubDetails />} />
          <Route path='/clubs/:id/edit' element={<ClubUpdate />} />
          <Route path='/clubs/create' element={<ClubCreate />} />

          <Route path='/coaches' element={<ListCoaches />} />
          <Route path='/coaches/:id' element={<CoachProfile />} />
          <Route path='/coaches/:id/edit' element={<UpdateCoach />} />
          <Route path='/coaches/create' element={<AddCoach />} />

          <Route path='/users' element={<ListUsers />} />
          <Route path='/users/:userId' element={<UserDetails />} />
          <Route path='/users/create' element={<CreateUser />} />

          <Route path='/confirmemail' element={<Login />} />
          <Route path='/confirmpassword' element={<Login />} />
        </Route>
        <Route path='/login' element={<Login />} />
        <Route path='/logout' element={<Logout />} />
      </Routes>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}

export default App
