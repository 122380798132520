import { useNavigate } from 'react-router'
import { LinkIcon, PlayerJersey } from '../../assets/SvgIcons'
import { PlayerDetailsT } from '../../models/Player'
import Image from '../shared/Image'
import { Fragment } from 'react'
import { Link } from 'react-router-dom'

type PlayerProfileCardProps = {
  player: PlayerDetailsT
  readOnly?: boolean
}

const PlayerProfileCard = ({ player, readOnly }: PlayerProfileCardProps) => {
  const navigate = useNavigate()

  return (
    <div className='px-4 sm:px-6 md:px-0'>
      <section className='relative rounded-lg bg-white shadow'>
        {readOnly ? null : (
          <header className='flex items-center justify-between border-b border-gray-200 px-4 py-5 sm:px-6'>
            <div className='inline-flex items-center'>
              <PlayerJersey />
              <h3 className='text-2xl font-medium leading-6 text-gray-900'>
                Лични данни
              </h3>
            </div>
            <div>
              <button
                type='button'
                onClick={() => navigate('edit')}
                className='inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto'
              >
                Редактирай
              </button>
            </div>
          </header>
        )}
        <div className='px-4 py-5 sm:p-6'>
          <div className='grid grid-cols-1 gap-6 xl:grid-cols-5'>
            <div className='col-span-1 grid items-center justify-center'>
              <Image
                imageType='person'
                photoId={player.photoId}
                className='h-[200px] w-[200px] rounded-full object-cover'
              />
            </div>
            <div className='grid grid-cols-1 gap-6 lg:col-span-4 lg:grid-cols-4'>
              <div className='col-span-1 lg:col-span-2'>
                <span className='block text-sm font-medium text-gray-500'>
                  Име
                </span>
                <span className='mt-1 block rounded-md bg-gray-50 p-2'>
                  {player.firstName} {player.lastName}
                </span>
              </div>

              <div className='col-span-1'>
                <span className='block text-sm font-medium text-gray-500'>
                  ЕГН/ЛНЧ
                </span>
                <span className='mt-1 block rounded-md bg-gray-50 p-2'>
                  {player.egn}
                </span>
              </div>

              <div className='col-span-1'>
                <span className='block text-sm font-medium text-gray-500'>
                  Националност
                </span>
                <span className='mt-1 block rounded-md bg-gray-50 p-2'>
                  {player.nationality ?? (
                    <span className='text-gray-400'>Няма информация</span>
                  )}
                </span>
              </div>

              {/* <div className='col-span-1'>
                <span className='block text-sm font-medium text-gray-500'>
                  Възрастова група
                </span>
                <span className='mt-1 block rounded-md bg-gray-50 p-2'>
                  {player.ageGroup ?? (
                    <span className='text-gray-400'>Няма информация</span>
                  )}
                </span>
              </div> */}

              <div className='col-span-1'>
                <span className='block text-sm font-medium text-gray-500'>
                  Рождена дата
                </span>
                <span className='mt-1 block rounded-md bg-gray-50 p-2'>
                  {new Date(player.birthDate).toLocaleDateString('bg-BG', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  })}
                </span>
              </div>

              <div className='col-span-1'>
                <span className='block text-sm font-medium text-gray-500'>
                  Състезател от
                </span>
                <span className='mt-1 block rounded-md bg-gray-50 p-2'>
                  {new Date(player.playingSince).toLocaleDateString('bg-BG', {
                    year: 'numeric',
                  }) ?? <span className='text-gray-400'>Няма информация</span>}
                </span>
              </div>

              <div className='col-span-1'>
                <span className='block text-sm font-medium text-gray-500'>
                  Град
                </span>
                <span className='mt-1 block rounded-md bg-gray-50 p-2'>
                  {player.city?.name || '-'}
                </span>
              </div>

              <div className='col-span-1'>
                <span className='block text-sm font-medium text-gray-500'>
                  Регион
                </span>
                <span className='mt-1 block rounded-md bg-gray-50 p-2'>
                  {player.region?.name || '-'}
                </span>
              </div>

              <div className='col-span-1 lg:col-span-2'>
                <span className='block text-sm font-medium text-gray-500'>
                  Първи треньор
                </span>
                <span className='mt-1 block rounded-md bg-gray-50 p-2'>
                  <span className='inline-flex items-center gap-1'>
                    {player.firstCoach ? (
                      <Fragment>
                        {player.firstCoach.name}
                        <Link to={`/coaches/${player.firstCoach.id}`}>
                          <div className='transition-color rounded-full bg-blue-50 p-1 text-blue-500 hover:bg-blue-500 hover:text-white'>
                            <LinkIcon />
                          </div>
                        </Link>
                      </Fragment>
                    ) : (
                      <span className='text-gray-400'>Няма информация</span>
                    )}
                  </span>
                </span>
              </div>

              <div className='col-span-1 lg:col-span-2'>
                <span className='block text-sm font-medium text-gray-500'>
                  Настоящ треньор
                </span>
                <span className='mt-1 block rounded-md bg-gray-50 p-2'>
                  <span className='inline-flex items-center gap-1'>
                    {player.currentCoach ? (
                      <Fragment>
                        {player.currentCoach.name}
                        <Link to={`/coaches/${player.currentCoach.id}`}>
                          <div className='transition-color rounded-full bg-blue-50 p-1 text-blue-500 hover:bg-blue-500 hover:text-white'>
                            <LinkIcon />
                          </div>
                        </Link>
                      </Fragment>
                    ) : (
                      <span className='text-gray-400'>Няма информация</span>
                    )}
                  </span>
                </span>
              </div>

              <div className='col-span-1 lg:col-span-2'>
                <span className='block text-sm font-medium text-gray-500'>
                  Първи клуб
                </span>
                <span className='mt-1 block rounded-md bg-gray-50 p-2 '>
                  <span className='inline-flex items-center gap-1'>
                    {player.firstClub ? (
                      <Fragment>
                        {player.firstClub.fullName}
                        <Link to={`/clubs/${player.firstClub.id}`}>
                          <div className='transition-color rounded-full bg-blue-50 p-1 text-blue-500 hover:bg-blue-500 hover:text-white'>
                            <LinkIcon />
                          </div>
                        </Link>
                      </Fragment>
                    ) : (
                      <span className='text-gray-400'>Няма информация</span>
                    )}
                  </span>
                </span>
              </div>

              <div className='col-span-1 lg:col-span-2'>
                <span className='block text-sm font-medium text-gray-500'>
                  Настоящ клуб
                </span>
                <span className='mt-1 block rounded-md bg-gray-50 p-2'>
                  <span className='inline-flex items-center gap-1'>
                    {player.currentClub ? (
                      <Fragment>
                        {player.currentClub.fullName}
                        <Link to={`/clubs/${player.currentClub.id}`}>
                          <div className='transition-color rounded-full bg-blue-50 p-1 text-blue-500 hover:bg-blue-500 hover:text-white'>
                            <LinkIcon />
                          </div>
                        </Link>
                      </Fragment>
                    ) : (
                      <span className='text-gray-400'>Няма информация</span>
                    )}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default PlayerProfileCard
