import { useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import { LockClosedIcon } from '@heroicons/react/20/solid'
import { AuthenticationContext } from '../../context/AuthenticationContext'
import logo from '../../assets/logo-gold.png'
import { SpinningCircle } from '../../assets/SvgIcons'

const Login = ({ redirectTo = '/players' }: { redirectTo?: string }) => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [rememberMe, setRememberMe] = useState(false)

  const context = useContext(AuthenticationContext)

  const handleSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault()
    context.login(username, password, redirectTo)
  }

  return (
    <div className='flex min-h-screen flex-col justify-center bg-gray-50 py-12 sm:px-6 lg:px-8'>
      <div className='flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8'>
        <div className='w-full max-w-md space-y-8'>
          <div>
            <img className='mx-auto h-12 w-auto' src={logo} alt='BVF logo' />
            <h2 className='mt-4 text-center text-3xl font-bold tracking-tight text-gray-700'>
              Вход в системата
            </h2>
          </div>
          <form
            className='mt-8 space-y-6'
            // action=''
            onSubmit={handleSubmit}
            method='POST'
          >
            <input type='hidden' name='remember' defaultValue='true' />
            <div className='-space-y-px rounded-md shadow-sm'>
              <div>
                <label htmlFor='username' className='sr-only'>
                  Потребителско име
                </label>
                <input
                  id='username'
                  name='username'
                  type='text'
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  autoComplete='username'
                  required
                  className='relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                  placeholder='Потребителско име'
                />
              </div>
              <div>
                <label htmlFor='password' className='sr-only'>
                  Парола
                </label>
                <input
                  id='password'
                  name='password'
                  type='password'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  autoComplete='current-password'
                  required
                  className='relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                  placeholder='Парола'
                />
              </div>
            </div>

            <div className='flex items-center justify-between'>
              <div className='flex items-center'>
                <input
                  id='remember-me'
                  name='remember-me'
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                  type='checkbox'
                  className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500'
                />
                <label
                  htmlFor='remember-me'
                  className='ml-2 block text-sm text-gray-900'
                >
                  Запомни ме
                </label>
              </div>

              <div className='text-sm'>
                <Link
                  to='/forgot-password'
                  className='font-medium text-indigo-600 hover:text-indigo-500'
                >
                  Забравена парола?
                </Link>
              </div>
            </div>

            {context.loginError && (
              <div>
                <div
                  role='alert'
                  className='rounded border-l-4 border-rose-500 bg-rose-50 p-4'
                >
                  <strong className='block font-medium text-rose-700'>
                    Нещо се обърка!
                  </strong>

                  <p className='mt-2 text-sm text-rose-700'>
                    {context.loginError}
                  </p>
                </div>
              </div>
            )}
            <div>
              <button
                type='submit'
                disabled={context.loading}
                className='group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed'
              >
                <span className='absolute inset-y-0 left-0 flex items-center pl-3'>
                  {context.loading ? (
                    <SpinningCircle />
                  ) : (
                    <LockClosedIcon
                      className='h-5 w-5 text-indigo-500 group-hover:text-indigo-400'
                      aria-hidden='true'
                    />
                  )}
                </span>
                {context.loading ? ' Loading...' : 'Вход'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Login
