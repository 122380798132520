import { useParams } from 'react-router-dom'
import { useCoachData } from '../../hooks/useCoachesData'
import CoachDetails from './CoachDetails'
import CoachEducations from './CoachEducations'
import CoachTransfers from './CoachTransfers'
import CoachCardIndexes from './CoachCardIndexes'
import { useState } from 'react'
import AddCoachEducationModal from './AddCoachEducationModal'
import CoachTransferCreateModal from './CoachTransferCreateModal'

type CoachProfileParams = {
  id: string
}

const CoachProfile = () => {
  const { id } = useParams() as CoachProfileParams
  const [showEducations, setShowEducations] = useState(false)
  const [showTransfers, setShowTransfers] = useState(false)

  const { data: coach, isError, isLoading } = useCoachData(id)

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (isError) {
    return <div>Error...</div>
  }

  if (!coach) {
    return <div>Coach not found</div>
  }

  return (
    <main className='flex-1'>
      <div className='relative mx-auto md:px-8 xl:px-0'>
        <div className='px-4 pb-16 sm:px-6 md:px-0'>
          <h1 className='text-3xl font-bold tracking-tight text-gray-900'>
            Профил на треньор
          </h1>
        </div>
        <CoachDetails coach={coach} />
        <CoachEducations coachId={id} setShowModal={setShowEducations} />
        <CoachTransfers
          transfers={coach.coachTransfers}
          setShowModal={setShowTransfers}
        />
        <CoachCardIndexes id={coach.id} />
      </div>
      <CoachTransferCreateModal
        show={showTransfers}
        setShow={setShowTransfers}
      />
      <AddCoachEducationModal
        show={showEducations}
        setShow={setShowEducations}
      />
    </main>
  )
}

export default CoachProfile
