import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import { Dispatch, SetStateAction, memo, useEffect, useState } from 'react'
import classNames from '../../utils/classNames'

type ServerPaginationProps = {
  searchResult: any
  table: any
  searchInput?: any
  setSearchInput: Dispatch<SetStateAction<any>>
}

const ServerPagination = memo(
  ({
    searchResult,
    table,
    searchInput,
    setSearchInput,
  }: ServerPaginationProps) => {
    const totalPages =
      Math.floor(searchResult.totalRecords / searchResult.pageSize) + 1

    const [goToPage, setGoToPage] = useState<number>(searchResult.page)

    useEffect(() => {
      if (goToPage < 1 || goToPage > totalPages) return
      setSearchInput({
        ...searchInput,
        page: goToPage,
      })
    }, [goToPage])

    return (
      <div className='flex items-center justify-between  border-gray-200 bg-gray-50 px-4 py-3 sm:px-6'>
        <div className='flex flex-1 justify-between sm:hidden'>
          <button
            className='cursor-pointer relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-50'
            onClick={() => {
              setSearchInput({ ...searchInput, page: searchResult.page - 1 })
            }}
            disabled={searchResult.page === 1}
          >
            {'Предишна'}
          </button>
          <button
            className='relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50'
            onClick={() => {
              setSearchInput({ ...searchInput, page: searchResult.page + 1 })
            }}
            disabled={searchResult.page === totalPages}
          >
            {'Следваща'}
          </button>
        </div>
        <div className='hidden sm:flex sm:flex-1 sm:items-center sm:justify-between'>
          <div className='flex items-center gap-2'>
            <div>
              <p className='text-sm text-gray-700'>
                Показване от{' '}
                <span className='font-medium'>
                  {table.getState().pagination.pageIndex *
                    table.getState().pagination.pageSize +
                    1}
                </span>{' '}
                до{' '}
                <span className='font-medium'>
                  {table.getState().pagination.pageIndex *
                    table.getState().pagination.pageSize +
                    table.getRowModel().rows.length}
                </span>{' '}
                от общо{' '}
                <span className='font-medium'>{searchResult.totalRecords}</span>{' '}
                резултати
              </p>
            </div>

            <div>
              <span className='hidden lg:flex lg:items-center text-sm lg:gap-2'>
                | Отиди на страница
                <input
                  type='number'
                  value={goToPage}
                  min={1}
                  max={totalPages}
                  onChange={(e) => {
                    setGoToPage(Number(e.target.value))
                  }}
                  className='border p-1 rounded w-16 border-gray-200'
                />
              </span>
            </div>
          </div>
          <nav
            className='isolate inline-flex -space-x-px rounded-md shadow-sm'
            aria-label='Pagination'
          >
            <button
              className='relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20'
              onClick={() => {
                setSearchInput({ ...searchInput, page: searchResult.page - 1 })
              }}
              disabled={searchResult.page === 1}
            >
              <span className='sr-only'>{'Предишна'}</span>
              <ChevronLeftIcon className='h-5 w-5' aria-hidden='true' />
            </button>

            {arrayRange(searchResult.page - 2, searchResult.page + 2, 1).map(
              (page: number) => {
                if (page < 1 || page > totalPages) return null

                return (
                  <button
                    key={page}
                    className={classNames(
                      searchResult.page === page
                        ? 'z-10 bg-indigo-50 border-blue-500 text-blue-600'
                        : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50',
                      'relative inline-flex items-center border px-4 py-2 text-sm font-medium focus:z-20'
                    )}
                    onClick={() => setSearchInput({ ...searchInput, page })}
                  >
                    {page}
                  </button>
                )
              }
            )}

            <button
              className='relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20'
              onClick={() => {
                setSearchInput({ ...searchInput, page: searchResult.page + 1 })
              }}
              disabled={searchResult.page === totalPages}
            >
              <span className='sr-only'>Следваща</span>
              <ChevronRightIcon className='h-5 w-5' aria-hidden='true' />
            </button>
          </nav>
          <span className='hidden lg:flex lg:items-center text-sm lg:gap-1'>
            <select
              value={searchInput.pageSize}
              onChange={(e) => {
                setSearchInput({
                  ...searchInput,
                  page: 1,
                  pageSize: Number(e.target.value),
                })
              }}
              className='border p-1 rounded w-16 border-gray-200'
            >
              {[10, 20, 30, 40, 100].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
            Резултати на страница
          </span>
        </div>
      </div>
    )
  }
)

const arrayRange = (start: number, stop: number, step: number) =>
  Array.from(
    { length: (stop - start) / step + 1 },
    (value, index) => start + index * step
  )

export default ServerPagination
