import React from 'react'
import AddCardIndexForm from './AddCardIndexForm'
import { IClubDetails } from '../../models/Club'

type AddCardIndexProps = {
  club?: IClubDetails
}

const AddCardIndex = ({ club }: AddCardIndexProps) => {
  return <AddCardIndexForm />
}

export default AddCardIndex
