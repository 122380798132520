import { useParams } from 'react-router'
import UpdateCoachForm from './UpdateCoachForm'
import { useCoachData } from '../../hooks/useCoachesData'

const UpdateCoach = () => {
  const { id } = useParams() as { id: string }

  const { data: coach, isError, isLoading } = useCoachData(id)

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (isError) {
    return <div>Error fetching coach data...</div>
  }

  return <UpdateCoachForm coach={coach} />
}

export default UpdateCoach
