import { PlayerTransferType } from '../../models/PlayerTransfer'

type TransferDateProps = {
  data: {
    date: Date
    type: PlayerTransferType
  }
}

const TransferDate = ({ data }: TransferDateProps) => {
  if (data.type === 0) return <span>-</span>

  const date = new Date(data.date).toLocaleDateString('bg-BG', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  })
  return <span>{date}</span>
}

export default TransferDate
