import { useContext } from 'react'
import { VolleyTransfers } from '../../assets/SvgIcons'
import { IPlayerTransfer } from '../../models/PlayerTransfer'
import TransferProgress from './TransferProgress'
import TransferStatus from './TransferStatus'
import { AuthenticationContext } from '../../context/AuthenticationContext'
import { useTransferConfirmationAsAdminQuery } from '../../hooks/useTransfersData'

type TransferDetailsCardProps = {
  transfer: IPlayerTransfer
  readOnly?: boolean
}

const TransferDetailsCard = ({
  transfer,
  readOnly,
}: TransferDetailsCardProps) => {
  const { isAdmin } = useContext(AuthenticationContext)
  const confirm = useTransferConfirmationAsAdminQuery()

  const adminConfirm = () => {
    confirm.mutate(transfer.id)
  }

  return (
    <>
      <div className='px-4 sm:px-6 md:px-0'>
        <section className='relative rounded-lg bg-white shadow'>
          <header className='flex items-center justify-between border-b border-gray-200 px-4 py-5 sm:px-6'>
            <div className='inline-flex items-center'>
              <VolleyTransfers />
              <h3 className='text-2xl font-medium leading-6 text-gray-900'>
                Информация за трансфера
              </h3>
            </div>
            <div className='mt-4 flex gap-1 sm:mt-0 sm:ml-16 sm:flex-none'>
              {isAdmin() ? (
                <>
                  <button
                    type='button'
                    className='inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-3 py-1 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto'
                  >
                    Редактирай
                  </button>

                  {transfer.isConfirmedByAdmin ? null : (
                    <button
                      onClick={adminConfirm}
                      type='button'
                      className='inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-3 py-1 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto'
                    >
                      Потвърди
                    </button>
                  )}
                </>
              ) : null}
            </div>
          </header>

          <div className='px-4 py-5 sm:p-6'>
            <div className='grid grid-cols-12 gap-6'>
              <div className='col-span-12 sm:col-span-6 xl:col-span-4'>
                <span className='block text-sm font-medium text-gray-500'>
                  Статус
                </span>
                <span className='mt-1 block rounded-md bg-gray-50 p-2'>
                  <TransferStatus
                    data={{
                      startDate: transfer.startDate,
                      endDate: transfer.endDate,
                      isConfirmed:
                        transfer.isConfirmedByAdmin &&
                        transfer.isConfirmedByPlayer &&
                        transfer.isConfirmedByFromClub &&
                        transfer.isConfirmedByToClub,
                      type: transfer.type,
                    }}
                  />
                </span>
              </div>

              <div className='col-span-12 sm:col-span-6 xl:col-span-4'>
                <span className='block text-sm font-medium text-gray-500'>
                  Дата на трансфера
                </span>
                <span className='mt-1 block rounded-md bg-gray-50 p-2'>
                  {new Date(transfer.dateInitiated).toLocaleString('bg-BG', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                  }) || '-'}
                </span>
              </div>
              <div className='col-span-12 sm:col-span-6 xl:col-span-4'>
                <span className='block text-sm font-medium text-gray-500'>
                  Вид на трансфера
                </span>
                <span className='mt-1 block rounded-md bg-gray-50 p-2'>
                  {transfer.type === 0
                    ? 'Смяна на клубна принадлежност'
                    : transfer.type === 1
                    ? 'Трансфер'
                    : 'Международен'}
                </span>
              </div>

              <div className='col-span-12 sm:col-span-6 xl:col-span-4'>
                <span className='block text-sm font-medium text-gray-500'>
                  Срок на трансфера
                </span>
                <span className='mt-1 block rounded-md bg-gray-50 p-2'>
                  <div className='flex flex-grow items-center gap-4'>
                    <span className=''>
                      {new Date(transfer.startDate).toLocaleString('bg-BG', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                      }) || '-'}
                    </span>
                    <svg
                      className='svg-icon h-3'
                      width='1.2em'
                      height='1.2em'
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 30 26.6'
                      xmlSpace='preserve'
                    >
                      <path d='m29.7 12.6-8.8-8.8c-.4-.4-1-.4-1.3 0-.4.4-.4 1 0 1.3l7.2 7.2H1c-.5 0-1 .4-1 1 0 .5.4 1 1 1h25.8l-7.2 7.2c-.4.4-.4 1 0 1.3.2.2.4.3.7.3.2 0 .5-.1.7-.3l8.8-8.8c.3-.5.3-1.1-.1-1.4z'></path>
                    </svg>
                    <span className=''>
                      {new Date(transfer.endDate).toLocaleString('bg-BG', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                      }) || '-'}
                    </span>
                  </div>
                </span>
              </div>

              <div className='col-span-12 sm:col-span-6 xl:col-span-4'>
                <span className='block text-sm font-medium text-gray-500'>
                  Прогрес на трансфера
                </span>
                <span className='mt-1 block rounded-md bg-gray-50 p-2'>
                  <TransferProgress
                    data={{
                      isConfirmedByAdmin: transfer.isConfirmedByAdmin,
                      isConfirmedByPlayer: transfer.isConfirmedByPlayer,
                      isConfirmedByFromClub: transfer.isConfirmedByFromClub,
                      isConfirmedByToClub: transfer.isConfirmedByToClub,
                    }}
                  />
                </span>
              </div>

              <div className='col-span-12 sm:col-span-6 xl:col-span-4'>
                <span className='block text-sm font-medium text-gray-500'>
                  Потвъден от Админ
                </span>
                <span className='mt-1 block rounded-md bg-gray-50 p-2'>
                  {transfer.isConfirmedByAdmin
                    ? new Date(transfer.dateInitiated).toLocaleString('bg-BG', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit',
                      })
                    : 'Не'}
                </span>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default TransferDetailsCard
