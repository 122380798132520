import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { AuthenticationContext } from '../../context/AuthenticationContext'

const Logout = () => {
  const context = useContext(AuthenticationContext)
  const navigate = useNavigate()

  useEffect(() => {
    context.logout()
    navigate('/login')
  }, [])

  return <div>Logging Out..</div>
}

export default Logout
