import { InputField, PhotoInput, SelectField } from '../../shared/Inputs'
import { SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  PlayerCreateFormSchema,
  PlayerCreateFormSchemaType,
} from '../../../models/FormDataSchemas'
import useFilePreview from '../../../hooks/useFilePreview'
import { usePlayerCreate } from '../../../hooks/usePlayersData'
import { SpinningCircle } from '../../../assets/SvgIcons'

const PlayerCreateForm = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<PlayerCreateFormSchemaType>({
    resolver: zodResolver(PlayerCreateFormSchema),
  })

  const photo = watch('files')
  const [filePreview] = useFilePreview(photo)

  const { mutate } = usePlayerCreate()

  const onSubmit: SubmitHandler<PlayerCreateFormSchemaType> = (values) => {
    const formData = new FormData()

    const { files, ...player } = values
    formData.append('file', files[0])
    for (const key in player) {
      formData.append(key, player[key as keyof typeof player].toString())
    }

    mutate(formData)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='shadow sm:overflow-hidden sm:rounded-md'>
        <div className='space-y-6 bg-white px-4 py-6 sm:p-6'>
          <div>
            <h3 className='text-base font-semibold leading-6 text-gray-900'>
              Добавяне на състезател
            </h3>
            <p className='mt-1 text-sm text-gray-500'>
              Моля използвайте данни по лична карта и се уверете, че сте
              добавили снимка.
            </p>
          </div>

          <div className='grid grid-cols-6 gap-6'>
            <div className='col-span-6'>
              <PhotoInput<PlayerCreateFormSchemaType>
                name='files'
                label='Снимка на състезател'
                register={register}
                error={errors.files?.message?.toString()}
                preview={filePreview}
              />
            </div>

            <div className='col-span-6 md:col-span-6 xl:col-span-2'>
              <InputField<PlayerCreateFormSchemaType>
                label='Име'
                name='firstName'
                type='text'
                register={register}
                error={errors.firstName?.message?.toString()}
              />
            </div>

            <div className='col-span-6 md:col-span-6 xl:col-span-2'>
              <InputField<PlayerCreateFormSchemaType>
                label='Презиме'
                name='middleName'
                type='text'
                register={register}
                error={errors.middleName?.message?.toString()}
              />
            </div>

            <div className='col-span-6 md:col-span-6 xl:col-span-2'>
              <InputField<PlayerCreateFormSchemaType>
                label='Фамилия'
                name='lastName'
                type='text'
                register={register}
                error={errors.lastName?.message?.toString()}
              />
            </div>

            <div className='col-span-6 md:col-span-6 xl:col-span-2'>
              <InputField<PlayerCreateFormSchemaType>
                label='ЕГН'
                name='egn'
                type='text'
                register={register}
                error={errors.egn?.message?.toString()}
              />
            </div>

            <div className='col-span-6 md:col-span-6 xl:col-span-2'>
              <InputField<PlayerCreateFormSchemaType>
                label='Националност'
                name='nationality'
                type='text'
                register={register}
                error={errors.nationality?.message?.toString()}
              />
            </div>

            <div className='col-span-7 md:col-span-7 xl:col-span-2'>
              <InputField<PlayerCreateFormSchemaType>
                label='Град'
                name='cityName'
                type='text'
                register={register}
                error={errors.cityName?.message?.toString()}
              />
            </div>

            <div className='col-span-7 md:col-span-7 xl:col-span-3'>
              <SelectField<PlayerCreateFormSchemaType>
                label='Клуб по произход'
                name='firstClubId'
                register={register}
                optionsFor='clubs'
              />
            </div>

            <div className='col-span-7 md:col-span-7 xl:col-span-3'>
              <SelectField<PlayerCreateFormSchemaType>
                label='Първи треньор'
                name='firstCoachId'
                register={register}
                optionsFor='coaches'
              />
            </div>
          </div>
        </div>
        <div className='bg-gray-50 px-4 py-3 text-right sm:px-6'>
          <button
            type='submit'
            disabled={isSubmitting}
            className='inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
          >
            {isSubmitting ? <SpinningCircle /> : null}
            Добави
          </button>
        </div>
      </div>
    </form>
  )
}

export default PlayerCreateForm
