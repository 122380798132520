import { useFilesData } from '../../hooks/useFilesData'

type FileDownload = {
  fileId: string
}

const FileDownload = ({ fileId }: FileDownload) => {
  const { data: file, isError, isLoading } = useFilesData(fileId)

  if (isLoading) return <span>Please wait</span>

  if (isError || !file) return <span>File not available</span>

  return (
    <a
      href={file}
      download={true}
      className='text-indigo-600 hover:text-indigo-900'
    >
      Свали документ
    </a>
  )
}

export default FileDownload
