import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { cardIndexService } from '../services/CardIndexService'
import { notify } from '../components/shared/Notification'
import { useNavigate } from 'react-router-dom'
import { ICardIndexPlayer } from '../models/CardIndex'

export function useCardIndexesData() {
  return useQuery({
    queryKey: ['fetch-cardindexes'],
    queryFn: cardIndexService.getAll,
  })
}

export function useCardIndexData(id: string | undefined) {
  return useQuery({
    queryKey: ['fetch-cardindex', id],
    queryFn: () => cardIndexService.getById(id),
  })
}

export function useCardIndexPlayersData(id: number | undefined) {
  return useQuery({
    queryKey: ['fetch-cardindex-players', id],
    queryFn: () => cardIndexService.getPlayers(id),
  })
}

export function useCardIndexAvailablePlayersData(id: number) {
  return useQuery({
    queryKey: ['fetch-cardindex-available-players'],
    queryFn: () => cardIndexService.getAvailablePlayers(id),
  })
}

export function useCardIndexAgeGroupsData() {
  return useQuery({
    queryKey: ['fetch-cardindex-agegroups'],
    queryFn: () =>
      cardIndexService.getAgeGroups() as Promise<
        Array<{ value: number; label: string }>
      >,
  })
}

export function useAddCardIndex() {
  const navigate = useNavigate()
  return useMutation({
    mutationFn: (cardIndex: FormData) =>
      cardIndexService.addCardIndex(cardIndex),
    onSuccess: (res) => {
      notify.success('Лицензът е добавен успешно')
      navigate(`/cardindexes/${res.id}`)
    },
    onError: (err: unknown) => {
      console.log(err)
      notify.error('Възникна грешка при добавянето на лиценз')
    },
  })
}
export function useCardIndexPlayerCreate() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: ICardIndexPlayer) =>
      cardIndexService.cardIndexPlayerCreate(data),
    onSuccess: (res, variables) => {
      notify.success('Състезателят е добавен успешно!')
      console.log(variables)
      queryClient.invalidateQueries({
        queryKey: ['fetch-cardindex', String(variables.cardIndexId)],
      })
      console.log(res)
    },
    onError: (err: unknown) => {
      console.log(err)
      notify.error('Възникна грешка при добавянето на състезател!')
    },
  })
}
