import { Link } from 'react-router-dom'
import { LinkIcon, PlayerDocumentsSvg } from '../../assets/SvgIcons'
import { Fragment, useRef } from 'react'
import { PrinterIcon } from '@heroicons/react/20/solid'
import { CardIndexT } from '../../models/CardIndex'
import { useReactToPrint } from 'react-to-print'
import CardIndexPrintContent from './CardIndexPrintContent'

type CardIndexCardProps = {
  cardIndex: CardIndexT
}

const CardIndexCard = ({ cardIndex }: CardIndexCardProps) => {
  const readOnly = false
  const componentRef = useRef<HTMLDivElement>(null)
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })

  return (
    <div className='px-4 sm:px-6 md:px-6'>
      <section className='relative rounded-lg bg-white shadow'>
        {readOnly ? null : (
          <header className='flex items-center justify-between border-b border-gray-200 px-4 py-5 sm:px-6'>
            <div className='inline-flex items-center'>
              <PlayerDocumentsSvg />
              <h3 className='text-2xl font-medium leading-6 text-gray-900'>
                Данни за лиценз
              </h3>
            </div>
            <div>
              <button
                type='button'
                onClick={handlePrint}
                className='inline-flex items-center justify-center gap-x-1.5 rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto'
              >
                <PrinterIcon className='-ml-0.5 h-5 w-5' aria-hidden='true' />
                Принтирай
              </button>
              <div className='hidden'>
                <CardIndexPrintContent
                  cardIndex={cardIndex}
                  ref={componentRef}
                />
              </div>
            </div>
          </header>
        )}
        <div className='px-4 py-5 sm:p-6'>
          <div className='grid grid-cols-4 gap-4 '>
            <div className='col-span-4 lg:col-span-2'>
              <span className='block text-sm font-medium text-gray-500'>
                Клуб
              </span>
              <span className='mt-1 block rounded-md bg-gray-50 p-2'>
                <span className='inline-flex items-center gap-1'>
                  {cardIndex.club ? (
                    <Fragment>
                      {cardIndex.club.fullName}
                      <Link to={`/coaches/${cardIndex.clubId}`}>
                        <div className='transition-color rounded-full bg-blue-50 p-1 text-blue-500 hover:bg-blue-500 hover:text-white'>
                          <LinkIcon />
                        </div>
                      </Link>
                    </Fragment>
                  ) : (
                    <span className='text-gray-400'>Няма информация</span>
                  )}
                </span>
              </span>
            </div>

            <div className='col-span-4 lg:col-span-2'>
              <span className='block text-sm font-medium text-gray-500'>
                Номер на лиценз
              </span>
              <span className='mt-1 block rounded-md bg-gray-50 p-2'>
                {cardIndex.id}
              </span>
            </div>

            <div className='col-span-4 lg:col-span-2'>
              <span className='block text-sm font-medium text-gray-500'>
                Възрастова група
              </span>
              <span className='mt-1 block rounded-md bg-gray-50 p-2'>
                {cardIndex.ageGroup ?? (
                  <span className='text-gray-400'>Няма информация</span>
                )}
              </span>
            </div>

            <div className='col-span-4 lg:col-span-2'>
              <span className='block text-sm font-medium text-gray-500'>
                Сезон
              </span>
              <span className='mt-1 block rounded-md bg-gray-50 p-2'>
                {cardIndex.year + '/' + (cardIndex.year + 1)}
              </span>
            </div>

            <div className='col-span-4 lg:col-span-2'>
              <span className='block text-sm font-medium text-gray-500'>
                Старши треньор
              </span>
              <span className='mt-1 block rounded-md bg-gray-50 p-2'>
                <span className='inline-flex items-center gap-1'>
                  {cardIndex.seniorCoachId ? (
                    <Fragment>
                      {cardIndex.seniorCoach?.name}
                      <Link to={`/coaches/${cardIndex.seniorCoachId}`}>
                        <div className='transition-color rounded-full bg-blue-50 p-1 text-blue-500 hover:bg-blue-500 hover:text-white'>
                          <LinkIcon />
                        </div>
                      </Link>
                    </Fragment>
                  ) : (
                    <span className='text-gray-400'>Няма информация</span>
                  )}
                </span>
              </span>
            </div>

            <div className='col-span-4 lg:col-span-2'>
              <span className='block text-sm font-medium text-gray-500'>
                Помощник треньор
              </span>
              <span className='mt-1 block rounded-md bg-gray-50 p-2'>
                <span className='inline-flex items-center gap-1'>
                  {cardIndex.coachId ? (
                    <Fragment>
                      {cardIndex.coach?.name}
                      <Link to={`/coaches/${cardIndex.coachId}`}>
                        <div className='transition-color rounded-full bg-blue-50 p-1 text-blue-500 hover:bg-blue-500 hover:text-white'>
                          <LinkIcon />
                        </div>
                      </Link>
                    </Fragment>
                  ) : (
                    <span className='text-gray-400'>Няма информация</span>
                  )}
                </span>
              </span>
            </div>

            <div className='col-span-4 lg:col-span-2'>
              <span className='block text-sm font-medium text-gray-500'>
                Втори треньор
              </span>
              <span className='mt-1 block rounded-md bg-gray-50 p-2'>
                <span className='inline-flex items-center gap-1'>
                  {cardIndex.coach2Id ? (
                    <Fragment>
                      {cardIndex.coach2?.name}
                      <Link to={`/coaches/${cardIndex.coach2Id}`}>
                        <div className='transition-color rounded-full bg-blue-50 p-1 text-blue-500 hover:bg-blue-500 hover:text-white'>
                          <LinkIcon />
                        </div>
                      </Link>
                    </Fragment>
                  ) : (
                    <span className='text-gray-400'>Няма информация</span>
                  )}
                </span>
              </span>
            </div>

            <div className='col-span-4 lg:col-span-2'>
              <span className='block text-sm font-medium text-gray-500'>
                Лекар
              </span>
              <span className='mt-1 block rounded-md bg-gray-50 p-2'>
                {cardIndex.medic ?? (
                  <span className='text-gray-400'>Няма информация</span>
                )}
              </span>
            </div>
            <div className='col-span-4 lg:col-span-2'>
              <span className='block text-sm font-medium text-gray-500'>
                Физиотерапевт
              </span>
              <span className='mt-1 block rounded-md bg-gray-50 p-2'>
                {cardIndex.masseur ?? (
                  <span className='text-gray-400'>Няма информация</span>
                )}
              </span>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default CardIndexCard
