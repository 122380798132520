import { useParams } from 'react-router'
import { useUserData } from '../../hooks/useUsersData'
import UpdateUser from './UpdateUser'
import UserClubClaims from './UserClubClaims'
import DeleteUser from './DeleteUser'
import PasswordChange from './PasswordChange'
import UserStats from './UserStats'
import UserRoles from './UserRoles'
import PasswordReset from './PasswordReset'

type UserDetailsParams = {
  userId: string
}

const UserDetails = () => {
  const { userId } = useParams() as UserDetailsParams

  const { data: user, isLoading, isError } = useUserData(userId)

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (isError) {
    return <div>Error fetching user..</div>
  }

  return (
    <>
      <div className='mx-auto grid max-w-3xl grid-cols-1 gap-5'>
        <UserStats isActive={user.isDeleted} roles={user.userRoles} />
        <UpdateUser user={user} />

        {/* <PasswordChange userId={user.id} /> */}

        <UserClubClaims userId={user.id} />

        <UserRoles roles={user.userRoles} />

        <PasswordReset userId={user.id} />

        <DeleteUser isDeleted={user.isDeleted} userId={user.id} />
      </div>
    </>
  )
}

export default UserDetails
