import { cardIndexService } from './../services/CardIndexService'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { playerService } from '../services/PlayerService'
import { notify } from '../components/shared/Notification'
import { ISearchInput } from '../models/BaseModels'
import { useNavigate } from 'react-router'

export function usePlayersSearchData(userSearchInput: ISearchInput) {
  const { keyword, page, pageSize, orderBy, desc } = userSearchInput
  return useQuery({
    queryKey: ['search-players', keyword, page, pageSize, orderBy, desc],
    queryFn: () =>
      playerService.get({
        page,
        pageSize,
        orderBy,
        desc,
        keyword,
      }),
    onError(err) {
      notify.error(
        'Възникна грешка при зареждане на потребителите! Моля, опитайте отново по-късно.',
      )
      console.error(err)
    },
  })
}

export function usePlayersData() {
  return useQuery({
    queryKey: ['fetch-players'],
    queryFn: playerService.getAll,
  })
}

export function usePlayerData(id: string | undefined) {
  return useQuery({
    queryKey: ['fetch-player', id],
    queryFn: () => playerService.getById(id),
  })
}

export function usePlayerCreate() {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (player: FormData) => playerService.create(player),
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ['fetch-players'] })
      notify.success('Състезателят е добавен успешно!')
      navigate('/players/' + res.id)
    },
    onError: (err) => {
      console.log(err)
      notify.error(`Възникна грешка при добавянето на състезател! ${err}`)
    },
  })
}

export function usePlayerUpdate(id: string) {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (player: FormData) => playerService.update(id, player),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['fetch-players'] })
      notify.success('Състезателят е обновен успешно!')
      navigate('/players/' + id)
    },
    onError: (err) => {
      console.log(err)
      notify.error(`Възникна грешка при обновяването на състезател! ${err}`)
    },
  })
}

export function usePlayerDevelopmentsData(id: number | undefined) {
  return useQuery({
    queryKey: ['fetch-player-developments', id],
    queryFn: () => playerService.getPlayerDevelopments(id),
  })
}

export function usePlayerPositionsData() {
  return useQuery({
    queryKey: ['fetch-player-positions'],
    queryFn: playerService.getPlayerPositions,
  })
}

export function usePlayerDocumentTypesData() {
  return useQuery({
    queryKey: ['fetch-player-document-types'],
    queryFn: playerService.getPlayerDocumentTypes,
  })
}

export function usePlayersWithExpiringContractsData() {
  return useQuery({
    queryKey: ['fetch-players-expiring-contracts'],
    queryFn: playerService.getPlayersWithExpiringContracts,
  })
}

export function usePlayerDocumentsData(id: number) {
  return useQuery({
    queryKey: ['fetch-player-documents', id],
    queryFn: () => playerService.getPlayerDocuments(id),
  })
}

export function usePlayerCardIndexesData(id: number) {
  return useQuery({
    queryKey: ['fetch-player-cardindexes', id],
    queryFn: () => cardIndexService.getByPlayerId(id),
  })
}

export function usePlayerTransfersData(id: number) {
  return useQuery({
    queryKey: ['fetch-player-transfers', id],
    queryFn: () => playerService.getPlayerTransfers(id),
  })
}

export function useAddPlayerDevelopment() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (development: FormData) =>
      playerService.addPlayerDevelopment(development),
    onSuccess: (res) => {
      const id = res.playerId
      queryClient.invalidateQueries({
        queryKey: ['fetch-player-developments', id],
      })
      notify.success('Развитието на състезателят е добавено успешно')
    },
    onError: (err) => {
      console.log(err)
      notify.error(
        `Възникна грешка при добавяне на развитие на състезателя! ${err}`,
      )
    },
  })
}
export function useAddPlayerDocument(playerId: string) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['add-player-document', playerId],
    mutationFn: (document: FormData) =>
      playerService.addPlayerDocument(document),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['fetch-player-documents'],
      })
      notify.success('Документът е добавено успешно')
    },
    onError: (err) => {
      console.log(err)
      notify.error('Възникна грешка при добавяне на документ!')
    },
  })
}

export function usePlayerDelete(playerId: string) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['player-delete', playerId],
    mutationFn: () => playerService.deletePlayer(playerId),
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: ['fetch-player', playerId],
      })
      notify.success(
        `Състезателят беше ${
          res.isDeleted ? 'деактивиран' : 'активиран'
        } успешно!`,
      )
    },
    onError: (err) => {
      console.log(err)
      notify.error(`Възникна грешка при промяна статуса на състезателя!`)
    },
  })
}
