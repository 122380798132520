import { OptionsT } from '../models/FormDataSchemas'
import { IRegion, IRegionCreate } from '../models/Region'
import { getData, postData } from './AxiosHelper'

const get = (): Promise<IRegion[]> => {
  return getData(`/api/region`, true) as Promise<IRegion[]>
}

const getById = (id: string): Promise<IRegion> => {
  return getData(`/api/region/${id}`, true) as Promise<IRegion>
}

const create = (region: IRegionCreate): Promise<IRegion> => {
  return postData(`/api/region`, region, true) as Promise<IRegion>
}

const getOptions = (): Promise<OptionsT[]> => {
  return getData(`/api/region/options`, true) as Promise<OptionsT[]>
}

export const regionService = {
  get,
  getById,
  create,
  getOptions,
}
