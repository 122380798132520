import { getData, postData, putData } from './AxiosHelper'
import { ICoach } from '../models/Coach'
import { ICoachEducation } from '../models/CoachEducation'
import { CardIndexT } from '../models/CardIndex'
import { educationLevels } from '../utils/staticData'

const getAll = () => getData('/api/coaches', true) as Promise<ICoach[]>

const getById = (id: string) =>
  getData(`/api/coaches/${id}`, true) as Promise<ICoach>

const addCoach = (coach: FormData) =>
  postData('/api/coaches', coach, true) as Promise<ICoach>

const updateCoach = (coach: FormData) =>
  putData(`/api/coaches/${coach.get('id')}`, coach, true) as Promise<ICoach>

const getEducations = (id: string) =>
  getData(`/api/coaches/${id}/educations`, true) as Promise<ICoachEducation[]>

const addEducation = (education: FormData) =>
  postData(
    '/api/coaches/educations',
    education,
    true,
  ) as Promise<ICoachEducation>

const getCardIndexes = (id: number) =>
  getData(`/api/coaches/${id}/cardindexes`, true) as Promise<CardIndexT[]>

const getEducationLevels = () => Promise.resolve(educationLevels)

export const coachService = {
  getAll,
  getById,
  getEducations,
  getEducationLevels,
  addEducation,
  getCardIndexes,
  addCoach,
  updateCoach,
}
