import { PlayerTransferType } from '../../models/PlayerTransfer'

type PlayerTransferTypeProps = {
  type: PlayerTransferType
}

const TransferType = ({ type }: PlayerTransferTypeProps) => {
  switch (type) {
    case 0:
      return <div>Преотстъпване</div>
    case 1:
      return <div>Договор</div>
    case 2:
      return <div>Международен</div>
    default:
      return <div>-</div>
  }
}

export default TransferType
