import { useState } from 'react'
import { PlayerDumbbell } from '../../assets/SvgIcons'
import { usePlayerDevelopmentsData } from '../../hooks/usePlayersData'
import AddPlayerDevelopmentModal from './AddPlayerDevelopmentModal'
import { playerPositions } from '../../utils/staticData'

type PlayerDevelopmentListProps = {
  id: number
}

const PlayerDevelopmentList = ({ id }: PlayerDevelopmentListProps) => {
  const {
    isLoading,
    data: playerDevelopments,
    isError,
  } = usePlayerDevelopmentsData(id)

  const [showModal, setShowModal] = useState(false)

  if (isError) return <div>Unable to fetch Player Developments data</div>

  if (isLoading) return <div>Loading...</div>

  return (
    <>
      <div className='mt-10 px-4 sm:px-6 md:px-0'>
        <section className='relative rounded-lg bg-white shadow'>
          {/* Card Header */}
          <header className='flex items-center justify-between border-b border-gray-200 px-4 py-5 sm:px-6'>
            <div className='inline-flex items-center'>
              <PlayerDumbbell />
              <h3 className='text-2xl font-medium leading-6 text-gray-900'>
                Развитие
              </h3>
            </div>
            <div>
              <button
                type='button'
                onClick={() => setShowModal(true)}
                className='inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto'
              >
                Добави
              </button>
            </div>
          </header>

          {/* Card Body */}
          <div className='px-4 sm:px-6'>
            <div className='flex flex-col'>
              <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-6'>
                <div className='inline-block min-w-full py-2 align-middle'>
                  <div className='overflow-hidden rounded-lg'>
                    <table className='min-w-full divide-y divide-gray-200'>
                      <thead className='bg-gray-50'>
                        <tr className='divide-x divide-gray-200'>
                          <th
                            scope='col'
                            className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8'
                          >
                            Дата
                          </th>
                          <th
                            scope='col'
                            className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                          >
                            Височина
                          </th>
                          <th
                            scope='col'
                            className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                          >
                            Тегло
                          </th>
                          <th
                            scope='col'
                            className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                          >
                            Позиция
                          </th>
                          <th
                            scope='col'
                            className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                          >
                            Разтег
                          </th>
                          <th
                            scope='col'
                            className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                          >
                            Атака
                          </th>
                          <th
                            scope='col'
                            className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                          >
                            Блокада
                          </th>
                        </tr>
                      </thead>
                      <tbody className='divide-y divide-gray-200 bg-white'>
                        {playerDevelopments.length > 0 ? (
                          playerDevelopments.map((development) => {
                            const dateAdded = new Date(development.date)
                            return (
                              <tr
                                key={development.id}
                                className='divide-x divide-gray-200'
                              >
                                <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-500 sm:pl-6 lg:pl-8'>
                                  {dateAdded.toLocaleDateString('bg-BG', {
                                    year: 'numeric',
                                    month: '2-digit',
                                    day: '2-digit',
                                  })}
                                </td>

                                <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                  {development.height} см
                                </td>
                                <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                  {development.weight} кг
                                </td>
                                <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                  {
                                    playerPositions.find(
                                      (position) =>
                                        position.value === development.position,
                                    )?.label
                                  }
                                </td>
                                <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                  {development.fullExtent} см
                                </td>
                                <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                  {development.attack} см
                                </td>
                                <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                  {development.block} см
                                </td>
                              </tr>
                            )
                          })
                        ) : (
                          <tr className='divide-x divide-gray-200'>
                            <td
                              colSpan={7}
                              className='whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500'
                            >
                              <span className='text-center'>
                                Няма добавени данни за развитие
                              </span>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <AddPlayerDevelopmentModal show={showModal} setShow={setShowModal} />
    </>
  )
}

export default PlayerDevelopmentList
