import { useState } from 'react'
import { DefaultPhoto } from '../../assets/SvgIcons'

type TableImageProps = {
  className?: string
  photoId?: string | null
  imageType: 'person' | 'club'
}

const TableImage = ({ className, photoId, imageType }: TableImageProps) => {
  const [imageError, setImageError] = useState<boolean>(false)

  const baseUrl =
    'https://cdn.bgvolley.dev/' +
    (imageType === 'person' ? 'images/' : 'club-logos/')

  const url = baseUrl + photoId

  if (!photoId || imageError) return <DefaultPhoto className={className} />

  const handleImageError = () => {
    setImageError(true)
  }

  return (
    <img
      className={className}
      src={url}
      alt='Player'
      onError={handleImageError}
    />
  )
}
export default TableImage
