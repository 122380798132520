import { InputField, PhotoInput, SelectField } from '../../shared/Inputs'
import { SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  CreateClubFormSchema,
  CreateClubFormSchemaType,
} from '../../../models/FormDataSchemas'
import { useClubCreate } from '../../../hooks/useClubsData'
import useFilePreview from '../../../hooks/useFilePreview'

const AddClubForm = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<CreateClubFormSchemaType>({
    resolver: zodResolver(CreateClubFormSchema),
    defaultValues: {
      type: 'ВК',
      title: 'Сдружение',
      regionId: 1,
    },
  })

  const photo = watch('files')
  const [filePreview] = useFilePreview(photo)

  const addClub = useClubCreate()

  const onSubmit: SubmitHandler<CreateClubFormSchemaType> = (values) => {
    const formData = new FormData()

    const { files, regionId, ...club } = values
    formData.append('file', files[0])
    formData.append('regionId', regionId.toString())
    for (const key in club) {
      formData.append(key, String(club[key as keyof typeof club]))
    }

    addClub.mutate(formData)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='shadow sm:overflow-hidden sm:rounded-md'>
        <div className='space-y-6 bg-white px-4 py-6 sm:p-6'>
          <div>
            <h3 className='text-base font-semibold leading-6 text-gray-900'>
              Добавяне на нов клуб
            </h3>
            <p className='mt-1 text-sm text-gray-500'></p>
          </div>

          <div className='grid grid-cols-6 gap-6'>
            <div className='col-span-6'>
              <PhotoInput<CreateClubFormSchemaType>
                name='files'
                label='Лого на клуба'
                register={register}
                error={errors.files?.message?.toString()}
                preview={filePreview}
              />
            </div>

            <div className='col-span-6 md:col-span-3 xl:col-span-1'>
              <InputField<CreateClubFormSchemaType>
                label='Правна форма'
                name='title'
                type='text'
                placeholder='e.g. Сдружение/АД'
                register={register}
                error={errors.title?.message?.toString()}
              />
            </div>

            <div className='col-span-6 md:col-span-3 xl:col-span-1'>
              <InputField<CreateClubFormSchemaType>
                label='ВК/СКВ/СК'
                name='type'
                type='text'
                placeholder='e.g. ВК/СКВ/СК'
                register={register}
                error={errors.type?.message?.toString()}
              />
            </div>

            <div className='col-span-6 md:col-span-3 xl:col-span-3'>
              <InputField<CreateClubFormSchemaType>
                label='Име на клуба'
                name='name'
                type='text'
                placeholder='e.g. Троян Волей'
                register={register}
                error={errors.name?.message?.toString()}
              />
            </div>

            <div className='col-span-6 md:col-span-3 xl:col-span-1'>
              <SelectField<CreateClubFormSchemaType>
                label='Регион'
                name='regionId'
                register={register}
                optionsFor='regions'
                // error={errors.regionId?.message?.toString()}
              />
            </div>

            <div className='col-span-6 md:col-span-3 xl:col-span-1'>
              <InputField<CreateClubFormSchemaType>
                label='Рег. № в НР на ММС'
                name='licenseNumber'
                type='text'
                placeholder='e.g. 123-456/12.12.2012'
                register={register}
                error={errors.licenseNumber?.message?.toString()}
              />
            </div>

            <div className='col-span-6 md:col-span-3 xl:col-span-1'>
              <InputField<CreateClubFormSchemaType>
                label='ЕИК/БУЛСТАТ'
                name='bulstat'
                type='text'
                placeholder='e.g. 205338036'
                register={register}
                error={errors.bulstat?.message?.toString()}
              />
            </div>
            <div className='col-span-6 md:col-span-6 xl:col-span-3'>
              <InputField<CreateClubFormSchemaType>
                label='Адрес'
                name='address'
                type='text'
                placeholder='e.g. ул. "Христо Ботев" №1'
                register={register}
                error={errors.address?.message?.toString()}
              />
            </div>
            <div className='col-span-6 md:col-span-3 xl:col-span-1'>
              <InputField<CreateClubFormSchemaType>
                label='Град'
                name='cityName'
                type='text'
                placeholder='e.g. София'
                register={register}
                error={errors.cityName?.message?.toString()}
              />
            </div>

            <div className='col-span-6 md:col-span-3 xl:col-span-2'>
              <InputField<CreateClubFormSchemaType>
                label='E-mail'
                name='email'
                type='text'
                placeholder='e.g. example@your-domain.com'
                register={register}
                error={errors.email?.message?.toString()}
              />
            </div>

            <div className='col-span-6 md:col-span-3 xl:col-span-2'>
              <InputField<CreateClubFormSchemaType>
                label='Телефон'
                name='contactNumber'
                type='text'
                placeholder='e.g. +359 123 456 789'
                register={register}
                error={errors.contactNumber?.message?.toString()}
              />
            </div>

            <div className='col-span-6 md:col-span-3 xl:col-span-2'>
              <InputField<CreateClubFormSchemaType>
                label='Име на лице за контакт'
                name='contactPerson'
                type='text'
                placeholder='e.g. Иван Иванов'
                register={register}
                error={errors.contactPerson?.message?.toString()}
              />
            </div>

            <div className='col-span-6 md:col-span-3 xl:col-span-3'>
              <InputField<CreateClubFormSchemaType>
                label='Президент'
                name='president'
                type='text'
                placeholder='e.g. Иван Иванов'
                register={register}
                error={errors.president?.message?.toString()}
              />
            </div>

            <div className='col-span-6 md:col-span-3 xl:col-span-3'>
              <InputField<CreateClubFormSchemaType>
                label='Website URL'
                name='websiteUrl'
                type='text'
                placeholder='e.g. https://www.example.com'
                register={register}
                error={errors.websiteUrl?.message?.toString()}
              />
            </div>

            <div className='col-span-6 md:col-span-3 xl:col-span-3'>
              <InputField<CreateClubFormSchemaType>
                label='Facebook URL'
                name='facebookUrl'
                type='text'
                placeholder='e.g. https://www.facebook.com/YourClubPage'
                register={register}
                error={errors.facebookUrl?.message?.toString()}
              />
            </div>

            <div className='col-span-6 md:col-span-3 xl:col-span-3'>
              <InputField<CreateClubFormSchemaType>
                label='Instagram URL'
                name='instagramUrl'
                type='text'
                placeholder='e.g. https://www.instagram.com/your_club_page'
                register={register}
                error={errors.instagramUrl?.message?.toString()}
              />
            </div>

            <div className='col-span-6 md:col-span-3 xl:col-span-3'>
              <InputField<CreateClubFormSchemaType>
                label='YouTube URL'
                name='youtubeUrl'
                type='text'
                placeholder='e.g. https://www.instagram.com/@YourClubPage'
                register={register}
                error={errors.youtubeUrl?.message?.toString()}
              />
            </div>

            <div className='col-span-6 md:col-span-3 xl:col-span-3'>
              <InputField<CreateClubFormSchemaType>
                label='LinkedIn URL'
                name='linkedInUrl'
                type='text'
                placeholder='e.g. https://www.linkedin.com/company/your-club-page'
                register={register}
                error={errors.linkedInUrl?.message?.toString()}
              />
            </div>

            <div className='col-span-6 md:col-span-3 xl:col-span-3'>
              <InputField<CreateClubFormSchemaType>
                label='X/Тwitter URL'
                name='xUrl'
                type='text'
                placeholder='e.g. https://twitter.com/YourClubPage'
                register={register}
                error={errors.xUrl?.message?.toString()}
              />
            </div>

            <div className='col-span-6 md:col-span-3 xl:col-span-3'>
              <InputField<CreateClubFormSchemaType>
                label='TikTok URL'
                name='tiktokUrl'
                type='text'
                placeholder='e.g. https://www.tiktok.com/@your_club_page'
                register={register}
                error={errors.tiktokUrl?.message?.toString()}
              />
            </div>
          </div>
        </div>
        <div className='bg-gray-50 px-4 py-3 text-right sm:px-6'>
          <button
            type='submit'
            disabled={isSubmitting}
            className='inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
          >
            Добави
          </button>
        </div>
      </div>
    </form>
  )
}

export default AddClubForm
