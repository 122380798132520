import {
  getData,
  upsertData,
  postData,
  putData,
  deleteData,
} from './AxiosHelper'

// define public methods
export const userService = {
  get,
  getById,
  post,
  getUserRoles,
  addRoleToUser,
  deleteRoleFromUser,
  getUserClubs,
  addClubToUser,
  removeClubFromUser,
  updatePassword,
  resetPassword,
  deleteUser,
  restoreUser,
}

function get(page, pagesize, orderBy, desc, keyword, roleid) {
  let url = `/api/user?page=${page}&pagesize=${pagesize}`

  if (keyword && keyword.length > 0) url += '&keyword=' + escape(keyword)

  if (roleid && roleid.length > 0) url += '&roleid=' + roleid

  if (orderBy) url += '&orderBy=' + orderBy

  if (desc) url += '&desc=' + desc

  return getData(url, true)
}

function getById(id) {
  return getData(`/api/user/${id}`, true)
}

function post(data) {
  return upsertData(data.id, `/api/user`, data, true)
}

function getUserRoles(id) {
  return getData(`/api/user/${id}/roles`, true)
}

function addRoleToUser(userId, roleId, startDate, expiryDate) {
  let data = {
    userId,
    roleId,
    startDate,
    expiryDate,
  }

  return postData(`/api/user/${userId}/role`, data, true)
}

function getUserClubs(id) {
  return getData(`/api/user/${id}/claim/clubs`, true)
}

function addClubToUser(userId, clubId) {
  let data = {
    userId,
    clubId,
  }

  return postData(`/api/user/${userId}/claim/club/${clubId}`, data, true)
}

function removeClubFromUser(userId, clubId) {
  return deleteData(`/api/user/${userId}/claim/club/delete/${clubId}`)
}

function deleteRoleFromUser(userId, roleId) {
  return deleteData(`/api/user/${userId}/role/${roleId}`)
}

function updatePassword(values) {
  return putData(`/api/user/${values.id}/password`, values, true)
}

function resetPassword(userId) {
  return postData(`/api/user/${userId}/resetpassword`, null, true)
}

function deleteUser(id) {
  return deleteData(`/api/user/${id}`)
}

function restoreUser(id) {
  return putData(`/api/user/${id}/restore`, null, true)
}
