import { useState } from 'react'
import { Combobox } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import {
  useCardIndexAvailablePlayersData,
  useCardIndexPlayerCreate,
} from '../../hooks/useCardIndexData'
import { Player } from '../../models/Player'
import PlayerImage from '../shared/TableImage'
import Button from '../core/ui/Button'
import { ICardIndexPlayer } from '../../models/CardIndex'
import { SpinningCircle } from '../../assets/SvgIcons'
import classNames from '../../utils/classNames'

type AddPlayerToCardIndexProps = {
  cardIndexId: number
}

const AddPlayerToCardIndex = ({ cardIndexId }: AddPlayerToCardIndexProps) => {
  const [query, setQuery] = useState('')
  const [selectedPerson, setSelectedPerson] = useState<Player>({} as Player)
  const {
    isError,
    data: availablePlayers,
    isLoading,
  } = useCardIndexAvailablePlayersData(cardIndexId)

  const { mutate, isLoading: isMutating } = useCardIndexPlayerCreate()

  if (isLoading) {
    return (
      <div className='px-4 sm:px-6 md:px-6'>
        <div className='sm:flex sm:items-center'>
          <div className='sm:flex-auto'>
            <h1 className='text-base font-semibold leading-6 text-gray-900'>
              Добави състезател към лиценза:
            </h1>
          </div>
        </div>

        <div
          role='status'
          className='w-2/3 animate-pulse divide-y divide-gray-200 rounded border border-gray-200 p-5 shadow'
        >
          <div className='flex w-full items-center justify-between'>
            <div className='h-6 w-2/3 rounded-lg bg-gray-300'></div>
            <div className='h-6 w-1/4 rounded-lg bg-gray-300'></div>
          </div>
        </div>
      </div>
    )
  }

  if (isError) {
    return (
      <div className='px-4 sm:px-6 md:px-6'>
        <div className='bg-white shadow sm:rounded-lg'>
          <div className='px-4 py-5 sm:p-6'>
            <h3 className='text-base font-semibold leading-6 text-gray-900'>
              Добавяне на състезател
            </h3>
            <div className='mt-2 max-w-xl text-sm text-gray-500'>
              <p>
                Не бяха открити състезатели, които отговарят на критериите по
                наредба!
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  if (!availablePlayers) {
    return (
      <div className='px-4 sm:px-6 md:px-6'>
        <div className='bg-white shadow sm:rounded-lg'>
          <div className='px-4 py-5 sm:p-6'>
            <h3 className='text-base font-semibold leading-6 text-gray-900'>
              Добавяне на състезател
            </h3>
            <div className='mt-2 max-w-xl text-sm text-gray-500'>
              <p>
                Към лиценза могат да бъдат добавени само състезатели, които
                отговарят на критериите по наредба!
              </p>
            </div>
            <div>
              <h2 className='text-base font-medium text-gray-500'>
                Не са открити състезатели, които могат да бъдат добавени!
              </h2>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const filteredPeople =
    query === ''
      ? availablePlayers
      : availablePlayers.filter((person) =>
          `${person.firstName} ${person.middleName} ${person.lastName}`
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(query.toLowerCase().replace(/\s+/g, '')),
        )

  const handleSubmit = () => {
    if (selectedPerson.id === undefined) {
      return
    }

    const data: ICardIndexPlayer = {
      cardIndexId: cardIndexId,
      playerId: selectedPerson.id,
    }

    mutate(data)

    setSelectedPerson({} as Player)
  }

  return (
    <div className='px-4 sm:px-6 md:px-6'>
      <div className='bg-white shadow sm:rounded-lg'>
        <div className='px-4 py-5 sm:p-6'>
          <h3 className='text-base font-semibold leading-6 text-gray-900'>
            Добавяне на състезател
          </h3>
          <div className='mt-2 max-w-xl text-sm text-gray-500'>
            <p>
              Към лиценза могат да бъдат добавени само състезатели, които
              отговарят на критериите по наредба!
            </p>
          </div>
          <div className='mt-5 sm:flex sm:items-center'>
            <div className='w-full sm:max-w-xl'>
              <Combobox
                as='div'
                value={selectedPerson}
                onChange={setSelectedPerson}
              >
                <div className='relative'>
                  <Combobox.Input
                    className='w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                    onChange={(event) => setQuery(event.target.value)}
                    placeholder='Търси състезател'
                    displayValue={(person: Player) =>
                      person.firstName !== undefined
                        ? `${person?.firstName} ${person?.lastName}`
                        : ''
                    }
                  />
                  <Combobox.Button className='absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none'>
                    <ChevronUpDownIcon
                      className='h-5 w-5 text-gray-400'
                      aria-hidden='true'
                    />
                  </Combobox.Button>

                  {filteredPeople.length > 0 && (
                    <Combobox.Options className='absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
                      {filteredPeople.map((person) => (
                        <Combobox.Option
                          key={person.id}
                          value={person}
                          className={({ active }) =>
                            classNames(
                              'relative mx-1 cursor-default select-none rounded py-2 pl-3 pr-9',
                              active
                                ? 'bg-indigo-600 text-white'
                                : 'text-gray-900',
                            )
                          }
                        >
                          {({ active, selected }) => (
                            <>
                              <div className='flex items-center'>
                                <PlayerImage
                                  className='h-6 w-6 flex-shrink-0 rounded-full object-cover object-center'
                                  imageType='person'
                                  photoId={person.photoId}
                                />
                                <span
                                  className={classNames(
                                    'ml-3 truncate',
                                    selected && 'font-semibold',
                                  )}
                                >
                                  {person.firstName} {person.middleName}{' '}
                                  {person.lastName}
                                </span>
                              </div>

                              {selected && (
                                <span
                                  className={classNames(
                                    'absolute inset-y-0 right-0 flex items-center pr-4',
                                    active ? 'text-white' : 'text-indigo-600',
                                  )}
                                >
                                  <CheckIcon
                                    className='h-5 w-5'
                                    aria-hidden='true'
                                  />
                                </span>
                              )}
                            </>
                          )}
                        </Combobox.Option>
                      ))}
                    </Combobox.Options>
                  )}
                </div>
              </Combobox>
            </div>
            <Button
              className='mt-3 px-3 py-2 sm:ml-3 sm:mt-0 sm:w-auto'
              disabled={isMutating}
              onClick={handleSubmit}
            >
              {isMutating ? <SpinningCircle /> : 'Добави'}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddPlayerToCardIndex
