import { getData, postData, putData } from './AxiosHelper'
import { IClubDetails, IClubSearchResult } from '../models/Club'
import { Player } from '../models/Player'
import { CardIndexT } from '../models/CardIndex'
import { OptionsT } from '../models/FormDataSchemas'
import { ISearchInput } from '../models/BaseModels'

const get = ({ page, pageSize, orderBy, desc, keyword }: ISearchInput) => {
  let url = `/api/clubs/search?page=${page}&pagesize=${pageSize}`

  if (keyword && keyword.length > 0) url += '&keyword=' + keyword

  if (orderBy) url += '&orderBy=' + orderBy

  if (desc) url += '&desc=' + desc

  return getData(url, true) as Promise<IClubSearchResult>
}

const getAll = () => getData('/api/clubs', true) as Promise<IClubDetails[]>

const getById = (id: string) =>
  getData(`/api/clubs/${id}`, true) as Promise<IClubDetails>

const addClub = (club: FormData) =>
  postData('/api/clubs', club, true) as Promise<IClubDetails>

const update = (id: string, club: FormData) =>
  putData(`/api/clubs/${id}`, club, true) as Promise<IClubDetails>

const getClubPlayers = (id: string) =>
  getData(`/api/clubs/${id}/players`, true) as Promise<Player[]>

const getClubCardIndexes = (id: string) =>
  getData(`/api/clubs/${id}/cardindexes`, true) as Promise<CardIndexT[]>

const getClubCoachOptions = (clubId: number) =>
  getData(`/api/clubs/${clubId}/coaches`, true) as Promise<OptionsT[]>

const getClubOptions = () =>
  getData('/api/clubs/options', true) as Promise<OptionsT[]>

export const clubService = {
  get,
  getAll,
  getById,
  addClub,
  update,
  getClubPlayers,
  getClubCardIndexes,
  getClubOptions,
  getClubCoachOptions,
}
