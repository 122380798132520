import { Transition } from '@headlessui/react'
import { useEffect, useState } from 'react'
import { MagnifyingGlass } from '../../assets/SvgIcons'
import { usePlayersWithExpiringContractsData } from '../../hooks/usePlayersData'
import ExpiringTransfersTable from './ExpiringTransfersTable'
import TablePlaceholder from '../shared/TablePlaceholder'
import { EnvelopeIcon } from '@heroicons/react/20/solid'

const ExpiringTransfersList = () => {
  const {
    isError,
    data: playersData,
    isLoading,
  } = usePlayersWithExpiringContractsData()
  const [globalFilter, setGlobalFilter] = useState('')
  const [filterInput, setFilterInput] = useState('')

  useEffect(() => {
    const debounce = setTimeout(() => {
      setGlobalFilter(filterInput)
    }, 750)

    return () => clearTimeout(debounce)
  }, [filterInput])

  if (isError) return <div>Error</div>
  return (
    <>
      <div className='sm:flex sm:items-center'>
        <div className='sm:flex-auto'>
          <h1 className='text-xl font-semibold text-gray-900'>
            Изтичащи договори
          </h1>
          <p className='mt-2 text-sm text-gray-700'>
            Списък на всички състезатели с изтичащи договори
          </p>
          <div className='relative mt-2 mr-24 max-w-7xl'>
            <div className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3'>
              <MagnifyingGlass />
            </div>
            <input
              value={filterInput ?? ''}
              onChange={(e) => setFilterInput(e.target.value)}
              type='search'
              id='main-search'
              className='block w-full rounded-lg border border-gray-300 bg-gray-50 p-2 pl-10 text-sm text-gray-900 focus:border-gray-500 focus:ring-gray-500 '
              placeholder='Търсене на състезтели...'
            />
          </div>
        </div>
        <div className='mt-4 sm:mt-0 sm:ml-16 sm:flex-none'>
          <button
            type='button'
            className='inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
          >
            <EnvelopeIcon className='-ml-0.5 h-5 w-5' aria-hidden='true' />
            Изпрати известия на всички
          </button>
        </div>
      </div>
      <Transition
        appear={true}
        show={true}
        enter='transition-opacity duration-75'
        enterFrom='opacity-0'
        enterTo='opacity-100'
        leave='transition-opacity duration-150'
        leaveFrom='opacity-100'
        leaveTo='opacity-0'
      >
        {!isLoading ? (
          <ExpiringTransfersTable
            data={playersData}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        ) : (
          <TablePlaceholder />
        )}
      </Transition>
    </>
  )
}

export default ExpiringTransfersList
