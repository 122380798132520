import { z } from 'zod'
import testPassword from '../utils/passwordValidator'

const thisYear = new Date().getFullYear()
const MAX_IMAGE_FILE_SIZE = 3 * 1024 * 1024 // 3MB
const ACCEPTED_IMAGE_TYPES = [
  'application/pdf',
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/webp',
]
const ACCEPTED_FILE_TYPES = [...ACCEPTED_IMAGE_TYPES, 'application/pdf']

export const PlayerCreateFormSchema = z.object({
  firstName: z.string().min(2, { message: 'Името трябва да е поне 2 символа' }),
  middleName: z
    .string()
    .min(2, { message: 'Презимето трябва да е поне 2 символа' }),
  lastName: z
    .string()
    .min(2, { message: 'Фамилията трябва да е поне 2 символа' }),
  egn: z.string().min(10, { message: 'ЕГН-то трябва да е 10 символа' }),
  nationality: z
    .string()
    .min(2, { message: 'Националността трябва да е поне 2 символа' }),
  cityName: z.string().min(2, { message: 'Градът трябва да е поне 2 символа' }),
  firstCoachId: z.number().min(1, { message: 'Невалиден треньор' }),
  firstClubId: z.number().min(1, { message: 'Невалиден клуб' }),
  files: z
    .any({ required_error: 'Моля прикачете лого' })
    .refine(
      (file) => file?.[0]?.size <= MAX_IMAGE_FILE_SIZE,
      `Максималният размер на файла е 3MB.`,
    )
    .refine(
      (file) => ACCEPTED_IMAGE_TYPES.includes(file?.[0]?.type),
      'Прикаченото лого е във формат различен от възможните: .jpg, .jpeg, .png and .webp',
    ),
})

export const PlayerUpdateFormSchema = z.object({
  firstName: z.string().min(2, { message: 'Името трябва да е поне 2 символа' }),
  middleName: z
    .string()
    .min(2, { message: 'Презимето трябва да е поне 2 символа' }),
  lastName: z
    .string()
    .min(2, { message: 'Фамилията трябва да е поне 2 символа' }),
  egn: z.string().min(10, { message: 'ЕГН-то трябва да е 10 символа' }),
  nationality: z
    .string()
    .min(2, { message: 'Националността трябва да е поне 2 символа' }),
  cityName: z.string().min(2, { message: 'Градът трябва да е поне 2 символа' }),
})

export const CreateClubFormSchema = z.object({
  title: z.string().min(1, {
    message: 'Това поле е задължително (примерни стойности: Сдружение/АД)',
  }),
  type: z.string().min(1, {
    message: 'Това поле е задължително (примерни стойности: ВК/СКВ)',
  }),
  name: z.string().min(1, { message: 'Моля въведете име на клуба' }),
  licenseNumber: z
    .string()
    .min(1, { message: 'Моля въведете номер от регистър на СК на ММС' }),
  bulstat: z.string().min(1, { message: 'Моля въведете Булстат/ЕИК' }),
  address: z.string().min(1, { message: 'Моля въведете адрес' }),
  contactNumber: z.string().min(1, { message: 'Моля въведете телефон' }),
  contactPerson: z
    .string()
    .min(3, { message: 'Моля въведете име на лицето за контакт' }),
  email: z.string().min(2, { message: 'Моля въведете E-mail адрес' }),
  president: z.string().min(2, { message: 'Моля въведете име на президент' }),
  cityName: z.string().min(2, { message: 'Моля въведете град' }),
  regionId: z.number().min(0, { message: 'Моля изберете регион' }),
  files: z
    .any({ required_error: 'Моля прикачете лого' })
    .refine(
      (file) => file?.[0]?.size <= MAX_IMAGE_FILE_SIZE,
      `Максималният размер на файла е 3MB.`,
    )
    .refine(
      (file) => ACCEPTED_IMAGE_TYPES.includes(file?.[0]?.type),
      'Прикаченото лого е във формат различен от възможните: .jpg, .jpeg, .png and .webp',
    ),
  websiteUrl: z
    .string()
    .url({ message: 'Моля въведете валиден URL адрес' })
    .or(z.literal('')),
  facebookUrl: z
    .string()
    .url({ message: 'Моля въведете валиден URL адрес' })
    .or(z.literal('')),
  instagramUrl: z
    .string()
    .url({ message: 'Моля въведете валиден URL адрес' })
    .or(z.literal('')),
  youtubeUrl: z
    .string()
    .url({ message: 'Моля въведете валиден URL адрес' })
    .or(z.literal('')),
  xUrl: z
    .string()
    .url({ message: 'Моля въведете валиден URL адрес' })
    .or(z.literal('')),
  linkedInUrl: z
    .string()
    .url({ message: 'Моля въведете валиден URL адрес' })
    .or(z.literal('')),
  tiktokUrl: z
    .string()
    .url({ message: 'Моля въведете валиден URL адрес' })
    .or(z.literal('')),
})

export const ClubUpdateFormSchema = z.object({
  title: z.string().min(1, {
    message: 'Това поле е задължително (примерни стойности: Сдружение/АД)',
  }),
  type: z.string().min(1, {
    message: 'Това поле е задължително (примерни стойности: ВК/СКВ)',
  }),
  name: z.string().min(1, { message: 'Моля въведете име на клуба' }),
  licenseNumber: z
    .string()
    .min(1, { message: 'Моля въведете номер от регистър на СК на ММС' }),
  bulstat: z.string().min(1, { message: 'Моля въведете Булстат/ЕИК' }),
  address: z.string().min(1, { message: 'Моля въведете адрес' }),
  contactNumber: z.string().min(1, { message: 'Моля въведете телефон' }),
  contactPerson: z
    .string()
    .min(5, { message: 'Моля въведете име на лицето за контакт' }),
  email: z.string().min(2, { message: 'Моля въведете E-mail адрес' }),
  president: z.string().min(2, { message: 'Моля въведете име на президент' }),
  websiteUrl: z
    .string()
    .url({ message: 'Моля въведете валиден URL адрес' })
    .or(z.literal('')),
  facebookUrl: z
    .string()
    .url({ message: 'Моля въведете валиден URL адрес' })
    .or(z.literal('')),
  instagramUrl: z
    .string()
    .url({ message: 'Моля въведете валиден URL адрес' })
    .or(z.literal('')),
  youtubeUrl: z
    .string()
    .url({ message: 'Моля въведете валиден URL адрес' })
    .or(z.literal('')),
  xUrl: z
    .string()
    .url({ message: 'Моля въведете валиден URL адрес' })
    .or(z.literal('')),
  linkedInUrl: z
    .string()
    .url({ message: 'Моля въведете валиден URL адрес' })
    .or(z.literal('')),
  tiktokUrl: z
    .string()
    .url({ message: 'Моля въведете валиден URL адрес' })
    .or(z.literal('')),
})

export const UserFormSchema = z.object({
  username: z.string().min(5, {
    message: 'Потребителското име трябва да е поне 5 символа',
  }),
  email: z.string().min(2, { message: 'E-mail адресът е задължителен' }),
  firstName: z
    .string()
    .min(2, { message: 'Името трябва да е с дължина от поне 2 символа' }),
  lastName: z
    .string()
    .min(2, { message: 'Фамилията трябва да е с дължина от поне 2 символа' }),
  phoneNumber: z.string().min(10, {
    message: 'Телефонният номер трябва да е с дължина от поне 10 цифри',
  }),
})

export const UpdatePasswordFormSchema = z
  .object({
    currentPassword: z.string().min(8, { message: 'Невалидна парола' }),
    newPassword: z
      .string()
      .min(8, { message: 'Паролата трябва да е поне 8 символа' }),
    confirmPassword: z
      .string()
      .min(8, { message: 'Паролата трябва да е поне 8 символа' }),
  })
  .refine((data) => testPassword(data.newPassword), {
    message:
      'Паролата трябва да съдържа поне една цифра, малка, главна буква, специален символ и да е с дължина от поне 8 символа',
    path: ['newPassword'],
  })
  .refine((data) => data.newPassword === data.confirmPassword, {
    message: 'Паролите не съвпадат',
    path: ['confirmPassword'],
  })

export const CreateCoachFormSchema = z.object({
  name: z.string().min(2, { message: 'Въведеното име е прекалено кратко' }),
  egn: z.string().min(10, { message: 'ЕГН-то трябва да е поне 10 символа' }),
  nationality: z.string().min(2, {
    message:
      'Невалидна стойност! Пример за валидни стойности: Българин, Българка, Италианец и др.',
  }),
  city: z.string().min(2, { message: '' }),
  files: z
    .any({ required_error: 'Моля прикачете снимка' })
    .refine(
      (file) => file?.[0]?.size <= MAX_IMAGE_FILE_SIZE,
      `Максималният размер на снимката е 3MB.`,
    )
    .refine(
      (file) => ACCEPTED_IMAGE_TYPES.includes(file?.[0]?.type),
      'Прикачената снимка е във формат различен от възможните: .jpg, .jpeg, .png and .webp',
    ),
})

export const CreatePlayerDevelopmentFormSchema = z.object({
  date: z.string().min(2, { message: 'Невалидна дата' }),
  height: z.number().min(1, { message: 'Невалидна височина' }),
  weight: z.number().min(1, { message: 'Невалидно тегло' }),
  position: z.number().min(0, { message: 'Невалидна позиция' }),
  fullExtent: z.number().min(1, { message: 'Невалиден разтег' }),
  attack: z.number().min(1, { message: 'Невалидна атака' }),
  block: z.number().min(1, { message: 'Невалиден блок' }),
})

export const CreatePlayerDocumentFormSchema = z.object({
  startDate: z.string().min(2, { message: 'Невалидна дата' }),
  endDate: z.string().min(2, { message: 'Невалидна дата' }),
  description: z.string().min(2, { message: 'Невалидно описание' }),
  type: z.number().min(0, { message: 'Невалиден тип документ' }),

  files: z
    .any({ required_error: 'Моля прикачете файл' })
    .refine(
      (file) => file?.[0]?.size <= MAX_IMAGE_FILE_SIZE,
      `Максималният размер на файла е 3MB.`,
    )
    .refine(
      (file) => ACCEPTED_IMAGE_TYPES.includes(file?.[0]?.type),
      'Прикаченият файл е във формат различен от възможните: .pdf, .jpg, .jpeg, .png and .webp',
    ),
})

export const CreatePlayerTransferDocumentFormSchema = z.object({
  startDate: z.string().min(2, { message: 'Невалидна дата' }),
  endDate: z.string().min(2, { message: 'Невалидна дата' }),
  description: z.string().min(2, { message: 'Невалидно описание' }),
  type: z.number().min(0, { message: 'Невалиден тип документ' }),

  files: z
    .any({ required_error: 'Моля прикачете файл' })
    .refine(
      (file) => file?.[0]?.size <= MAX_IMAGE_FILE_SIZE,
      `Максималният размер на файла е 3MB.`,
    )
    .refine(
      (file) => ACCEPTED_IMAGE_TYPES.includes(file?.[0]?.type),
      'Прикаченият файл е във формат различен от възможните: .pdf, .jpg, .jpeg, .png and .webp',
    ),
})

export const CoachTransferCreateFormSchema = z.object({
  clubId: z.number().min(1, { message: 'Невалиден клуб' }),
})

export const CreateCoachEducationFormSchema = z.object({
  graduationDate: z.string().min(2, { message: 'Невалидна дата' }),
  level: z
    .number()
    .min(0, { message: 'Невалидна стойност за ниво на образование' }),
  institution: z
    .string()
    .min(2, { message: 'Невалидна стойност за институция' }),
  speciality: z
    .string()
    .min(2, { message: 'Невалидна стойност за специалност' }),
  coachId: z.number().min(1, { message: 'Невалиден треньор' }),
  files: z
    .any({ required_error: 'Моля прикачете файл' })
    .refine(
      (file) => file?.[0]?.size <= MAX_IMAGE_FILE_SIZE,
      `Максималният размер на файла е 3MB.`,
    )
    .refine(
      (file) => ACCEPTED_FILE_TYPES.includes(file?.[0]?.type),
      'Прикаченият файл във формат различен от възможните: .pdf, .jpg, .jpeg, .png and .webp',
    ),
})

export const CreateCardIndexFormSchema = z.object({
  clubId: z.number().min(1, { message: 'Невалиден клуб' }),
  ageGroup: z.number().min(1, { message: 'Невалидна възрастова група' }),
  year: z.number().min(1973, { message: 'Невалиден сезон' }),
  championship: z.string().min(1, { message: 'Невалидно първенство' }),
  seniorCoachId: z
    .number()
    .nullable()
    .optional()
    .or(z.number().min(1, { message: 'Невалиден старши треньор' })),
  coachId: z
    .number()
    .nullable()
    .optional()
    .or(z.number().min(1, { message: 'Невалиден треньор' })),
  coach2Id: z
    .number()
    .nullable()
    .optional()
    .or(z.number().min(1, { message: 'Невалиден втори треньор' })),
  medic: z
    .string()
    .min(1, { message: 'Невалиден лекар' })
    .optional()
    .or(z.literal('')),
  masseur: z
    .string()
    .min(1, { message: 'Невалиден физиотерапевт' })
    .optional()
    .or(z.literal('')),
})

export type CreateCardIndexFormSchemaType = z.infer<
  typeof CreateCardIndexFormSchema
>
export type UserFormSchemaType = z.infer<typeof UserFormSchema>
export type UpdatePasswordFormSchemaType = z.infer<
  typeof UpdatePasswordFormSchema
>
export type CreateCoachFormSchemaType = z.infer<typeof CreateCoachFormSchema>
export type CreateClubFormSchemaType = z.infer<typeof CreateClubFormSchema>
export type ClubUpdateFormSchemaType = z.infer<typeof ClubUpdateFormSchema>
export type PlayerUpdateFormSchemaType = z.infer<typeof PlayerUpdateFormSchema>
export type PlayerCreateFormSchemaType = z.infer<typeof PlayerCreateFormSchema>
export type CreatePlayerDevelopmentFormSchemaType = z.infer<
  typeof CreatePlayerDevelopmentFormSchema
>
export type CreatePlayerDocumentFormSchemaType = z.infer<
  typeof CreatePlayerDocumentFormSchema
>

export type CreatePlayerTransferDocumentFormSchemaType = z.infer<
  typeof CreatePlayerTransferDocumentFormSchema
>

export type CreateCoachEducationFormSchemaType = z.infer<
  typeof CreateCoachEducationFormSchema
>
export type CoachTransferCreateFormSchemaType = z.infer<
  typeof CoachTransferCreateFormSchema
>

export type FormDataTypes =
  | CreateCardIndexFormSchemaType
  | UserFormSchemaType
  | UpdatePasswordFormSchemaType
  | CreateCoachFormSchemaType
  | CreateClubFormSchemaType
  | PlayerCreateFormSchemaType
  | CreateCoachEducationFormSchemaType
  | CoachTransferCreateFormSchemaType
  | CreatePlayerDevelopmentFormSchemaType
  | CreatePlayerDocumentFormSchemaType

export type OptionsT = {
  value: number
  label: string
}
