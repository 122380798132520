import { useParams } from 'react-router-dom'
import ClubProfileCard from './ClubProfileCard'
import ClubPlayers from './ClubPlayers'
import ClubCardIndexes from './ClubCardIndexes'
import { useClubData } from '../../hooks/useClubsData'

type ClubDetailsParams = {
  id: string
}

const ClubDetails = () => {
  const { id } = useParams() as ClubDetailsParams

  const { isError, data: clubData, isLoading } = useClubData(id)

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (isError) {
    return <div>Грешка при извличане на данни за клуб</div>
  }

  if (!clubData) {
    return <div>Клубът не е открит</div>
  }

  return (
    <>
      <ClubProfileCard club={clubData} />
      <ClubCardIndexes clubId={id} />
      <ClubPlayers clubId={id} />
    </>
  )
}

export default ClubDetails
