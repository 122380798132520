import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

const TransferExpiration = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [selectedDate, setSelectedDate] = useState<string | undefined>()

  useEffect(() => {
    const expirationDate = searchParams.get('expiration')
    expirationDate && setSelectedDate(expirationDate)
  }, [])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDate(e.target.value)
    setSearchParams((prev) => {
      prev.set('expiration', e.target.value)
      return Array.from(prev.entries())
    })
  }

  return (
    <div>
      <div className='mt-4'>
        <h2 className='mb-2 text-base font-semibold leading-6 text-gray-900'>
          Избери срок на трансфера
        </h2>

        <input
          className='block w-full rounded-md border-0 bg-white py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 disabled:cursor-not-allowed sm:text-sm sm:leading-6'
          type='date'
          value={selectedDate}
          onChange={handleChange}
        />
      </div>
    </div>
  )
}

export default TransferExpiration
