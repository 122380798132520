import { InputField, PhotoInput } from '../shared/Inputs'
import { SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  CreateCoachFormSchema,
  CreateCoachFormSchemaType,
} from '../../models/FormDataSchemas'
import useFilePreview from '../../hooks/useFilePreview'
import { useAddCoach } from '../../hooks/useCoachesData'

const AddCoachForm = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<CreateCoachFormSchemaType>({
    resolver: zodResolver(CreateCoachFormSchema),
  })

  const photo = watch('files')
  const [filePreview] = useFilePreview(photo)

  const addCoach = useAddCoach()

  const onSubmit: SubmitHandler<CreateCoachFormSchemaType> = (values) => {
    const formData = new FormData()

    const { files, ...coach } = values
    formData.append('files', files[0])

    for (const key in coach) {
      formData.append(key, coach[key as keyof typeof coach])
    }

    addCoach.mutate(formData)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='shadow sm:overflow-hidden sm:rounded-md'>
        <div className='space-y-6 bg-white px-4 py-6 sm:p-6'>
          <div>
            <h3 className='text-base font-semibold leading-6 text-gray-900'>
              Добавяне на треньор
            </h3>
            <p className='mt-1 text-sm text-gray-500'>
              Моля използвайте данни по лична карта и се уверете, че сте
              добавили снимка.
            </p>
          </div>

          <div className='grid grid-cols-7 gap-6'>
            <div className='col-span-7'>
              <PhotoInput<CreateCoachFormSchemaType>
                name='files'
                label='Снимка на треньора'
                register={register}
                error={errors.files?.message?.toString()}
                preview={filePreview}
              />
            </div>
            <div className='col-span-7 md:col-span-7 xl:col-span-3'>
              <InputField<CreateCoachFormSchemaType>
                label='Три имена'
                name='name'
                type='text'
                register={register}
                error={errors.name?.message?.toString()}
              />
            </div>
            <div className='col-span-7 md:col-span-7 xl:col-span-3'>
              <InputField<CreateCoachFormSchemaType>
                label='ЕГН/ЛНЧ'
                name='egn'
                type='text'
                register={register}
                error={errors.egn?.message?.toString()}
              />
            </div>
            <div className='col-span-7 md:col-span-7 xl:col-span-3'>
              <InputField<CreateCoachFormSchemaType>
                label='Град'
                name='city'
                type='text'
                register={register}
                error={errors.city?.message?.toString()}
              />
            </div>

            <div className='col-span-7 md:col-span-7 xl:col-span-3'>
              <InputField<CreateCoachFormSchemaType>
                label='Националност'
                name='nationality'
                type='text'
                register={register}
                error={errors.nationality?.message?.toString()}
              />
            </div>
          </div>
        </div>
        <div className='bg-gray-50 px-4 py-3 text-right sm:px-6'>
          <button
            type='submit'
            disabled={isSubmitting}
            className='inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
          >
            Добави
          </button>
        </div>
      </div>
    </form>
  )
}

export default AddCoachForm
