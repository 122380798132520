import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  Row,
  useReactTable,
} from '@tanstack/react-table'

import {
  Dispatch,
  MouseEvent,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { IUserSearch, IUserSearchInput, IUser } from '../../models/User'
import { useNavigate } from 'react-router'
import { localizations } from '../../utils/localizations'
import ServerPagination from '../shared/ServerPagination'

export default function UsersTable({
  userSearchResult,
  userSearchInput,
  setUserSearchInput,
}: {
  userSearchResult: IUserSearch
  userSearchInput: IUserSearchInput
  setUserSearchInput: Dispatch<SetStateAction<IUserSearchInput>>
}) {
  const navigate = useNavigate()
  const columnHelper = createColumnHelper<IUser>()
  const [data, setData] = useState(userSearchResult.users)

  useEffect(() => {
    setData([...userSearchResult.users])
  }, [userSearchResult.users])

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.userName, {
        id: 'userName',
        header: 'Потребителско име',
        cell: (cell) => <div>{cell.getValue()}</div>,
      }),
      columnHelper.accessor((row) => row.isDeleted, {
        id: 'isDeleted',
        header: 'Статус',
        cell: (cell) => <div>{cell.getValue() ? 'Неактивен' : 'Активен'}</div>,
      }),
      columnHelper.accessor((row) => row.email, {
        id: 'email',
        header: 'E-mail',
        cell: (cell) => <div>{cell.getValue()}</div>,
      }),
      columnHelper.accessor((row) => row.createdOn, {
        id: 'createdOn',
        header: 'Създаден на',
        cell: (cell) =>
          new Date(cell.getValue()).toLocaleDateString('bg-BG', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          }),
      }),
      columnHelper.accessor((row) => row.userRoles, {
        id: 'userRoles',
        header: 'Роли',
        cell: (cell) => (
          <div>
            {cell.getValue().map((role) => (
              <span
                key={role.roleId}
                className='mx-px inline-flex items-center rounded-md bg-indigo-100 px-2 py-1 text-xs font-medium text-indigo-700'
              >
                {localizations[role.roleName] || role.roleName}
              </span>
            ))}
          </div>
        ),
      }),
    ],
    [],
  )

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  const handleClick = (e: MouseEvent, row: Row<IUser>) => {
    e.preventDefault()
    if (e.detail === 2) {
      navigate(`/users/${row.original.id}`)
    }
  }

  return (
    <>
      <div className='mt-8 flex flex-col'>
        <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
            <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
              <table className='w-full border-collapse border-b border-gray-100 bg-white text-left text-sm text-gray-500'>
                <thead className='bg-gray-50'>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <th
                          scope='col'
                          className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                          key={header.id}
                        >
                          {header.isPlaceholder ? null : (
                            <div
                              onClick={(e) => {
                                const call =
                                  header.column.getToggleSortingHandler()
                                call && call(e)
                                setUserSearchInput({
                                  ...userSearchInput,

                                  orderBy: header.column.id,
                                  desc:
                                    {
                                      asc: false,
                                      desc: true,
                                    }[header.column.getIsSorted() as string] ??
                                    true,
                                })
                              }}
                              {...{
                                className: header.column.getCanSort()
                                  ? 'cursor-pointer select-none'
                                  : '',
                              }}
                            >
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext(),
                              )}
                              {{
                                asc: ' 🔼',
                                desc: ' 🔽',
                              }[header.column.getIsSorted() as string] ?? null}
                            </div>
                          )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody className='divide-y divide-gray-100 border-t border-gray-200 bg-white'>
                  {data.length === 0 ? (
                    <tr className='p-2 text-center'>
                      <td
                        className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'
                        colSpan={5}
                      >
                        Няма добавени потребители
                      </td>
                    </tr>
                  ) : (
                    table.getRowModel().rows.map((row) => (
                      <tr
                        key={row.id}
                        className='cursor-pointer hover:bg-gray-50'
                        onClick={(e) => handleClick(e, row)}
                      >
                        {row.getVisibleCells().map((cell) => (
                          <td
                            className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'
                            key={cell.id}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </td>
                        ))}
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
              <ServerPagination
                table={table}
                searchResult={userSearchResult}
                searchInput={userSearchInput}
                setSearchInput={setUserSearchInput}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
