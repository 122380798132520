import { IUserRole } from '../../models/User'
import { localizations } from '../../utils/localizations'

type UserStatsProps = {
  isActive: boolean
  roles: IUserRole[]
}

const UserStats = ({ isActive, roles }: UserStatsProps) => {
  const roleNames = roles.map((role) => localizations[role.roleName])
  return (
    <div>
      <dl className='mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2'>
        <div className='overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6'>
          <dt className='truncate text-xl font-semibold text-gray-500'>
            Статус
          </dt>
          <dd className='mt-1 text-sm font-medium tracking-tight text-gray-900'>
            {!isActive ? (
              <span className='inline-flex items-center rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-green-700'>
                Активен
              </span>
            ) : (
              <span className='inline-flex items-center rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600'>
                Неактивен
              </span>
            )}
          </dd>
        </div>
        <div className='overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6'>
          <dt className='truncate text-xl font-semibold text-gray-500'>
            Ниво на достъп
          </dt>
          <dd className='mt-1 text-sm font-medium tracking-tight text-gray-900'>
            {roleNames.map((role) => (
              <span
                key={role}
                className='mr-2 inline-flex items-center rounded-md bg-indigo-100 px-2 py-1 text-xs font-medium text-indigo-700'
              >
                {role}
              </span>
            ))}
          </dd>
        </div>
      </dl>
    </div>
  )
}

export default UserStats
