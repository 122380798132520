import { useSearchParams } from 'react-router-dom'
import ClubSelect from './ClubSelect'
import PlayerSelect from './PlayerSelect'
import TransferTypeSelect from './TransferTypeSelect'
import TransferExpiration from './TransferExpiration'
import { useEffect, useState } from 'react'
import Button from '../../core/ui/Button'
import { useTransferCreateQuery } from '../../../hooks/useTransfersData'
import { IPlayerTransferCreateV2 } from '../../../models/PlayerTransfer'

const TransferCreate = () => {
  const [searchParams] = useSearchParams()
  const playerTransfer = useTransferCreateQuery()

  const [transfer, setTransfer] = useState<IPlayerTransferCreateV2>({
    type: null,
    playerId: null,
    clubId: null,
    expiration: null,
  })

  useEffect(() => {
    setTransfer({
      type: searchParams.get('type'),
      playerId: searchParams.get('playerId'),
      clubId: searchParams.get('clubId'),
      expiration: searchParams.get('expiration'),
    } as IPlayerTransferCreateV2)
  }, [searchParams])

  // TODO: Needs to be refactored for some extra validations before submitting the transfer
  const isValid =
    !transfer.type || !transfer.playerId || !transfer.clubId ? true : false

  const handleCreateTransfer = () => {
    if (isValid) return
    playerTransfer.mutate(transfer)
  }

  return (
    <>
      <TransferTypeSelect />

      <PlayerSelect />

      <ClubSelect />

      {Number(transfer.type) === 1 && <TransferExpiration />}

      <Button
        disabled={isValid}
        onClick={handleCreateTransfer}
        className='mt-4'
      >
        Започни трансфер
      </Button>
    </>
  )
}

export default TransferCreate
