import { ICoachTransfer, ICoachTransferCreate } from '../models/CoachTransfer'
import {
  IPlayerTransferCreateV2,
  IPlayerTransferDocument,
} from '../models/PlayerTransfer'
import { IPlayerTransfer } from '../models/PlayerTransfer'
import { playerTransferDocumentTypes } from '../utils/staticData'
import { getData, postData } from './AxiosHelper'

const get = (): Promise<IPlayerTransfer[]> =>
  getData(`/api/transfers`, true) as Promise<IPlayerTransfer[]>

const getExpiring = (): Promise<IPlayerTransfer[]> =>
  getData(`/api/transfers/expiring`, true) as Promise<IPlayerTransfer[]>

const getById = (id: string): Promise<IPlayerTransfer> =>
  getData(`/api/transfers/${id}`, true) as Promise<IPlayerTransfer>

const getDocuments = (id: string): Promise<IPlayerTransferDocument[]> => {
  return getData(`/api/transfers/${id}/documents`, true) as Promise<
    IPlayerTransferDocument[]
  >
}

const addPlayerTransferDocument = (document: FormData) =>
  postData(
    '/api/transfers/documents',
    document,
    true,
  ) as Promise<IPlayerTransferDocument>

const getPlayerTransferDocumentTypes = () =>
  Promise.resolve(playerTransferDocumentTypes)

const create = (transfer: IPlayerTransferCreateV2): Promise<IPlayerTransfer> =>
  postData(`/api/transfers`, transfer, true) as Promise<IPlayerTransfer>

const coachTransferCreate = (
  transfer: ICoachTransferCreate,
): Promise<ICoachTransfer> =>
  postData(`/api/transfers/coach`, transfer, true) as Promise<ICoachTransfer>

const confirmTransferAsClub = (
  id: string,
  clubId: number,
): Promise<IPlayerTransfer> =>
  postData(
    `/api/transfers/${id}/confirm/club/${clubId}`,
    {},
    true,
  ) as Promise<IPlayerTransfer>

const confirmTransferAsAdmin = (id: string): Promise<IPlayerTransfer> =>
  postData(
    `/api/transfers/${id}/confirm/admin`,
    {},
    true,
  ) as Promise<IPlayerTransfer>

export const transferService = {
  get,
  getById,
  getDocuments,
  addPlayerTransferDocument,
  getPlayerTransferDocumentTypes,
  create,
  coachTransferCreate,
  confirmTransferAsClub,
  confirmTransferAsAdmin,
}
