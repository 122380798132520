import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/react/24/outline'

const modalTemplates = {
  'send-notification': {
    icon: (
      <ExclamationTriangleIcon
        className='h-6 w-6 text-red-500'
        aria-hidden='true'
      />
    ),
    title: 'Изпрати известие',
    body: 'Сигурни ли сте, че искате да изпратите e-mail известие за изтичането на този договор?',
    confirmButtonText: 'Изпрати',
    cancelButtonText: 'Отказ',
  },
  'deactivate-player': {
    icon: (
      <ExclamationTriangleIcon
        className='h-6 w-6 text-red-500'
        aria-hidden='true'
      />
    ),
    title: 'Деактивиране на състезател',
    body: 'Сигурни ли сте, че искате да деактивирате този състезател?',
    confirmButtonText: 'Деактивирай',
    cancelButtonText: 'Отказ',
  },
  'delete-club': {
    icon: (
      <ExclamationTriangleIcon
        className='h-6 w-6 text-red-500'
        aria-hidden='true'
      />
    ),
    title: 'Изтрий отбор',
    body: 'Сигурни ли сте, че искате да изтриете този отбор?',
    confirmButtonText: 'Изтрий',
    cancelButtonText: 'Отказ',
  },
  'delete-player-from-cardindex': {
    icon: (
      <ExclamationTriangleIcon
        className='h-6 w-6 text-red-500'
        aria-hidden='true'
      />
    ),
    title: 'Премахни състезател от лиценз',
    body: 'Сигурни ли сте, че искате да премахнете този състезател от лиценза?',
    confirmButtonText: 'Премахни',
    cancelButtonText: 'Отказ',
  },
}

type ConfirmationModalProps = {
  modalType: keyof typeof modalTemplates
  children: any
}

export default function ConfirmationModal({
  modalType,
  children,
}: ConfirmationModalProps) {
  const [open, setOpen] = useState(false)
  const [callback, setCallback] = useState<any>(null)

  const show = (callback: any) => (event: any) => {
    event.preventDefault()
    setOpen(true)
    event = {
      ...event,
      target: { ...event.target, value: event.target.value },
    }
    setCallback({
      run: () => callback(event),
    })
  }

  const hide = () => {
    setCallback(null)
    setOpen(false)
  }

  const confirm = () => {
    callback.run()
    hide()
  }

  return (
    <>
      {children(show)}
      <Transition.Root show={open} as={Fragment}>
        <Dialog as='div' className='relative z-10' onClose={hide}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </Transition.Child>

          <div className='fixed inset-0 z-10 overflow-y-auto'>
            <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                enterTo='opacity-100 translate-y-0 sm:scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              >
                <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6'>
                  <div className='absolute right-0 top-0 hidden pr-4 pt-4 sm:block'>
                    <button
                      type='button'
                      className='rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                      onClick={hide}
                    >
                      <span className='sr-only'>Затвори</span>
                      <XMarkIcon className='h-6 w-6' aria-hidden='true' />
                    </button>
                  </div>
                  <div className='sm:flex sm:items-start'>
                    <div className='mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10'>
                      {modalTemplates[modalType].icon}
                    </div>
                    <div className='mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left'>
                      <Dialog.Title
                        as='h3'
                        className='text-base font-semibold leading-6 text-gray-900'
                      >
                        {modalTemplates[modalType].title}
                      </Dialog.Title>
                      <div className='mt-2'>
                        <p className='text-sm text-gray-500'>
                          {modalTemplates[modalType].body}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='mt-5 sm:mt-4 sm:flex sm:flex-row-reverse'>
                    <button
                      type='button'
                      className='inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto'
                      onClick={confirm}
                    >
                      {modalTemplates[modalType].confirmButtonText}
                    </button>
                    <button
                      type='button'
                      className='mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto'
                      onClick={hide}
                    >
                      {modalTemplates[modalType].cancelButtonText}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}
