import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { regionService } from '../services/RegionService'
import { useNavigate } from 'react-router'
import { IRegionCreate } from '../models/Region'
import { notify } from '../components/shared/Notification'

export function useRegionsData() {
  return useQuery({
    queryKey: ['fetch-regions'],
    queryFn: regionService.get,
  })
}

export function useRegionData(id: string) {
  return useQuery({
    queryKey: ['fetch-region', id],
    queryFn: () => regionService.getById(id),
  })
}

export function useRegionCreate() {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['region-create'],
    mutationFn: (transfer: IRegionCreate) => regionService.create(transfer),
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: ['fetch-regions'],
      })
      notify.success('Регионът е добавен успешно!')
      navigate(`/regions/${res.id}`)
    },
    onError: (err) => {
      console.log(err)
      notify.error('Възникна грешка при добавяне на регион!')
    },
  })
}

export function useRegionOptionsData() {
  return useQuery({
    queryKey: ['fetch-region-options'],
    queryFn: regionService.getOptions,
  })
}
