import { Fragment, useContext, useEffect, useState } from 'react'
import { LinkIcon, VolleyballIcon } from '../../assets/SvgIcons'
import { IClub } from '../../models/Club'
import Image from '../shared/Image'
import { Link } from 'react-router-dom'
import { AuthenticationContext } from '../../context/AuthenticationContext'
import { useTransferConfirmationAsClubQuery } from '../../hooks/useTransfersData'

type TransferDetailsClubCardProps = {
  cardIcon?: React.ReactNode
  cardTitle?: string
  club: IClub
  transferId: string
  isConfirmed?: boolean
  confirmationDate?: string | null
  confirmedBy?: string | null
}

const TransferDetailsClubCard = ({
  cardIcon,
  cardTitle,
  club,
  transferId,
  isConfirmed,
  confirmationDate,
  confirmedBy,
}: TransferDetailsClubCardProps) => {
  const { getUserClubs } = useContext(AuthenticationContext)
  const [isClubOwner, setIsClubOwner] = useState<boolean>(false)
  const confirm = useTransferConfirmationAsClubQuery()

  useEffect(() => {
    const clubIds = getUserClubs()
    if (clubIds.includes(String(club.id))) {
      setIsClubOwner(true)
    }
  }, [])

  const clubConfirm = () => {
    confirm.mutate({ id: transferId, clubId: club.id })
  }

  return (
    <div className='px-4 sm:px-6 md:px-0'>
      <section className='relative rounded-lg bg-white shadow'>
        <header className='flex items-center justify-between border-b border-gray-200 px-4 py-5 sm:px-6'>
          <div className='inline-flex items-center'>
            {cardIcon ? cardIcon : <VolleyballIcon />}
            <h3 className='text-2xl font-medium leading-6 text-gray-900'>
              {cardTitle ? cardTitle : 'Информация за клуба'}
            </h3>
          </div>
          <div className='mt-4 sm:mt-0 sm:ml-16 sm:flex-none'>
            {!isConfirmed && isClubOwner ? (
              <button
                type='button'
                onClick={clubConfirm}
                className='shadow-xs inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-3 py-1 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto'
              >
                Потвърди
              </button>
            ) : null}
          </div>
        </header>
        <div className='px-4 py-5 sm:p-6'>
          <div className='grid grid-cols-1 gap-6 sm:grid-cols-5'>
            <div className='col-span-1 grid items-center justify-center'>
              <Image
                imageType='club'
                photoId={club.logoId}
                className='h-[100px] w-[100px] rounded-full object-cover'
              />
            </div>
            <div className='grid grid-cols-12 gap-6 sm:col-span-4'>
              <div className='col-span-12 xl:col-span-8'>
                <span className='block text-sm font-medium text-gray-500'>
                  Име на клуба
                </span>
                <span className='mt-1 block rounded-md bg-gray-50 p-2'>
                  <span className='inline-flex items-center gap-1'>
                    <Fragment>
                      {`${club.title} ${club.type} ${club.name}`}
                      <Link to={`/clubs/${club.id}`}>
                        <div className='transition-color rounded-full bg-blue-50 p-1 text-blue-500 hover:bg-blue-500 hover:text-white'>
                          <LinkIcon />
                        </div>
                      </Link>
                    </Fragment>
                  </span>
                </span>
              </div>
              <div className='col-span-12 xl:col-span-4'>
                <span className='block text-sm font-medium text-gray-500'>
                  Потвърдил
                </span>
                <span className='mt-1 block rounded-md bg-gray-50 p-2'>
                  <span className='inline-flex items-center gap-1'>
                    {isConfirmed
                      ? new Date(confirmationDate as string).toLocaleDateString(
                          'bg-BG',
                          {
                            day: '2-digit',
                            year: 'numeric',
                            month: '2-digit',
                            hour: '2-digit',
                            minute: '2-digit',
                          },
                        )
                      : 'Не'}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default TransferDetailsClubCard
