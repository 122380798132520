import { useEffect, useState } from 'react'

export default function useFilePreview(files: FileList) {
  const [imgSrc, setImgSrc] = useState<string>('')

  useEffect(() => {
    if (files && files[0]) {
      const newUrl = URL.createObjectURL(files[0])

      if (newUrl !== imgSrc) {
        setImgSrc(newUrl)
      }
    }
  }, [files])

  return [imgSrc, setImgSrc]
}
