import { useState } from 'react'
import { Combobox } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import classNames from '../../utils/classNames'
import { useClubOptionsData } from '../../hooks/useClubsData'
import { OptionsT } from '../../models/FormDataSchemas'
import { useAddUserClubData } from '../../hooks/useUsersData'
import { useParams } from 'react-router'
import { IClub } from '../../models/Club'

type AddToClubProps = {
  addedClubs: IClub[]
}

export default function AddClubToUser({ addedClubs }: AddToClubProps) {
  const [query, setQuery] = useState('')
  const [selectedClub, setSelectedClub] = useState<OptionsT>({
    label: 'Избери клуб...',
    value: 0,
  })
  const { userId } = useParams<{ userId: string }>()
  const addClubToUser = useAddUserClubData()

  const { data: clubs, isLoading, isError } = useClubOptionsData()

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (isError) {
    return <div>Error fetching user clubs..</div>
  }

  if (!clubs) {
    return <div>No clubs</div>
  }

  const filteredClubs =
    query === ''
      ? clubs.filter((club) => !addedClubs.find((c) => c.id === club.value))
      : clubs.filter((club) => {
          return (
            club.label.toLowerCase().includes(query.toLowerCase()) &&
            !addedClubs.find((c) => c.id === club.value)
          )
        })

  const handleAddClub = () => {
    if (selectedClub.value > 0 && userId)
      addClubToUser.mutate({ userId, clubId: selectedClub.value })
  }

  return (
    <>
      <div className='my-2 flex flex-wrap gap-3'>
        <div className='flex-1'>
          <Combobox as='div' value={selectedClub} onChange={setSelectedClub}>
            <div className='relative'>
              <Combobox.Input
                className='w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                onChange={(event) => setQuery(event.target.value)}
                displayValue={(club: OptionsT) => club?.label}
              />
              <Combobox.Button className='absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none'>
                <ChevronUpDownIcon
                  className='h-5 w-5 text-gray-400'
                  aria-hidden='true'
                />
              </Combobox.Button>

              {filteredClubs.length > 0 && (
                <Combobox.Options className='absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
                  {filteredClubs.map((club) => (
                    <Combobox.Option
                      key={club.value}
                      value={club}
                      className={({ active }) =>
                        classNames(
                          'relative cursor-default select-none py-2 pl-3 pr-9',
                          active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                        )
                      }
                    >
                      {({ active, selected }) => (
                        <>
                          <span
                            className={classNames(
                              'block truncate',
                              selected ? 'font-semibold' : 'font-normal',
                            )}
                          >
                            {club.label}
                          </span>

                          {selected && (
                            <span
                              className={classNames(
                                'absolute inset-y-0 right-0 flex items-center pr-4',
                                active ? 'text-white' : 'text-indigo-600',
                              )}
                            >
                              <CheckIcon
                                className='h-5 w-5'
                                aria-hidden='true'
                              />
                            </span>
                          )}
                        </>
                      )}
                    </Combobox.Option>
                  ))}
                </Combobox.Options>
              )}
            </div>
          </Combobox>
        </div>
        <div className='flex-grow-0'>
          <button
            type='button'
            className='inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:cursor-wait'
            onClick={() => handleAddClub()}
          >
            Добави
          </button>
        </div>
      </div>
    </>
  )
}
