import type {
  FieldValues,
  UseFormReturn,
  SubmitHandler,
  Path,
} from 'react-hook-form'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'

type ExtraInputProps = {
  label?: string
  error?: string
}

type InputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> &
  ExtraInputProps

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (props, ref) => (
    <>
      <label
        className='block text-sm font-medium leading-6 text-gray-900'
        htmlFor={props.name}
      >
        {props.label}
      </label>
      <input
        ref={ref}
        {...props}
        className='mt-2 block w-full rounded-md border-0 bg-white py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 disabled:cursor-not-allowed sm:text-sm sm:leading-6'
      />
      {props.error ? (
        <span className='mt-2 block text-red-800'>{props.error}</span>
      ) : null}
    </>
  ),
)

type Option = {
  label: React.ReactNode
  value: string | number | string[]
}

type SelectProps = React.DetailedHTMLProps<
  React.SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
> & { options: Option[] }

export const Select = React.forwardRef<HTMLSelectElement, SelectProps>(
  ({ options, ...props }, ref) => (
    <select ref={ref} {...props}>
      {options.map(({ label, value }) => (
        <option value={value}>{label}</option>
      ))}
    </select>
  ),
)

export type FormProps<TFormValues extends FieldValues> = {
  onSubmit: SubmitHandler<TFormValues>
  initialData?: TFormValues
  resolver?: any
  children: (methods: UseFormReturn<TFormValues>) => React.ReactNode
}

export const Form = <TFormValues extends FieldValues>({
  onSubmit,
  initialData,
  resolver,
  children,
}: FormProps<TFormValues>) => {
  const methods = useForm<TFormValues>(resolver ? { resolver } : {})

  useEffect(() => {
    if (initialData) {
      Object.keys(initialData).forEach((key) => {
        methods.setValue(key as Path<TFormValues>, initialData[key])
      })
    }
  }, [initialData])

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)}>{children(methods)}</form>
  )
}
