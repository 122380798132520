import { useParams } from 'react-router-dom'
import { useRolesData } from '../../hooks/useRolesData'
import { useRemoveUserRoleData } from '../../hooks/useUsersData'
import { IRole } from '../../models/Role'
import AddRoleToUser from './AddRoleToUser'
import { useEffect, useState } from 'react'
import { SpinningCircleSmall } from '../../assets/SvgIcons'
import { IUserRole } from '../../models/User'

type UserRoleProps = {
  roles: IUserRole[]
}

const UserRoles = ({ roles }: UserRoleProps) => {
  const { data: allRoles, isLoading, isError } = useRolesData()
  const { userId } = useParams() as { userId: string }
  const removeRole = useRemoveUserRoleData()
  const [availableRoles, setAvailableRoles] = useState<IRole[]>([])

  useEffect(() => {
    if (allRoles)
      setAvailableRoles(
        allRoles.filter(
          (role) => !roles.find((r) => r.roleId === role.id),
        ) as IRole[],
      )
  }, [allRoles])

  if (isError) {
    return <div>Error fetching available roles..</div>
  }

  const handleRemove = (roleId: string) => {
    removeRole.mutate({ userId, roleId })
  }

  return (
    <div>
      <div className='shadow'>
        <div className='space-y-6 rounded-md bg-white px-4 py-6 sm:p-6'>
          <div>
            <h3 className='text-lg font-semibold text-gray-900'>
              Управление на ролите
            </h3>
            <p className='mt-1 text-sm text-gray-500'>
              Списък на ролите на потребителя
            </p>
          </div>
          <div className='inline-flex flex-wrap gap-3'>
            {roles.length === 0 ? (
              <span className='inline-flex items-center gap-x-0.5 rounded-md bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10'>
                Няма добавени роли
              </span>
            ) : (
              roles.map((role) => (
                <span
                  key={role.roleId}
                  className='inline-flex items-center gap-x-0.5 rounded-md bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10'
                >
                  {role.roleName}

                  <button
                    type='button'
                    disabled={removeRole.isLoading || roles.length === 1}
                    className='group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-indigo-600/20 disabled:hover:bg-none'
                    onClick={() => handleRemove(role.roleId)}
                  >
                    <span className='sr-only'>Remove</span>
                    {removeRole.isLoading &&
                    removeRole.variables?.roleId === role.roleId ? (
                      <SpinningCircleSmall />
                    ) : (
                      <svg
                        viewBox='0 0 14 14'
                        className='h-3.5 w-3.5 stroke-indigo-600/50 group-hover:stroke-indigo-600/75'
                      >
                        <path d='M4 4l6 6m0-6l-6 6' />
                      </svg>
                    )}
                    <span className='absolute -inset-1' />
                  </button>
                </span>
              ))
            )}
          </div>
          {isLoading ? (
            <div role='status' className='max-w-sm animate-pulse'>
              <div className='mb-4 h-5 w-96 rounded-full bg-indigo-200'></div>
              <span className='sr-only'>Loading...</span>
            </div>
          ) : (
            availableRoles.length > 0 && (
              <AddRoleToUser availableRoles={availableRoles} />
            )
          )}
        </div>
      </div>
    </div>
  )
}

export default UserRoles
