import { GenericResponse } from '../models/BaseModels'
import { Player } from '../models/Player'
import { ageGroups } from '../utils/staticData'
import { CardIndexT, ICardIndexPlayer } from '../models/CardIndex'
import { getData, postData, putData } from './AxiosHelper'

const getAll = () => getData('/api/players', true) as Promise<CardIndexT[]>

const getById = (id: string | undefined) => {
  if (id === undefined || isNaN(+id))
    return Promise.reject('Invalid CardIndex Id')

  return getData(`/api/cardindexes/${id}`, true) as Promise<CardIndexT>
}

const addCardIndex = (cardIndex: FormData) =>
  postData('/api/cardindexes', cardIndex, true) as Promise<CardIndexT>

const cardIndexPlayerCreate = (data: ICardIndexPlayer) =>
  putData('/api/cardindexes/add-player', data, true) as Promise<GenericResponse>

const getByPlayerId = (playerId: number) =>
  getData(`/api/players/${playerId}/cardindexes`, true) as Promise<CardIndexT[]>

const getByClubId = (clubId: number) =>
  getData(`/api/clubs/${clubId}/cardindexes`, true) as Promise<CardIndexT[]>

//TODO: this should not be undefined
const getPlayers = (id: number | undefined) =>
  getData(`/api/cardindexes/${id}/players`, true) as Promise<Player[]>

const getAvailablePlayers = (id: number) =>
  getData(`/api/cardindexes/available-players/${id}`, true) as Promise<Player[]>

const getAgeGroups = () =>
  new Promise((resolve) => {
    resolve(ageGroups)
  })

export const cardIndexService = {
  getAll,
  getById,
  getByPlayerId,
  addCardIndex,
  cardIndexPlayerCreate,
  getPlayers,
  getAvailablePlayers,
  getAgeGroups,
  getByClubId,
}
