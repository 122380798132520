import { useParams } from 'react-router-dom'
import ClubUpdateForm from './Forms/ClubUpdateForm'
import { useClubData, useClubUpdate } from '../../hooks/useClubsData'
import { ClubUpdateFormSchemaType } from '../../models/FormDataSchemas'

const ClubUpdate = () => {
  const { id } = useParams<{ id: string }>() as { id: string }
  const { data: clubData, isLoading, isError } = useClubData(id)

  const clubUpdate = useClubUpdate(id as string)

  const handleSubmit = (data: ClubUpdateFormSchemaType): void => {
    const formData = new FormData()
    for (const key in data) {
      formData.append(key, String(data[key as keyof ClubUpdateFormSchemaType]))
    }

    clubUpdate.mutate(formData)
  }

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (isError) {
    return <div>Error Club player</div>
  }

  const initialData = {
    title: clubData?.title,
    type: clubData?.type,
    name: clubData?.name,
    licenseNumber: clubData?.licenseNumber,
    bulstat: clubData?.bulstat,
    address: clubData?.address,
    contactNumber: clubData?.contactNumber,
    contactPerson: clubData?.contactPerson || '',
    email: clubData?.email,
    president: clubData?.president,
    xUrl: clubData?.xUrl || '',
    websiteUrl: clubData?.websiteUrl || '',
    facebookUrl: clubData?.facebookUrl || '',
    instagramUrl: clubData?.instagramUrl || '',
    youtubeUrl: clubData?.youtubeUrl || '',
    linkedInUrl: clubData?.linkedInUrl || '',
    tiktokUrl: clubData?.tiktokUrl || '',
  } as ClubUpdateFormSchemaType

  return (
    <div>
      <div className='shadow sm:overflow-hidden sm:rounded-md'>
        <div className='space-y-6 bg-white px-4 py-6 sm:p-6'>
          <div>
            <h3 className='text-base font-semibold leading-6 text-gray-900'>
              Редактиране на клуб
            </h3>
            <p className='mt-1 text-sm text-gray-500'>
              Моля използвайте валидни и актуални данни за клуба
            </p>
          </div>
          <ClubUpdateForm initialData={initialData} onSubmit={handleSubmit} />
        </div>
      </div>
    </div>
  )
}

export default ClubUpdate
