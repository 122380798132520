import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import { useState } from 'react'

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

type PaginationProps = {
  data: any
  table: any
  displayPages: number
}

export default function Pagination({
  data,
  table,
  displayPages,
}: PaginationProps) {
  const [displayedPages, setDisplayedPages] = useState(
    table.getPageOptions().slice(0, displayPages),
  )

  const handlePageChange = (page: number) => {
    if (
      page - displayPages / 2 > 0 &&
      page + displayPages / 2 < table.getPageCount()
    ) {
      console.log(page)
      setDisplayedPages(
        table
          .getPageOptions()
          .slice(page - displayPages / 2, page + displayPages / 2),
      )
    } else if (page - 2 < 0) {
      setDisplayedPages(table.getPageOptions().slice(0, displayPages))
    } else if (page + 3 > table.getPageCount()) {
      setDisplayedPages(
        table
          .getPageOptions()
          .slice(table.getPageCount() - displayPages, table.getPageCount()),
      )
    }
    table.setPageIndex(page)
  }

  return (
    <div className='flex items-center justify-between  border-gray-200 bg-gray-50 px-4 py-3 sm:px-6'>
      <div className='flex flex-1 justify-between sm:hidden'>
        <button
          className='relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50'
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          {'Предишна'}
        </button>
        <button
          className='relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50'
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          {'Следваща'}
        </button>
      </div>
      <div className='hidden sm:flex sm:flex-1 sm:items-center sm:justify-between'>
        <div className='flex items-center gap-2'>
          <div>
            <p className='text-sm text-gray-700'>
              Показване от{' '}
              <span className='font-medium'>
                {table.getState().pagination.pageIndex *
                  table.getState().pagination.pageSize +
                  1}
              </span>{' '}
              до{' '}
              <span className='font-medium'>
                {table.getState().pagination.pageIndex *
                  table.getState().pagination.pageSize +
                  table.getRowModel().rows.length}
              </span>{' '}
              от общо <span className='font-medium'>{data.length}</span>{' '}
              резултати
            </p>
          </div>

          {/* <div>
            <span className='hidden lg:flex lg:items-center text-sm lg:gap-1'>
              Отиди на страница
              <input
                type='number'
                value={table.getState().pagination.pageIndex + 1}
                max={table.getPageOptions().length}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0
                  table.setPageIndex(page)
                }}
                className='border p-1 rounded w-16 border-gray-200'
              />
            </span>
          </div> */}
        </div>
        <nav
          className='isolate inline-flex -space-x-px rounded-md shadow-sm'
          aria-label='Pagination'
        >
          <button
            className='relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20'
            onClick={() =>
              handlePageChange(table.getState().pagination.pageIndex - 1)
            }
            disabled={!table.getCanPreviousPage()}
          >
            <span className='sr-only'>{'Предишна'}</span>
            <ChevronLeftIcon className='h-5 w-5' aria-hidden='true' />
          </button>

          {displayedPages.map((page: number) => (
            <button
              key={page}
              className={classNames(
                table.getState().pagination.pageIndex === page
                  ? 'z-10 border-blue-500 bg-indigo-50 text-blue-600'
                  : 'border-gray-300 bg-white text-gray-500 hover:bg-gray-50',
                'relative inline-flex items-center border px-4 py-2 text-sm font-medium focus:z-20',
              )}
              onClick={() => handlePageChange(page)}
            >
              {page + 1}
            </button>
          ))}

          <button
            className='relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20'
            onClick={() =>
              handlePageChange(table.getState().pagination.pageIndex + 1)
            }
            disabled={!table.getCanNextPage()}
          >
            <span className='sr-only'>Следваща</span>
            <ChevronRightIcon className='h-5 w-5' aria-hidden='true' />
          </button>
        </nav>
        <span className='hidden text-sm lg:flex lg:items-center lg:gap-1'>
          <select
            value={table.getState().pagination.pageSize}
            onChange={async (e) => {
              await table.setPageSize(Number(e.target.value))
              setDisplayedPages(table.getPageOptions().slice(0, displayPages))
            }}
            className='w-16 rounded border border-gray-200 p-1'
          >
            {[10, 20, 30, 40, 100].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
          Резултати на страница
        </span>
      </div>
    </div>
  )
}
