import { SubmitHandler, useForm } from 'react-hook-form'
import UserForm from './UserForm'
import {
  UserFormSchema,
  UserFormSchemaType,
} from '../../models/FormDataSchemas'
import { useAddUserData } from '../../hooks/useUsersData'
import { zodResolver } from '@hookform/resolvers/zod'

const CreateUser = () => {
  const addUser = useAddUserData()

  const onSubmit: SubmitHandler<UserFormSchemaType> = (users) => {
    addUser.mutate(users)
  }

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<UserFormSchemaType>({
    resolver: zodResolver(UserFormSchema),
  })

  return (
    <UserForm<UserFormSchemaType>
      register={register}
      errors={errors}
      isSubmitting={isSubmitting}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      formType='create'
      isLoading={addUser.isLoading}
    />
  )
}

export default CreateUser
