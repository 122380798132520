import { useState } from 'react'
import { Switch } from '@headlessui/react'
import classNames from '../../utils/classNames'
import { useDeleteUserData, useRestoreUserData } from '../../hooks/useUsersData'

type DeleteUserProps = {
  isDeleted: boolean
  userId: string
}

const DeleteUser = ({ isDeleted, userId }: DeleteUserProps) => {
  const [enabled, setEnabled] = useState(!isDeleted)

  const deleteUser = useDeleteUserData()
  const restoreUser = useRestoreUserData()

  const handleDelete = (checked: boolean) => {
    isDeleted ? restoreUser.mutate(userId) : deleteUser.mutate(userId)
    setEnabled(checked)
  }

  return (
    <div className='bg-white shadow sm:rounded-lg'>
      <Switch.Group as='div' className='px-4 py-5 sm:p-6'>
        <Switch.Label
          as='h3'
          className='text-base font-semibold leading-6 text-gray-900'
          passive
        >
          Деактивиране на потребител
        </Switch.Label>
        <div className='mt-2 sm:flex sm:items-start sm:justify-between'>
          <div className='max-w-xl text-sm text-gray-500'>
            <Switch.Description>
              Може да деактивирате потребител, който вече не е активен в
              системата, като по този начин ще му бъде отнет достъпът за вход.
            </Switch.Description>
          </div>
          <div className='mt-5 sm:ml-6 sm:mt-0 sm:flex sm:flex-shrink-0 sm:items-center'>
            <Switch
              checked={enabled}
              onChange={handleDelete}
              className={classNames(
                enabled ? 'bg-indigo-600' : 'bg-gray-200',
                'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2',
              )}
            >
              <span
                aria-hidden='true'
                className={classNames(
                  enabled ? 'translate-x-5' : 'translate-x-0',
                  'inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                )}
              />
            </Switch>
          </div>
        </div>
      </Switch.Group>
    </div>
  )
}

export default DeleteUser
