import { useNavigate, useParams } from 'react-router-dom'
import { useCardIndexData } from '../../hooks/useCardIndexData'
import CardIndexPlayersTable from './CardIndexPlayersTable'
import AddPlayerToCardIndex from './AddPlayerToCardIndex'
import CardIndexCard from './CardIndexCard'

const CardIndex = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const { isError, data: cardIndex, isLoading } = useCardIndexData(id)

  if (isLoading) {
    return <div>Fetching...</div>
  }

  if (isError) {
    return <div>Error fetching CardIndex ${id}</div>
  }

  if (!cardIndex) {
    return <div>CardIndex not found</div>
  }

  if (!cardIndex.players) {
    return <div>CardIndex not found</div>
  }

  return (
    <>
      <CardIndexCard cardIndex={cardIndex} />

      {cardIndex.players && <CardIndexPlayersTable data={cardIndex.players} />}

      <AddPlayerToCardIndex cardIndexId={cardIndex.id} />
    </>
  )
}

export default CardIndex
