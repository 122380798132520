import { Fragment, useEffect, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import classNames from '../../utils/classNames'
import { useAddUserRoleData } from '../../hooks/useUsersData'
import { useParams } from 'react-router'
import { IRole } from '../../models/Role'
import { SpinningCircle } from '../../assets/SvgIcons'

type AddRoleProps = {
  availableRoles: IRole[]
}

export default function AddRoleToUser({ availableRoles }: AddRoleProps) {
  const [selectedRole, setSelectedRole] = useState<IRole>(availableRoles[0])
  const { userId } = useParams() as { userId: string }
  const addRoleToUser = useAddUserRoleData()

  useEffect(() => {
    setSelectedRole(availableRoles[0])
  }, [availableRoles])

  const handleAddRole = () => {
    addRoleToUser.mutate({ userId, roleId: selectedRole.id })
  }

  return (
    <>
      <div className='my-2 flex flex-wrap gap-3'>
        <div className='flex-1'>
          <Listbox value={selectedRole} onChange={setSelectedRole}>
            {({ open }) => (
              <>
                <div className='relative'>
                  <Listbox.Button className='relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6'>
                    <span className='block truncate'>{selectedRole?.name}</span>
                    <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
                      <ChevronUpDownIcon
                        className='h-5 w-5 text-gray-400'
                        aria-hidden='true'
                      />
                    </span>
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave='transition ease-in duration-100'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                  >
                    <Listbox.Options className='absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
                      {availableRoles.map((role) => (
                        <Listbox.Option
                          key={role.id}
                          className={({ active }) =>
                            classNames(
                              active
                                ? 'bg-indigo-600 text-white'
                                : 'text-gray-900',
                              'relative cursor-default select-none py-2 pl-3 pr-9',
                            )
                          }
                          value={role}
                        >
                          {({ selected, active }) => (
                            <>
                              <span
                                className={classNames(
                                  selected ? 'font-semibold' : 'font-normal',
                                  'block truncate',
                                )}
                              >
                                {role.name}
                              </span>

                              {selected ? (
                                <span
                                  className={classNames(
                                    active ? 'text-white' : 'text-indigo-600',
                                    'absolute inset-y-0 right-0 flex items-center pr-4',
                                  )}
                                >
                                  <CheckIcon
                                    className='h-5 w-5'
                                    aria-hidden='true'
                                  />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
        </div>
        <div className='flex-grow-0'>
          <button
            type='button'
            className='inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:cursor-wait disabled:bg-gray-300'
            onClick={() => handleAddRole()}
            disabled={addRoleToUser.isLoading}
          >
            {addRoleToUser.isLoading ? (
              <>
                <SpinningCircle />
              </>
            ) : (
              <>Добави</>
            )}
          </button>
        </div>
      </div>
    </>
  )
}
