import { IUserRoleInput } from '../models/Role'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { userService } from '../services/UserService'
import {
  IUserSearch,
  IUserSearchInput,
  IUserUpdate,
  IUser,
} from '../models/User'
import { UserFormSchemaType } from '../models/FormDataSchemas'
import { useNavigate } from 'react-router'
import { IClub } from '../models/Club'
import { notify } from '../components/shared/Notification'

type UserClubData = {
  clubId: number
  userId: string
}

export function useUsersData(userSearchInput: IUserSearchInput) {
  const { keyword, roleid, page, pageSize, orderBy, desc } = userSearchInput
  return useQuery({
    queryKey: ['fetch-users', keyword, roleid, page, pageSize, orderBy, desc],
    queryFn: () =>
      userService.get(
        page,
        pageSize,
        orderBy,
        desc,
        keyword,
        roleid,
      ) as Promise<IUserSearch>,
    onError(err) {
      notify.error(
        'Възникна грешка при зареждане на потребителите! Моля, опитайте отново по-късно.',
      )
      console.error(err)
    },
  })
}

export function useUserData(id: IUser['id']) {
  return useQuery({
    queryKey: ['fetch-user', id],
    queryFn: () => userService.getById(id) as Promise<IUser>,
  })
}

export function useAddUserData() {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (user: UserFormSchemaType) => userService.post(user),
    onSuccess: (res, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['fetch-users'] })
      notify.success('Потребителят е добавен успешно')
      navigate('/users')
    },
    onError: (err) => {
      console.log(err)
      notify.error('Възникна грешка при добавянето на потребител')
    },
  })
}

export function useUpdateUserData() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (user: IUserUpdate) => userService.post(user),
    onSuccess: (res, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['fetch-users'] })
      notify.success('Потребителят е редактиран успешно')
    },
    onError: (err) => {
      console.log(err)
      notify.error('Възникна грешка при редактирането на потребител')
    },
  })
}

export function useResetUserPasswordData() {
  return useMutation({
    mutationFn: (userId: IUser['id']) => userService.resetPassword(userId),
    onSuccess: (res, variables, context) => {
      notify.success('Паролата беше генерирана и изпратена на email успешно')
    },
    onError: (err) => {
      console.log(err)
      notify.error('Възникна грешка при генерирането на нова парола')
    },
  })
}

export function useUserClubsData(id: IUser['id']) {
  return useQuery({
    queryKey: ['fetch-user-clubs'],
    queryFn: () => userService.getUserClubs(id) as Promise<IClub[]>,
  })
}

export function useAddUserClubData() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (payload: UserClubData) =>
      userService.addClubToUser(payload.userId, payload.clubId),
    onSuccess: () => {
      queryClient.invalidateQueries(['fetch-user-clubs'])
      notify.success('Клубът е добавен успешно')
    },
    onError: (err: unknown) => {
      console.log(err)
      notify.error('Възникна грешка при добавянето на клуб')
    },
  })
}

export function useRemoveUserClubData() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (payload: UserClubData) =>
      userService.removeClubFromUser(payload.userId, payload.clubId),
    onSuccess: () => {
      queryClient.invalidateQueries(['fetch-user-clubs'])
      notify.success('Клубът е премахнат успешно')
    },
    onError: (err: unknown) => {
      console.log(err)
      notify.error('Възникна грешка при премахването на клуба')
    },
  })
}

export function useAddUserRoleData() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (payload: IUserRoleInput) =>
      userService.addRoleToUser(payload.userId, payload.roleId),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(['fetch-user', variables.userId])
      queryClient.invalidateQueries(['fetch-roles'])
      notify.success('Ролята е добавена успешно')
    },
    onError: (err: unknown) => {
      console.log(err)
      notify.error('Възникна грешка при добавянето на роля')
    },
  })
}

export function useRemoveUserRoleData() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (payload: IUserRoleInput) =>
      userService.deleteRoleFromUser(payload.userId, payload.roleId),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(['fetch-user', variables.userId])
      queryClient.invalidateQueries(['fetch-roles'])
      notify.success('Ролята е премахната успешно')
    },
    onError: (err: unknown) => {
      console.log(err)
      notify.error('Възникна грешка при премахването на роля')
    },
  })
}

export function useDeleteUserData() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (userId: IUser['id']) => userService.deleteUser(userId),
    onSuccess: (data, userId) => {
      queryClient.invalidateQueries(['fetch-user', userId])
      notify.success('Потребителят е деактивиран успешно')
    },
    onError: (err: unknown) => {
      console.log(err)
      notify.error('Възникна грешка при деактивирането на потребител')
    },
  })
}

export function useRestoreUserData() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (userId: IUser['id']) => userService.restoreUser(userId),
    onSuccess: (data, userId) => {
      queryClient.invalidateQueries(['fetch-user', userId])
      notify.success('Потребителят е реактивиран успешно')
    },
    onError: (err: unknown) => {
      console.log(err)
      notify.error('Възникна грешка при реактивирането на потребител')
    },
  })
}
