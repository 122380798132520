import axios from 'axios'

// set default baseURL
axios.defaults.baseURL = process.env.REACT_APP_WEBAPI_URL

// create a response interceptor to check for 401s
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      const { status } = error.response
      if (status === 401) {
        // if 401 is returned, logout the user
        localStorage.removeItem('user_info')
        console.log(error)
      }
    }
    return Promise.reject(error)
  },
)

export const getBlob = (url: string, needsAuth: boolean | undefined) => {
  const requestOptions =
    needsAuth === true || needsAuth === undefined
      ? { headers: authHeader() }
      : {}
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        ...requestOptions,
        responseType: 'blob',
      })
      .then((res) => {
        if (res === undefined) reject('error')

        const data = new Blob([res.data], {
          type: res.headers['content-type'],
        })

        const url = URL.createObjectURL(data)
        resolve(url)
      })
      .catch((res) => {
        reject(formatReject(res))
      })
  })
}

//#region generic crud methods
export const getData = (url: string, needsAuth: boolean) => {
  const requestOptions =
    needsAuth === true || needsAuth === undefined
      ? { headers: authHeader() }
      : {}

  return new Promise((resolve, reject) => {
    axios
      .get(url, requestOptions)
      .then((res) => {
        resolve(res.data)
      })
      .catch((res) => {
        reject(formatReject(res))
      })
  })
}

export const upsertData = (
  id: number | string | null | undefined,
  url: string,
  data: unknown,
  needsAuth: boolean,
) => {
  if (id === undefined || id === null || id === 0)
    return postData(url, data, needsAuth)
  else return putData(`${url}/${id}`, data, needsAuth)
}

export const postData = (url: string, data: unknown, needsAuth: boolean) => {
  const requestOptions =
    needsAuth === true || needsAuth === undefined
      ? { headers: authHeader() }
      : {}

  return new Promise((resolve, reject) => {
    axios
      .post(url, data, requestOptions)
      .then((res) => {
        resolve(res.data)
      })
      .catch((res) => {
        reject(formatReject(res))
      })
  })
}

export const putData = (url: string, data: unknown, needsAuth: boolean) => {
  const requestOptions =
    needsAuth === true || needsAuth === undefined
      ? { headers: authHeader() }
      : {}

  return new Promise((resolve, reject) => {
    axios
      .put(url, data, requestOptions)
      .then((res) => {
        resolve(res.data)
      })
      .catch((res) => {
        reject(formatReject(res))
      })
  })
}

export const deleteData = (url: string) => {
  const requestOptions = { headers: authHeader() } // deletes always need security

  return new Promise((resolve, reject) => {
    axios
      .delete(url, requestOptions)
      .then((res) => {
        resolve(res.data)
      })
      .catch((res) => {
        reject(formatReject(res))
      })
  })
}

export const deleteMultipleData = (url: string, ids: number[]) => {
  const requestOptions = {
    headers: authHeader(), // deletes always need security
    data: { ids },
  }

  return new Promise((resolve, reject) => {
    axios
      .delete(url, requestOptions)
      .then((res) => {
        resolve(res.data)
      })
      .catch((res) => {
        reject(formatReject(res))
      })
  })
}

function formatReject(res: any) {
  if (res === null) return 'Unknown Error'

  // normal operation
  if (res.response) {
    if (Array.isArray(res.response.data)) {
      const m = res.response.data.map((o: any, idx: any) => {
        return o.description
      })
      return m.join('\n')
    }

    return res.response.data
  } else if (res.request) {
    return 'Network Error'
  } else return 'Unknown Error'
}
//#endregion
export function authHeader() {
  // return authorization header with jwt token
  const info = localStorage.getItem('user_info')
  const user = info !== null ? JSON.parse(info) : null

  if (user && user.token) {
    return {
      Authorization: 'Bearer ' + user.token,
    }
  } else {
    window.location.href = '/login'
    return {}
  }
}
