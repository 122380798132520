import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  Row,
} from '@tanstack/react-table'

import { useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Pagination from '../Players/Pagination'
import fuzzyFilter from '../../utils/fuzzyFilter'
import { IClubDetails } from '../../models/Club'
import { LinkIcon } from '../../assets/SvgIcons'
import TableImage from '../shared/TableImage'

type ClubsTableProps = {
  data: IClubDetails[]
  globalFilter: string
  setGlobalFilter: React.Dispatch<React.SetStateAction<string>>
}

export default function ClubsTable({
  data,
  globalFilter,
  setGlobalFilter,
}: ClubsTableProps) {
  const columnVisibility = { id: false, logoId: false }
  const columnHelper = createColumnHelper<IClubDetails>()
  const [sorting, setSorting] = useState<SortingState>([])
  const navigate = useNavigate()

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.id, {
        id: 'id',
        header: () => <span>id</span>,
      }),
      columnHelper.accessor((row) => row.logoId, {
        id: 'logoId',
        header: () => <span>id</span>,
      }),
      columnHelper.accessor((row) => row.fullName, {
        id: 'fullName',
        header: 'Име',
        cell: (cell) => (
          <div className='flex items-center gap-3'>
            <div className='relative h-10 w-10'>
              <TableImage
                className='h-full w-full rounded-full object-cover object-center text-gray-300'
                imageType='club'
                photoId={cell.row.getValue<string>('logoId')}
              />
            </div>

            <div className='text-sm'>
              <div className='peer flex cursor-pointer gap-2 text-gray-700 hover:text-blue-500'>
                <Link to={`/clubs/${cell.row.getValue('id')}`}>
                  <div className='font-medium transition-colors'>
                    {cell.renderValue()}
                  </div>
                </Link>
                <Link to={`/clubs/${cell.row.getValue('id')}`}>
                  <div className='rounded-full bg-blue-50 p-1 text-blue-500 transition-colors hover:bg-blue-500 hover:text-white'>
                    <LinkIcon />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        ),
      }),
      columnHelper.accessor((row) => row.licenseNumber, {
        id: 'licenseNumber',
        header: 'Лиценз',
        cell: (cell) => <div>{cell.getValue()}</div>,
      }),
      columnHelper.accessor((row) => row.email, {
        id: 'email',
        header: 'E-mail',
        cell: (cell) => <div>{cell.getValue()}</div>,
      }),
      columnHelper.accessor((row) => row.president, {
        id: 'president',
        header: 'Президент',
        cell: (cell) => <div>{cell.getValue()}</div>,
      }),
      columnHelper.accessor((row) => row.contactNumber, {
        id: 'contactNumber',
        header: 'Телефон',
        cell: (cell) => <div>{cell.getValue()}</div>,
      }),
    ],
    [],
  )

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      sorting,
      columnVisibility,
      globalFilter,
    },
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  })

  const handleClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    row: Row<IClubDetails>,
  ) => {
    e.preventDefault()
    if (e.detail === 2) {
      navigate(`/clubs/${row.original.id}`)
    }
  }

  return (
    <>
      <div className='mt-8 flex flex-col'>
        <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
            <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
              <table className='w-full border-collapse border-b border-gray-100 bg-white text-left text-sm text-gray-500'>
                <thead className='bg-gray-50'>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <th
                          scope='col'
                          className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                          key={header.id}
                        >
                          {header.isPlaceholder ? null : (
                            <div
                              {...{
                                className: header.column.getCanSort()
                                  ? 'cursor-pointer select-none'
                                  : '',
                                onClick:
                                  header.column.getToggleSortingHandler(),
                              }}
                            >
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext(),
                              )}
                              {{
                                asc: ' 🔼',
                                desc: ' 🔽',
                              }[header.column.getIsSorted() as string] ?? null}
                            </div>
                          )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody className='divide-y divide-gray-100 border-t border-gray-200 bg-white'>
                  {data.length === 0 ? (
                    <tr className='p-2 text-center'>
                      <td
                        className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'
                        colSpan={5}
                      >
                        Няма добавени клубове
                      </td>
                    </tr>
                  ) : (
                    table.getRowModel().rows.map((row) => (
                      <tr
                        key={row.id}
                        className='cursor-pointer hover:bg-gray-50'
                        onClick={(e) => handleClick(e, row)}
                      >
                        {row.getVisibleCells().map((cell) => (
                          <td
                            className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'
                            key={cell.id}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </td>
                        ))}
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
              {data.length !== 0 ? (
                <Pagination table={table} data={data} displayPages={6} />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
