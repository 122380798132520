import { IRole, IRoleClaim } from '../models/Role'
import { getData, upsertData, deleteData, putData } from './AxiosHelper'

// define public methods
export const roleService = {
  get,
  getById,
  post,
  deleteRole,
  getClaims,
  updateClaims,
}

function get(): Promise<IRole[]> {
  return getData(`/api/role`, true) as Promise<IRole[]>
}

function getById(roleId: string): Promise<IRole> {
  return getData(`/api/role/${roleId}`, true) as Promise<IRole>
}

function post(data: IRole) {
  return upsertData(data.id, `/api/role`, data, true) as Promise<unknown>
}

function deleteRole(roleId: string) {
  return deleteData(`/api/role/${roleId}`) as Promise<unknown>
}

function getClaims() {
  return getData(`/api/role/claims`, true) as Promise<IRoleClaim[]>
}

function updateClaims(roleId: string, data: unknown) {
  return putData(`/api/role/${roleId}/claims`, data, true) as Promise<unknown>
}
