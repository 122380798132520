import { SelectField } from '../shared/Inputs'
import { SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  CoachTransferCreateFormSchema,
  CoachTransferCreateFormSchemaType,
} from '../../models/FormDataSchemas'
import { useParams } from 'react-router'
import { useCoachTransferCreateQuery } from '../../hooks/useTransfersData'
import { ICoachTransferCreate } from '../../models/CoachTransfer'

type CoachTransferCreateFormProps = {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
}

const CoachTransferCreateForm = ({
  setShowModal,
}: CoachTransferCreateFormProps) => {
  const { id } = useParams() as { id: string }

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<CoachTransferCreateFormSchemaType>({
    resolver: zodResolver(CoachTransferCreateFormSchema),
  })

  const coachTransferCreate = useCoachTransferCreateQuery()

  const onSubmit: SubmitHandler<CoachTransferCreateFormSchemaType> = (
    values,
  ) => {
    const coachId = parseInt(id)

    const postData: ICoachTransferCreate = {
      coachId: coachId,
      clubId: values.clubId,
    }

    coachTransferCreate.mutate(postData)
    setShowModal(false)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='col-span-8 md:col-span-7 xl:col-span-4'>
        <SelectField<CoachTransferCreateFormSchemaType>
          label='Клуб на трансфера'
          name='clubId'
          isOptional={false}
          register={register}
          optionsFor='clubs'
        />
      </div>

      <div className='mt-4 text-right'>
        <button
          type='submit'
          disabled={isSubmitting}
          className='inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
        >
          Добави
        </button>
      </div>
    </form>
  )
}

export default CoachTransferCreateForm
