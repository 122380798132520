import { FC, PropsWithChildren } from 'react'
import classNames from '../../../utils/classNames'

type ButtonProps = {
  type?: 'submit' | 'button' | 'reset'
  className?: string
  onClick?: () => void
  disabled?: boolean
}

const Button: FC<PropsWithChildren<ButtonProps>> = ({
  type = 'button',
  className,
  onClick,
  disabled,
  children,
}) => {
  return (
    <button
      type={type}
      disabled={disabled}
      className={classNames(
        className ? className : '',
        'inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed sm:w-auto',
      )}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

export default Button
