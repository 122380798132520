import { PlayerDetailsT } from '../../models/Player'
import PlayerDeleteCard from './PlayerDeleteCard'

type PlayerSettingsProps = {
  player: PlayerDetailsT
}

const PlayerSettings = ({ player }: PlayerSettingsProps) => {
  return (
    <PlayerDeleteCard
      playerId={String(player.id)}
      isDeleted={player.isDeleted}
    />
  )
}

export default PlayerSettings
