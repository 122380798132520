import { InputField, SelectField } from '../shared/Inputs'
import { SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  CreateCardIndexFormSchema,
  CreateCardIndexFormSchemaType,
} from '../../models/FormDataSchemas'
import { ageGroups } from '../../utils/staticData'
import { useAddCardIndex } from '../../hooks/useCardIndexData'
import getSeason from '../../utils/getSeason'

const AddCardIndexForm = () => {
  const addCardIndex = useAddCardIndex()
  const year = getSeason()

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<CreateCardIndexFormSchemaType>({
    resolver: zodResolver(CreateCardIndexFormSchema),
    defaultValues: {
      year: year,
      clubId: 0,
      ageGroup: 1,
      coach2Id: null,
      seniorCoachId: null,
      coachId: null,
      championship: 'Държавно първенство',
    },
  })

  const onSubmit: SubmitHandler<CreateCardIndexFormSchemaType> = (values) => {
    const { ageGroup, ...cardIndex } = values

    const formData = new FormData()

    console.log(cardIndex)
    for (const key in cardIndex) {
      const value = cardIndex[key as keyof typeof cardIndex]
      if (value !== undefined && value !== null) {
        formData.append(key, value.toString())
      }
    }

    formData.append('age', ageGroups[ageGroup].age.toString())
    formData.append('sex', ageGroups[ageGroup].sex.toString())

    console.log(formData)

    addCardIndex.mutate(formData)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='shadow sm:overflow-hidden sm:rounded-md'>
        <div className='space-y-6 bg-white px-4 py-6 sm:p-6'>
          <div>
            <h3 className='text-base font-semibold leading-6 text-gray-900'>
              Добавяне на лиценз
            </h3>
            <p className='mt-1 text-sm text-gray-500'>
              След създаване на лиценза, ще можете да добавите състезателите към
              него.
            </p>
          </div>

          <div className='grid grid-cols-7 gap-6 xl:grid-cols-3'>
            <div className='col-span-7 md:col-span-7 xl:col-span-3'>
              <SelectField<CreateCardIndexFormSchemaType>
                label='Клуб'
                name='clubId'
                isOptional={true}
                register={register}
                optionsFor='clubs'
              />
            </div>

            <div className='col-span-7 md:col-span-7 xl:col-span-3'>
              <SelectField<CreateCardIndexFormSchemaType>
                label='Възрастова група'
                name='ageGroup'
                isOptional={false}
                register={register}
                optionsFor='age-groups'
              />
            </div>

            <div className='col-span-7 md:col-span-7 xl:col-span-3'>
              <label
                htmlFor='year'
                className='block text-sm font-medium leading-6 text-gray-900'
              >
                Сезон
              </label>
              <select
                id='year'
                disabled={true}
                {...register('year', { valueAsNumber: true })}
                className='mt-2 block w-full rounded-md border-0 bg-white py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 disabled:cursor-not-allowed sm:text-sm sm:leading-6'
              >
                <option value={year}>{`${year} / ${year + 1}`}</option>
              </select>
            </div>

            <div className='col-span-7 md:col-span-7 xl:col-span-3'>
              <InputField<CreateCardIndexFormSchemaType>
                label='Първенство'
                name='championship'
                type='text'
                register={register}
                error={errors.championship?.message?.toString()}
              />
            </div>

            <div className='col-span-7 md:col-span-7 xl:col-span-3'>
              <SelectField<CreateCardIndexFormSchemaType>
                label='Старши треньор'
                name='seniorCoachId'
                isOptional={true}
                register={register}
                optionsFor='coaches'
              />
            </div>

            <div className='col-span-7 md:col-span-7 xl:col-span-3'>
              <SelectField<CreateCardIndexFormSchemaType>
                label='Треньор'
                name='coachId'
                register={register}
                isOptional={true}
                optionsFor='coaches'
              />
            </div>

            <div className='col-span-7 md:col-span-7 xl:col-span-3'>
              <SelectField<CreateCardIndexFormSchemaType>
                label='Втори треньор'
                name='coach2Id'
                register={register}
                isOptional={true}
                optionsFor='coaches'
              />
            </div>

            <div className='col-span-7 md:col-span-7 xl:col-span-3'>
              <InputField<CreateCardIndexFormSchemaType>
                label='Лекар'
                name='medic'
                type='text'
                register={register}
                error={errors.medic?.message?.toString()}
              />
            </div>

            <div className='col-span-7 md:col-span-7 xl:col-span-3'>
              <InputField<CreateCardIndexFormSchemaType>
                label='Физиотерапевт'
                name='masseur'
                type='text'
                register={register}
                error={errors.masseur?.message?.toString()}
              />
            </div>
          </div>
        </div>
        <div className='bg-gray-50 px-4 py-3 text-right sm:px-6'>
          <button
            type='submit'
            disabled={isSubmitting}
            className='inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
          >
            Добави
          </button>
        </div>
      </div>
    </form>
  )
}

export default AddCardIndexForm
