import { Tab } from '@headlessui/react'
import { Fragment, useState } from 'react'
import { useParams } from 'react-router-dom'
import { usePlayerData } from '../../hooks/usePlayersData'
import PlayerCardIndexes from './PlayerCardIndexes'
import PlayerProfile from './PlayerProfile'
import PlayerSettings from './PlayerSettings'
import PlayerTransfers from './PlayerTransfers'
import classNames from '../../utils/classNames'

const tabs = [
  { id: 0, name: 'Общи данни', href: '#', current: true },
  { id: 1, name: 'Картотеки', href: '#', current: false },
  { id: 2, name: 'Трансфери', href: '#', current: false },
  { id: 3, name: 'Настройки', href: '#', current: false },
]

const PlayerDetails = () => {
  const { id } = useParams()
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)

  const { isError, data: playerData, isLoading } = usePlayerData(id)

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (isError) {
    return <div>Error fetching player</div>
  }

  if (!playerData) {
    return <div>Player not found</div>
  }

  return (
    <main className='flex-1'>
      <div className='relative mx-auto md:px-8 xl:px-0'>
        <div className='pb-16'>
          <div className='px-4 sm:px-6 md:px-0'>
            <h1 className='text-3xl font-bold tracking-tight text-gray-900'>
              Профил на състезател
            </h1>
          </div>

          <div className='px-4 sm:px-6 md:px-0'>
            <div className='py-6'>
              {/* Tabs */}
              <div className='lg:hidden'>
                <label htmlFor='selected-tab' className='sr-only'>
                  Select a tab
                </label>
                <select
                  id='selected-tab'
                  name='selected-tab'
                  className='mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm'
                  defaultValue={tabs.find((tab) => tab.current)?.name}
                  onChange={(e) => {
                    const selectedTabName = e.target.value
                    setSelectedTabIndex(
                      tabs.findIndex((tab) => tab.name === selectedTabName),
                    )
                  }}
                >
                  {tabs.map((tab) => (
                    <option key={tab.name}>{tab.name}</option>
                  ))}
                </select>
              </div>

              <Tab.Group
                selectedIndex={selectedTabIndex}
                onChange={setSelectedTabIndex}
              >
                <div className='hidden lg:block'>
                  <div className='border-b border-gray-200'>
                    <Tab.List className='-mb-px flex space-x-8'>
                      {tabs.map((tab) => (
                        <Tab as={Fragment} key={tab.name}>
                          {({ selected }) => (
                            <button
                              className={classNames(
                                selected
                                  ? 'border-blue-500 text-blue-600'
                                  : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                'whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium',
                              )}
                            >
                              {tab.name}
                            </button>
                          )}
                        </Tab>
                      ))}
                    </Tab.List>
                  </div>
                </div>

                <Tab.Panels className='mt-10'>
                  <Tab.Panel>
                    <PlayerProfile player={playerData} />
                  </Tab.Panel>
                  <Tab.Panel>
                    <PlayerCardIndexes id={playerData.id} />
                  </Tab.Panel>
                  <Tab.Panel>
                    <PlayerTransfers id={playerData.id} />
                  </Tab.Panel>
                  <Tab.Panel>
                    <PlayerSettings player={playerData} />
                  </Tab.Panel>
                </Tab.Panels>
              </Tab.Group>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default PlayerDetails
