import { memo } from 'react'
import { FieldValues, Path, UseFormRegister } from 'react-hook-form'
import { FormDataTypes } from '../../models/FormDataSchemas'
import {
  useClubOptionsData,
  useCoachOptionsData,
} from '../../hooks/useClubsData'
import { UseQueryResult } from '@tanstack/react-query'
import { PhotoIcon } from '@heroicons/react/20/solid'
import { useCardIndexAgeGroupsData } from '../../hooks/useCardIndexData'
import { useCoachEducationLevelsData } from '../../hooks/useCoachesData'
import {
  usePlayerDocumentTypesData,
  usePlayerPositionsData,
} from '../../hooks/usePlayersData'
import { useRegionOptionsData } from '../../hooks/useRegionsData'
import { usePlayerTransferDocumentTypesData } from '../../hooks/useTransfersData'

interface InputFieldProps<T extends FieldValues> {
  label: string
  name: Path<T>
  type: 'text' | 'number' | 'password' | 'email'
  register: UseFormRegister<T>
  error?: string
  defVal?: string | number
  disabled?: boolean
  placeholder?: string
}

const InputElement = <T extends FormDataTypes>({
  label,
  name,
  type,
  register,
  error,
  defVal,
  disabled = false,
  placeholder,
}: InputFieldProps<T>) => {
  const content = placeholder || ''

  return (
    <div>
      <label
        className='block text-sm font-medium leading-6 text-gray-900'
        htmlFor={name}
      >
        {label}
      </label>
      <div>
        <input
          type={type}
          id={name}
          disabled={disabled}
          {...register(name, type === 'number' ? { valueAsNumber: true } : {})}
          autoComplete='off'
          defaultValue={defVal}
          placeholder={content}
          className='mt-2 block w-full rounded-md border-0 bg-white py-1.5 text-gray-900 placeholder-gray-400 shadow-sm ring-1 ring-inset ring-gray-300 focus:placeholder-transparent focus:ring-2 focus:ring-inset focus:ring-indigo-600 disabled:cursor-not-allowed sm:text-sm sm:leading-6'
        />
      </div>
      {<span className='mt-2 block text-red-800'>{error}</span>}
    </div>
  )
}

interface PhotoInputProps<T extends FieldValues> {
  name: Path<T>
  label: string
  register: UseFormRegister<T>
  error?: string
  preview?: any
}

const PhotoInput = <T extends FormDataTypes>({
  name,
  label,
  register,
  preview,
  error,
}: PhotoInputProps<T>) => {
  return (
    <div className='col-span-full'>
      <label
        htmlFor={name}
        className='block text-sm font-medium leading-6 text-gray-900'
      >
        {label}
      </label>
      <div className='mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10'>
        <div className='text-center'>
          {preview ? (
            <object data={preview} className='h-48 w-auto text-gray-300' />
          ) : (
            <PhotoIcon
              className='mx-auto h-12 w-12 text-gray-300'
              aria-hidden='true'
            />
          )}
          <div className='mt-4 text-sm leading-6 text-gray-600'>
            <label
              htmlFor={name}
              className='relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500'
            >
              <span>Прикачи файл</span>
              <input
                type='file'
                accept='image/*, application/pdf'
                className='sr-only'
                id={name}
                {...register(name)}
              />
            </label>
          </div>
          <p className='text-xs leading-5 text-gray-600'>
            Позволени формати PDF, PNG, JPG, JPEG до 3MB
          </p>
          <p className='text-xs leading-5 text-red-600'>{error}</p>
        </div>
      </div>
    </div>
  )
}

interface SelectFieldProps<T extends FieldValues> {
  label: string
  name: Path<T>
  isOptional?: boolean
  register: UseFormRegister<T>
  optionsFor: OptionsProps['type']
}

const SelectElement = <T extends FormDataTypes>({
  label,
  name,
  optionsFor,
  isOptional,
  register,
}: SelectFieldProps<T>) => {
  return (
    <div>
      <label
        htmlFor={name}
        className='block text-sm font-medium leading-6 text-gray-900'
      >
        {label}
      </label>
      <select
        id={name}
        {...register(name, { valueAsNumber: true })}
        className='mt-2 block w-full rounded-md border-0 bg-white py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
      >
        <Options type={optionsFor} isOptional={isOptional} />
      </select>
    </div>
  )
}

interface OptionListProps {
  dataHook: () => UseQueryResult<
    Array<{ value: number; label: string }>,
    unknown
  >
  isOptional?: boolean
}

const OptionsList = memo(function OptionList({
  dataHook,
  isOptional,
}: OptionListProps) {
  const { isLoading, data, isError } = dataHook()

  if (isLoading) return <option value={0}>Loading...</option>
  if (isError) return <option value={0}>Error fetching options...</option>

  return (
    <>
      {isOptional && <option value={0}></option>}(
      {data.map((current, idx) => (
        <option key={idx} value={current.value}>
          {current.label}
        </option>
      ))}
      )
    </>
  )
})

interface OptionsProps {
  type:
    | 'clubs'
    | 'coaches'
    | 'regions'
    | 'age-groups'
    | 'education-levels'
    | 'player-positions'
    | 'document-types'
    | 'transfer-document-types'
  isOptional?: boolean
}

const Options = ({ type, isOptional }: OptionsProps) => {
  switch (type) {
    case 'clubs':
      return (
        <OptionsList dataHook={useClubOptionsData} isOptional={isOptional} />
      )
    case 'coaches':
      return (
        <OptionsList
          //TODO: Refactor this to use the correct dataHook
          // eslint-disable-next-line react-hooks/rules-of-hooks
          dataHook={() => useCoachOptionsData(1)}
          isOptional={isOptional}
        />
      )
    case 'regions':
      return (
        <OptionsList dataHook={useRegionOptionsData} isOptional={isOptional} />
      )
    case 'age-groups':
      return (
        <OptionsList
          dataHook={useCardIndexAgeGroupsData}
          isOptional={isOptional}
        />
      )
    case 'education-levels':
      return (
        <OptionsList
          dataHook={useCoachEducationLevelsData}
          isOptional={isOptional}
        />
      )

    case 'player-positions':
      return (
        <OptionsList
          dataHook={usePlayerPositionsData}
          isOptional={isOptional}
        />
      )

    case 'document-types':
      return (
        <OptionsList
          dataHook={usePlayerDocumentTypesData}
          isOptional={isOptional}
        />
      )

    case 'transfer-document-types':
      return (
        <OptionsList
          dataHook={usePlayerTransferDocumentTypesData}
          isOptional={isOptional}
        />
      )
    default:
      return <></>
  }
}

const InputField = memo(InputElement) as typeof InputElement
const SelectField = memo(SelectElement) as typeof SelectElement

export { InputField, SelectField, PhotoInput }
