import { PlayerDocumentsSvg } from '../../assets/SvgIcons'
import { useCoachEducations } from '../../hooks/useCoachesData'
import { educationLevels } from '../../utils/staticData'
import FileDownload from '../shared/FileDownload'

type CoachEducationsProps = {
  coachId: string
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
}

const CoachEducations = ({ coachId, setShowModal }: CoachEducationsProps) => {
  const {
    data: coachEducations,
    isError,
    isLoading,
  } = useCoachEducations(coachId)

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (isError) {
    return <div>Error...</div>
  }

  return (
    <div className='px-4 pb-16 sm:px-6 md:px-0'>
      <section className='relative rounded-lg bg-white shadow'>
        {/* Card Header */}
        <header className='flex items-center justify-between border-b border-gray-200 px-4 py-5 sm:px-6'>
          <div className='inline-flex items-center'>
            <PlayerDocumentsSvg />
            <h3 className='text-2xl font-medium leading-6 text-gray-900'>
              Образование
            </h3>
          </div>
          <div>
            <button
              type='button'
              onClick={() => setShowModal(true)}
              className='inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto'
            >
              Добави
            </button>
          </div>
        </header>

        {/* Card Body */}
        <div className='px-4 sm:px-6'>
          <div className='flex flex-col'>
            <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-6'>
              <div className='inline-block min-w-full py-2 align-middle'>
                <div className='overflow-hidden rounded-lg'>
                  <table className='min-w-full divide-y divide-gray-200'>
                    <thead className='bg-gray-50'>
                      <tr className='divide-x divide-gray-200'>
                        <th
                          scope='col'
                          className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8'
                        >
                          Дата на завършване
                        </th>
                        <th
                          scope='col'
                          className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                        >
                          Ниво
                        </th>
                        <th
                          scope='col'
                          className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                        >
                          Учебно заведение
                        </th>
                        <th
                          scope='col'
                          className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                        >
                          Специалност
                        </th>
                        <th
                          scope='col'
                          className='relative py-3.5 pl-3 pr-4 sm:pr-6 lg:pr-8'
                        >
                          <span className='sr-only'>Файл</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className='divide-y divide-gray-200 bg-white'>
                      {coachEducations.length > 0 ? (
                        coachEducations.map((document) => {
                          const startDate = new Date(document.graduationDate)

                          return (
                            <tr
                              key={document.id}
                              className='divide-x divide-gray-200'
                            >
                              <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-500 sm:pl-6 lg:pl-8'>
                                {startDate.toLocaleDateString('bg-BG', {
                                  year: 'numeric',
                                  month: '2-digit',
                                  day: '2-digit',
                                })}
                              </td>

                              <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                {
                                  educationLevels.find(
                                    (el) => el.value === document.level,
                                  )?.label
                                }
                              </td>
                              <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                {document.institution}
                              </td>
                              <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                {document.speciality}
                              </td>
                              <td className='relative whitespace-nowrap py-4 pl-3 text-center text-sm font-medium sm:pr-6 lg:pr-8'>
                                <FileDownload fileId={document.fileId} />
                              </td>
                            </tr>
                          )
                        })
                      ) : (
                        <tr className='divide-x divide-gray-200'>
                          <td
                            colSpan={5}
                            className='whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500'
                          >
                            <span className='text-center'>
                              Няма добавени файлове
                            </span>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default CoachEducations
