import { useNavigate } from 'react-router'
import {
  FacebookIcon,
  InstagramIcon,
  LinkIcon,
  LinkedinIcon,
  TiktokIcon,
  TwitterIcon,
  VolleyballIcon,
  WebsiteIcon,
  YoutubeIcon,
} from '../../assets/SvgIcons'
import { IClubDetails } from '../../models/Club'
import Image from '../shared/Image'

type ClubProfileCardProps = {
  club: IClubDetails
  readOnly?: boolean
}

const ClubProfileCard = ({ club, readOnly }: ClubProfileCardProps) => {
  const navigate = useNavigate()

  return (
    <div className='px-4 sm:px-6 md:px-0'>
      <section className='relative rounded-lg bg-white shadow'>
        {readOnly ? null : (
          <header className='flex items-center justify-between border-b border-gray-200 px-4 py-5 sm:px-6'>
            <div className='inline-flex items-center'>
              <VolleyballIcon />
              <h3 className='text-2xl font-medium leading-6 text-gray-900'>
                Данни за волейболния клуб
              </h3>
            </div>
            <div className='mt-4 sm:mt-0 sm:ml-16 sm:flex-none'>
              <button
                type='button'
                onClick={() => navigate(`edit`)}
                className='inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto'
              >
                Редактирай
              </button>
            </div>
          </header>
        )}
        <div className='px-4 py-5 sm:p-6'>
          <div className='grid grid-cols-1 gap-6 xl:grid-cols-5'>
            <div className='col-span-1 flex flex-col items-center justify-center gap-2'>
              <Image
                imageType='club'
                photoId={club.logoId}
                className='h-[200px] w-[200px] rounded-full object-cover'
              />
              <div className='flex flex-wrap justify-center gap-2'>
                {club.websiteUrl && (
                  <a
                    href={club.websiteUrl}
                    target='_blank'
                    rel='noreferrer'
                    className='inline-flex items-center space-x-2 rounded bg-blue-400 p-2 font-semibold text-white'
                  >
                    <WebsiteIcon />
                  </a>
                )}
                {club.facebookUrl && (
                  <a
                    href={club.facebookUrl}
                    target='_blank'
                    rel='noreferrer'
                    className='inline-flex items-center space-x-2 rounded bg-blue-500 p-2 font-semibold text-white'
                  >
                    <FacebookIcon />
                  </a>
                )}
                {club.xUrl && (
                  <a
                    href={club.xUrl}
                    target='_blank'
                    rel='noreferrer'
                    className='inline-flex items-center space-x-2 rounded bg-blue-400 p-2 font-semibold text-white'
                  >
                    <TwitterIcon />
                  </a>
                )}
                {club.instagramUrl && (
                  <a
                    href={club.instagramUrl}
                    target='_blank'
                    rel='noreferrer'
                    className='inline-flex items-center space-x-2 rounded bg-gray-700 p-2 font-semibold text-white'
                  >
                    <InstagramIcon />
                  </a>
                )}
                {club.youtubeUrl && (
                  <a
                    href={club.youtubeUrl}
                    target='_blank'
                    rel='noreferrer'
                    className='inline-flex items-center space-x-2 rounded bg-red-600 p-2 font-semibold text-white'
                  >
                    <YoutubeIcon />
                  </a>
                )}
                {club.linkedInUrl && (
                  <a
                    href={club.linkedInUrl}
                    target='_blank'
                    rel='noreferrer'
                    className='inline-flex items-center space-x-2 rounded bg-blue-600 p-2 font-semibold text-white'
                  >
                    <LinkedinIcon />
                  </a>
                )}
                {club.tiktokUrl && (
                  <a
                    href={club.tiktokUrl}
                    target='_blank'
                    rel='noreferrer'
                    className='inline-flex items-center space-x-2 rounded bg-gray-700 p-2 font-semibold text-white'
                  >
                    <TiktokIcon />
                  </a>
                )}
              </div>
            </div>
            <div className='grid grid-cols-12 gap-6 lg:col-span-4'>
              <div className='col-span-12 sm:col-span-4 xl:col-span-2'>
                <span className='block text-sm font-medium text-gray-500'>
                  Правна форма
                </span>
                <span className='mt-1 block rounded-md bg-gray-50 p-2'>
                  {club.title}
                </span>
              </div>

              <div className='col-span-12 sm:col-span-8 xl:col-span-5'>
                <span className='block text-sm font-medium text-gray-500'>
                  Наименование
                </span>
                <span className='mt-1 block rounded-md bg-gray-50 p-2'>
                  {club.type} {club.name}
                </span>
              </div>

              <div className='col-span-12 sm:col-span-6 xl:col-span-3'>
                <span className='block text-sm font-medium text-gray-500'>
                  № на лиценз в ММС
                </span>
                <span className='mt-1 block rounded-md bg-gray-50 p-2'>
                  {club.licenseNumber}
                </span>
              </div>

              <div className='col-span-12 sm:col-span-6 xl:col-span-2'>
                <span className='block text-sm font-medium text-gray-500'>
                  Булстат/ЕИК
                </span>
                <span className='mt-1 block rounded-md bg-gray-50 p-2'>
                  <div className='flex items-center gap-2'>
                    <div className='font-normal transition-colors'>
                      {club.bulstat}
                    </div>
                    <a
                      href={`https://portal.registryagency.bg/CR/en/Reports/ActiveConditionTabResult?uic=${club.bulstat}`}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <div className='transition-color rounded-full bg-blue-50 p-1 text-blue-500 hover:bg-blue-500 hover:text-white'>
                        <LinkIcon />
                      </div>
                    </a>
                  </div>
                </span>
              </div>

              <div className='col-span-12 sm:col-span-6 xl:col-span-2'>
                <span className='block text-sm font-medium text-gray-500'>
                  Град/Село
                </span>
                <span className='mt-1 block rounded-md bg-gray-50 p-2'>
                  {club.city?.name || '-'}
                </span>
              </div>

              <div className='col-span-12 sm:col-span-6 xl:col-span-2'>
                <span className='block text-sm font-medium text-gray-500'>
                  Регион
                </span>
                <span className='mt-1 block rounded-md bg-gray-50 p-2'>
                  {club.region?.name || '-'}
                </span>
              </div>

              <div className='col-span-12 xl:col-span-5'>
                <span className='block text-sm font-medium text-gray-500'>
                  Адрес
                </span>
                <span className='mt-1 block rounded-md bg-gray-50 p-2'>
                  {club.address || '-'}
                </span>
              </div>

              <div className='col-span-12 sm:col-span-6 xl:col-span-3'>
                <span className='block text-sm font-medium text-gray-500'>
                  E-mail
                </span>
                <span className='mt-1 block rounded-md bg-gray-50 p-2'>
                  {club.email}
                </span>
              </div>

              <div className='col-span-12 sm:col-span-6 xl:col-span-4'>
                <span className='block text-sm font-medium text-gray-500'>
                  Президент/Председател
                </span>
                <span className='mt-1 block rounded-md bg-gray-50 p-2'>
                  {club.president || '-'}
                </span>
              </div>

              <div className='col-span-12 sm:col-span-6 xl:col-span-4'>
                <span className='block text-sm font-medium text-gray-500'>
                  Име на лице за контакт
                </span>
                <span className='mt-1 block rounded-md bg-gray-50 p-2'>
                  {club.contactPerson || '-'}
                </span>
              </div>

              <div className='col-span-12 sm:col-span-6 xl:col-span-4'>
                <span className='block text-sm font-medium text-gray-500'>
                  Телефон за контакт
                </span>
                <span className='mt-1 block rounded-md bg-gray-50 p-2'>
                  {club.contactNumber || '-'}
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default ClubProfileCard
