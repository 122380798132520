import { Fragment, useEffect, useState } from 'react'
import { Combobox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { ISearchInput } from '../../../models/BaseModels'
import classNames from '../../../utils/classNames'
import { useClubsSearchData } from '../../../hooks/useClubsData'
import { IClubDetails } from '../../../models/Club'
import ClubProfileCard from '../../Clubs/ClubProfileCard'

type ClubSearchProps = {
  selected: IClubDetails | null
  setSelected: (player: IClubDetails) => void
}

export default function ClubSearch({ selected, setSelected }: ClubSearchProps) {
  const [query, setQuery] = useState('')

  const [clubSearchInput, setClubSearchInput] = useState<ISearchInput>({
    keyword: '',
    page: 1,
    pageSize: 10,
    orderBy: 'id',
    desc: false,
  })

  const {
    isLoading,
    data: clubsData,
    isError,
  } = useClubsSearchData(clubSearchInput)

  useEffect(() => {
    const debounce = setTimeout(() => {
      setClubSearchInput((prev) => ({ ...prev, keyword: query }))
    }, 500)

    return () => clearTimeout(debounce)
  }, [query])

  return selected ? (
    <ClubProfileCard club={selected} readOnly={true} />
  ) : (
    <Combobox value={selected} onChange={setSelected}>
      <div className='relative mt-1'>
        <div className='relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-indigo-300 sm:text-sm'>
          <Combobox.Button className='flex w-full items-center'>
            <Combobox.Input
              className='w-full border-none py-5 px-6 text-sm leading-5 text-gray-900 focus:ring-0'
              placeholder='Търсене на клуб'
              displayValue={(club: IClubDetails) => club?.fullName || ''}
              onChange={(event) => setQuery(event.target.value)}
            />
            <span className='absolute inset-y-0 right-0 flex items-center pr-4'>
              <ChevronUpDownIcon
                className='h-5 w-5 text-gray-400'
                aria-hidden='true'
              />
            </span>
          </Combobox.Button>
        </div>
        <Transition
          as={Fragment}
          leave='transition ease-in duration-100'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <Combobox.Options className='absolute mt-1 max-h-96 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
            {clubsData?.clubs.length === 0 && query !== '' ? (
              <div className='relative cursor-default select-none py-2 px-4 text-gray-700'>
                Нищо не беше намерено!
              </div>
            ) : isLoading ? (
              <div className='relative cursor-default select-none py-2 px-4 text-gray-700'>
                Търсене...
              </div>
            ) : isError ? (
              <div className='relative cursor-default select-none py-2 px-4 text-gray-700'>
                Възникна грешка при търсенето! Моля свържете се с администратор.
              </div>
            ) : (
              clubsData?.clubs.map((club) => (
                <Combobox.Option
                  key={club.id}
                  className={({ active }) =>
                    classNames(
                      active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                      'relative cursor-pointer select-none py-2 pl-3 pr-9',
                    )
                  }
                  value={club}
                >
                  {({ selected, active }) => (
                    <>
                      <div className='flex items-center'>
                        {/* <PlayerImage
                          className='h-10 w-10 flex-shrink-0 rounded-full object-cover object-center'
                          photoId={club.logo}
                        /> */}
                        <span
                          className={classNames(
                            'ml-4 truncate',
                            selected && 'font-semibold',
                          )}
                        >
                          {club.fullName}
                        </span>

                        <span
                          className={classNames(
                            active ? 'text-indigo-200' : 'text-gray-500',
                            'ml-2 truncate',
                          )}
                        >
                          Номер на клуба: {club.id}
                        </span>
                      </div>

                      {selected && (
                        <span
                          className={classNames(
                            'absolute inset-y-0 right-0 flex items-center pr-4',
                            active ? 'text-white' : 'text-indigo-600',
                          )}
                        >
                          <CheckIcon className='h-5 w-5' aria-hidden='true' />
                        </span>
                      )}
                    </>
                  )}
                </Combobox.Option>
              ))
            )}
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  )
}
