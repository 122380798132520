const getSeason = (): number => {
  const today = new Date()
  const month = today.getMonth()
  const year = today.getFullYear()

  if (month >= 7) {
    return year
  }

  return year - 1
}

export default getSeason
