import { IUser } from '../models/User'
import { postData } from './AxiosHelper'

// define public methods
export const AuthenticationService = {
  login,
  resetPassword,
}

function login(username: string, password: string) {
  return postData(
    `/api/authentication/login`,
    { username, password },
    false,
  ) as Promise<IUser>
}

// function confirmEmail(o) {
//   return postData(`/api/authentication/confirmemail`, o, false)
// }

// function confirmPassword(o) {
//   return postData(`/api/authentication/confirmpassword`, o, false)
// }

function resetPassword(email: string) {
  return postData(
    `/api/authentication/resetpassword?email=${email}`,
    null,
    false,
  )
}
