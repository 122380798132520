import { IPlayerTransferStatus } from '../../models/PlayerTransfer'

type TransferStatusProps = {
  data: IPlayerTransferStatus
}
const TransferStatus = ({ data }: TransferStatusProps) => {
  const endDate = new Date(data.endDate)
  const startDate = new Date(data.startDate)
  const dateNow = new Date()

  if (!data.isConfirmed)
    return (
      <span className='inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-700 ring-1 ring-inset ring-yellow-600/20'>
        Изчаква потвърждение
      </span>
    )

  if (data.type === 1 || data.type === 2) {
    if (dateNow > endDate) {
      return (
        <span className='inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20'>
          Активен
        </span>
      )
    } else {
      return (
        <span className='inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/20'>
          Изтекъл
        </span>
      )
    }
  }

  if (data.type === 0) {
    return (
      <span className='inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20'>
        Преотстъпен
      </span>
    )
  }

  return <div>-</div>
}

export default TransferStatus
