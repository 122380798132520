import { useParams } from 'react-router'
import { useCardIndexData } from '../../hooks/useCardIndexData'
import { useRef } from 'react'
import { useReactToPrint } from 'react-to-print'
import CardIndexPrintContent from './CardIndexPrintContent'
import Button from '../core/ui/Button'

const CardIndexPrint = () => {
  const componentRef = useRef<HTMLDivElement>(null)
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })
  const { id } = useParams<{ id: string }>()
  const { isError, data: cardIndex, isLoading } = useCardIndexData(id)

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (isError) {
    return <div>Error</div>
  }

  return (
    <div>
      <Button onClick={handlePrint}>Печат</Button>
      <div className='hidden'>
        <CardIndexPrintContent cardIndex={cardIndex} ref={componentRef} />
      </div>
    </div>
  )
}

export default CardIndexPrint
