import { useEffect } from 'react'
import { useUpdateUserData } from '../../hooks/useUsersData'
import UserForm from './UserForm'
import { SubmitHandler, useForm } from 'react-hook-form'
import {
  UserFormSchema,
  UserFormSchemaType,
} from '../../models/FormDataSchemas'
import { zodResolver } from '@hookform/resolvers/zod'
import { IUser } from '../../models/User'

type UpdateUserProps = {
  user: IUser
}

const UpdateUser = ({ user }: UpdateUserProps) => {
  const updateUser = useUpdateUserData()

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<UserFormSchemaType>({
    resolver: zodResolver(UserFormSchema),
  })

  useEffect(() => {
    setValue('username', user?.userName || '')
    setValue('email', user?.email || '')
    setValue('firstName', user?.firstName || '')
    setValue('lastName', user?.lastName || '')
    setValue('phoneNumber', user?.phoneNumber || '')
  }, [user])

  const onSubmit: SubmitHandler<UserFormSchemaType> = (values) => {
    const updatedUser = { ...values, id: user.id }
    updateUser.mutate(updatedUser)
  }

  return (
    <UserForm<UserFormSchemaType>
      register={register}
      onSubmit={onSubmit}
      handleSubmit={handleSubmit}
      errors={errors}
      isSubmitting={isSubmitting}
      isLoading={updateUser.isLoading}
      formType='update'
    />
  )
}

export default UpdateUser
