import { createContext, useEffect, useState } from 'react'
import { IUser } from '../models/User'
import { AuthenticationService } from '../services/AuthenticationService'
import { useNavigate } from 'react-router'

type AuthenticationContextType = {
  user: IUser | null
  loading: boolean
  loginError: string

  login: (username: string, password: string, redirect?: string) => void
  logout: () => void
  getUser: () => IUser | null
  getUserClubs: () => string[]
  isAdmin: () => boolean
}

export const AuthenticationContext = createContext<AuthenticationContextType>(
  {} as AuthenticationContextType,
)

type AuthProviderProps = {
  children: React.ReactNode
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const navigate = useNavigate()
  const [user, setUser] = useState<IUser | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [loginError, setLoginError] = useState<string>('')

  useEffect(() => {
    const userFromLocalStorage = localStorage.getItem('user')
    setUser(userFromLocalStorage ? JSON.parse(userFromLocalStorage) : null)
  }, [])

  const getUser = (): IUser | null => {
    if (user !== null) return user

    const info = localStorage.getItem('user_info')

    if (info !== null) return JSON.parse(info)

    return null
  }

  const getUserClubs = (): string[] => {
    const user = getUser()
    if (user === null) return []

    const clubs = user.claims.filter((claim) => claim.claimType === 'clubId')
    return clubs.map((c) => c.claimValue)
  }

  const isAdmin = (): boolean => {
    const user = getUser()
    if (user === null) return false

    const admin = user.userRoles.find(
      (role) => role.roleNormalizedName === 'ADMINISTRATOR',
    )

    if (admin !== undefined) return true

    return false
  }

  const login = (username: string, password: string, redirect?: string) => {
    setLoginError('')
    setLoading(true)

    AuthenticationService.login(username, password)
      .then((response) => {
        const user: IUser = response
        localStorage.setItem('user_info', JSON.stringify(user))
        setUser(user)
        if (redirect !== undefined && redirect !== '') {
          navigate(redirect, { replace: true })
        }
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        setLoginError(error)
        console.log(error)
      })
  }

  const logout = () => {
    localStorage.removeItem('user_info')
    setUser(null)
    navigate('/login')
  }

  return (
    <AuthenticationContext.Provider
      value={{
        user,
        loading,
        loginError,
        login,
        logout,
        getUser,
        getUserClubs,
        isAdmin,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  )
}
