import { InputField, PhotoInput, SelectField } from '../shared/Inputs'
import { SubmitHandler, Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  CreateCoachEducationFormSchema,
  CreateCoachEducationFormSchemaType,
} from '../../models/FormDataSchemas'
import useFilePreview from '../../hooks/useFilePreview'
import { useAddCoachEducation } from '../../hooks/useCoachesData'
import DatePicker, {
  DateRangeType,
  DateValueType,
} from 'react-tailwindcss-datepicker'
import { useState } from 'react'
import { useParams } from 'react-router'

type AddCoachEducationFormProps = {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
}

const AddCoachEducationForm = ({
  setShowModal,
}: AddCoachEducationFormProps) => {
  const { id: coachId } = useParams() as { id: string }

  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<CreateCoachEducationFormSchemaType>({
    resolver: zodResolver(CreateCoachEducationFormSchema),
    defaultValues: {
      coachId: parseInt(coachId),
    },
  })

  const photo = watch('files')
  const [filePreview] = useFilePreview(photo)

  const [dateValue, setDateValue] = useState<DateRangeType>({
    startDate: null,
    endDate: null,
  })

  const handleDateChange = (newValue: DateValueType) => {
    if (newValue)
      setDateValue({ startDate: newValue.startDate, endDate: newValue.endDate })
  }

  const AddCoachEducation = useAddCoachEducation()

  const onSubmit: SubmitHandler<CreateCoachEducationFormSchemaType> = (
    values,
  ) => {
    const formData = new FormData()

    const { files, ...coachEducation } = values
    formData.append('files', files[0])

    for (const key in coachEducation) {
      formData.append(
        key,
        coachEducation[key as keyof typeof coachEducation].toString(),
      )
    }

    AddCoachEducation.mutate(formData)
    setShowModal(false)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='grid grid-cols-8 gap-6'>
        <div className='col-span-8'>
          <PhotoInput<CreateCoachEducationFormSchemaType>
            name='files'
            label='Сканирано копие на документ за завършено образование'
            register={register}
            error={errors.files?.message?.toString()}
            preview={filePreview}
          />
        </div>
        <div className='col-span-8 md:col-span-7 xl:col-span-4'>
          <SelectField<CreateCoachEducationFormSchemaType>
            label='Образователна степен'
            name='level'
            isOptional={false}
            register={register}
            optionsFor='education-levels'
          />
        </div>
        <div className='col-span-8 md:col-span-8 xl:col-span-4'>
          <label
            htmlFor='graduationDate'
            className='block text-sm font-medium leading-6 text-gray-900'
          >
            Дата на завършване
          </label>

          <Controller
            control={control}
            name='graduationDate'
            render={({ field }) => (
              <DatePicker
                primaryColor={'indigo'}
                useRange={false}
                asSingle={true}
                popoverDirection='up'
                placeholder='Избери дата'
                displayFormat={'DD/MM/YYYY'}
                maxDate={new Date()}
                containerClassName='relative w-full text-gray-700 mt-2'
                inputClassName='relative w-full tracking-wide rounded-md border-0 bg-white py-1.5 pl-4 pr-14 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 disabled:cursor-not-allowed sm:text-sm sm:leading-6'
                value={dateValue}
                onChange={(newValue) => {
                  handleDateChange(newValue)
                  return field.onChange(
                    new Date(newValue?.startDate || '').toJSON(),
                  )
                }}
              />
            )}
          />
        </div>
        <div className='col-span-8 md:col-span-8 xl:col-span-4'>
          <InputField<CreateCoachEducationFormSchemaType>
            label='Учебно заведение'
            name='institution'
            type='text'
            register={register}
            error={errors.institution?.message?.toString()}
          />
        </div>

        <div className='col-span-8 md:col-span-8 xl:col-span-4'>
          <InputField<CreateCoachEducationFormSchemaType>
            label='Специалност'
            name='speciality'
            type='text'
            register={register}
            error={errors.speciality?.message?.toString()}
          />
        </div>
      </div>

      <div className='mt-4 text-right'>
        <button
          type='submit'
          disabled={isSubmitting}
          className='inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
        >
          Добави
        </button>
      </div>
    </form>
  )
}

export default AddCoachEducationForm
