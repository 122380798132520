import classNames from '../utils/classNames'

type IconProps = {
  className?: string
}

export const WebsiteIcon = ({ className }: IconProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      strokeWidth={1.3}
      stroke='currentColor'
      className='h-5 w-5'
    >
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M12 21a9.004 9.004 0 0 0 8.716-6.747M12 21a9.004 9.004 0 0 1-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 0 1 7.843 4.582M12 3a8.997 8.997 0 0 0-7.843 4.582m15.686 0A11.953 11.953 0 0 1 12 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0 1 21 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0 1 12 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 0 1 3 12c0-1.605.42-3.113 1.157-4.418'
      />
    </svg>
  )
}

export const FacebookIcon = ({ className }: IconProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className='inline-block h-5 w-5 fill-current stroke-none text-white'
      viewBox='0 0 24 24'
    >
      <path d='M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z' />
    </svg>
  )
}

export const TiktokIcon = ({ className }: IconProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 448 512'
      className='inline-block h-5 w-5 fill-current stroke-none text-white'
    >
      <path
        fill='currentColor'
        d='M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z'
      />
    </svg>
  )
}

export const YoutubeIcon = ({ className }: IconProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className='inline-block h-5 w-5 fill-current stroke-none text-white'
      viewBox='0 0 24 24'
    >
      <path d='M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z' />
    </svg>
  )
}

export const InstagramIcon = ({ className }: IconProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className='inline-block h-5 w-5 fill-current stroke-none text-white'
      viewBox='0 0 24 24'
    >
      <path d='M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z' />
    </svg>
  )
}

export const LinkedinIcon = ({ className }: IconProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className='inline-block h-5 w-5 fill-current stroke-none text-white'
      viewBox='0 0 24 24'
    >
      <path d='M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z' />
    </svg>
  )
}

export const TwitterIcon = ({ className }: IconProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className='inline-block h-5 w-5 fill-current stroke-none text-white'
      viewBox='0 0 24 24'
    >
      <path d='M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z' />
    </svg>
  )
}

const SpinningCircle = () => {
  return (
    <svg
      className='h-5 w-5 animate-spin text-white'
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
    >
      <circle
        className='opacity-25'
        cx='12'
        cy='12'
        r='10'
        stroke='currentColor'
        strokeWidth='4'
      ></circle>
      <path
        className='opacity-75'
        fill='currentColor'
        d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
      ></path>
    </svg>
  )
}

const SpinningCircleSmall = () => {
  return (
    <svg
      className=' h-3.5 w-3.5 animate-spin stroke-indigo-600/50 group-hover:stroke-indigo-600/75'
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
    >
      <circle
        className='opacity-25'
        cx='12'
        cy='12'
        r='10'
        stroke='currentColor'
        strokeWidth='4'
      ></circle>
      <path
        className='opacity-75'
        fill='currentColor'
        d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
      ></path>
    </svg>
  )
}

const TrashCan = ({ className }: IconProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      stroke='currentColor'
      className={classNames('h-4 w-4', className)}
      x-tooltip='tooltip'
    >
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0'
      />
    </svg>
  )
}

const Pencil = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      stroke='currentColor'
      className='h-6 w-6'
      x-tooltip='tooltip'
    >
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125'
      />
    </svg>
  )
}
const LinkIcon = ({ className }: IconProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 20 20'
      fill='currentColor'
      aria-hidden='true'
      className={classNames('h-2.5 w-2.5', className)}
    >
      <path d='M12.232 4.232a2.5 2.5 0 013.536 3.536l-1.225 1.224a.75.75 0 001.061 1.06l1.224-1.224a4 4 0 00-5.656-5.656l-3 3a4 4 0 00.225 5.865.75.75 0 00.977-1.138 2.5 2.5 0 01-.142-3.667l3-3z'></path>
      <path d='M11.603 7.963a.75.75 0 00-.977 1.138 2.5 2.5 0 01.142 3.667l-3 3a2.5 2.5 0 01-3.536-3.536l1.225-1.224a.75.75 0 00-1.061-1.06l-1.224 1.224a4 4 0 105.656 5.656l3-3a4 4 0 00-.225-5.865z'></path>
    </svg>
  )
}

const MagnifyingGlass = () => {
  return (
    <svg
      aria-hidden='true'
      className='h-5 w-5 text-gray-500 dark:text-gray-400'
      fill='none'
      stroke='currentColor'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z'
      ></path>
    </svg>
  )
}

const PlayerJersey = () => {
  return (
    <svg
      className='svg-icon mr-4 h-8 w-8 fill-current text-blue-500'
      width='1.2em'
      height='1.2em'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 30 26.6'
    >
      <path d='M29.8 7 23 .2c-.2-.1-.3-.2-.5-.2h-15c-.1 0-.3.1-.5.2L.2 7c-.3.3-.3.7 0 1l4.1 4.1c.3.3.7.3 1 0l1.6-1.6v15.4c0 .4.3.7.7.7h15c.4 0 .7-.3.7-.7V10.5l1.6 1.6c.3.3.7.3 1 0L29.8 8c.1-.1.2-.3.2-.5s-.1-.3-.2-.5zM18.3 1.4C18 2.9 16.6 4 15 4s-3-1.1-3.3-2.7l6.6.1zM4.8 10.6 1.7 7.5l.9-.9 3.1 3.1-.9.9zM23 8.3c-.2-.2-.5-.3-.8-.2-.3.1-.4.4-.4.6v16.4H8.2V8.8c0-.3-.2-.5-.4-.6-.3-.1-.6-.1-.8.1l-.8.9-3.1-3.1 4.7-4.7H11c.3 1.9 2 3.4 4 3.4s3.7-1.5 4-3.4h3.2l4.7 4.7-3.1 3.1-.8-.9zm2.2 2.3-.9-.9 3.1-3.1.9.9-3.1 3.1z'></path>
    </svg>
  )
}

const PlayerDumbbell = () => {
  return (
    <svg
      className='svg-icon mr-4 h-8 w-8 fill-current text-blue-500'
      width='1.2em'
      height='1.2em'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 30 26.6'
    >
      <path d='m27.8 7-2.5-2.5.3-.3c.3-.2.4-.5.4-.8 0-.3-.1-.6-.4-.9-.2-.2-.5-.4-.9-.4s-.6.1-.9.4l-.3.3L21.2.5c-.3-.3-.7-.5-1.1-.5s-.9.2-1.2.5c-.2.2-.3.3-.4.5-.2-.1-.5-.1-.8-.1-.7 0-1.4.3-1.9.8-1.1 1.1-1.1 2.8 0 3.8l2.6 2.6-8.6 8.6-2.6-2.6c-.5-.5-1.2-.8-1.9-.8A2.732 2.732 0 0 0 2.6 16c0 .3 0 .5.1.8-.2.1-.4.2-.5.4-.3.3-.5.7-.5 1.2s.2.9.5 1.2l2.3 2.3-.3.3c-.2.2-.4.5-.4.9 0 .3.1.6.4.9.2.2.5.4.9.4.3 0 .6-.1.9-.4l.3-.3 2.5 2.5c.3.3.8.5 1.2.5.4 0 .9-.2 1.2-.5.2-.2.3-.3.4-.5.2.1.5.1.8.1.7 0 1.4-.3 1.9-.8 1.1-1.1 1.1-2.8 0-3.8l-2.7-2.7 8.6-8.6 2.7 2.7c.5.5 1.2.8 1.9.8s1.4-.3 1.9-.8c.7-.7 1-1.8.7-2.7.2-.1.4-.2.5-.4.3-.3.5-.7.5-1.2-.1-.5-.3-.9-.6-1.3zm-3.4-4c.2-.2.5-.2.7 0 .2.2.2.5 0 .7l-.3.3-.7-.7.3-.3zM5.5 23.4c-.2.2-.5.2-.7 0-.2-.2-.2-.5 0-.7l.2-.3.7.7-.2.3zm7.7.5c-.5.5-1.3.5-1.8 0l-.2-.2c-.3-.3-.7-.3-1 0-.3.3-.3.7 0 1 .1.1.1.3 0 .4-.1.1-.3.1-.4 0l-6.5-6.5c-.1-.1-.1-.2-.1-.2s0-.1.1-.2.1-.1.2-.1.2 0 .2.1c.3.3.7.3 1 0 .1-.1.2-.3.2-.5s-.1-.4-.2-.5l-.2-.2c-.5-.5-.5-1.3 0-1.8.2-.2.6-.4.9-.4.3 0 .7.1.9.4l7 7c.4.4.4 1.2-.1 1.7zm-2.2-6-.7-.7 8.6-8.6.7.7-8.6 8.6zm15.8-9.4c-.1.1-.3.1-.4 0-.3-.3-.7-.3-1 0-.3.3-.3.7 0 1l.2.2c.5.5.5 1.3 0 1.8-.2.2-.6.4-.9.4-.3 0-.7-.1-.9-.4l-7-7c-.5-.5-.5-1.3 0-1.8.2-.2.6-.4.9-.4.3 0 .7.1.9.4l.2.2c.3.3.7.3 1 0 .3-.3.3-.7 0-1-.1-.1-.1-.3 0-.4.1-.1.2-.1.2-.1s.1 0 .2.1L26.8 8c.1.1.1.2.1.2s0 .2-.1.3z'></path>
      <path d='M21 3.7c-.1-.1-.4-.1-.5 0-.1.1-.1.4 0 .5l3.3 3.3c.1.1.2.1.2.1s.2 0 .2-.1c.1-.1.1-.4 0-.5L21 3.7zM5.3 18.9c-.1.1-.1.4 0 .5l3.3 3.3c.1.1.2.1.2.1.1 0 .2 0 .2-.1.1-.1.1-.4 0-.5l-3.3-3.3c0-.2-.2-.2-.4 0z'></path>
    </svg>
  )
}

const PlayerDocumentsSvg = () => {
  return (
    <svg
      className='svg-icon mr-4 h-8 w-8 fill-current text-blue-500'
      width='1.2em'
      height='1.2em'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 30 26.6'
    >
      <path d='M17.8 17.4c1.9 0 3.5-1.6 3.5-3.5V5.6c0-1.9-1.6-3.5-3.5-3.5H3.5C1.6 2.1 0 3.6 0 5.6v8.3c0 1.9 1.6 3.5 3.5 3.5h3.3l-1.1 3.2c-.1.3 0 .5.2.7.1.1.3.1.4.1.1 0 .2 0 .3-.1l6-4h5.2zm-5.7-1.1-4.6 3 .8-2.3c.1-.2 0-.4-.1-.6-.1-.2-.3-.3-.5-.3H3.5c-1.2 0-2.2-1-2.2-2.2V5.6c0-1.2 1-2.2 2.2-2.2h14.3c1.2 0 2.2 1 2.2 2.2v8.3c0 1.2-1 2.2-2.2 2.2h-5.3c-.1.1-.2.1-.4.2z'></path>
      <path d='M26.5 6.2h-2.8c-.3 0-.6.3-.6.6s.3.6.6.6h2.8c1.2 0 2.2 1 2.2 2.2v8.3c0 1.2-1 2.2-2.2 2.2h-4.2c-.2 0-.4.1-.5.3-.1.2-.1.4-.1.6l.8 2.3-4.6-3c-.1-.1-.2-.1-.3-.1h-5.3c-.3 0-.6.3-.6.6s.3.6.6.6h5.1l6 4c.1.1.2.1.3.1.1 0 .3 0 .4-.1.2-.2.3-.5.2-.7l-1.1-3.2h3.3c1.9 0 3.5-1.6 3.5-3.5V9.7c0-1.9-1.6-3.5-3.5-3.5zM16.4 6.9H4.8c-.2 0-.3.1-.3.3s.1.3.3.3h11.7c.2 0 .3-.1.3-.3s-.2-.3-.4-.3zM16.4 9.5H4.8c-.2 0-.3.1-.3.3 0 .2.1.3.3.3h11.7c.2 0 .3-.1.3-.3-.1-.2-.2-.3-.4-.3zM16.4 12H4.8c-.2 0-.3.1-.3.3 0 .2.1.3.3.3h11.7c.2 0 .3-.1.3-.3-.1-.1-.2-.3-.4-.3z'></path>
    </svg>
  )
}

const VolleyballIcon = () => {
  return (
    <svg
      className='svg-icon mr-4 h-8 w-8 fill-current text-blue-500'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 30 26.6'
    >
      <path d='M15 24.2c6.7 0 12.1-5.4 12.1-12.1S21.7 0 15 0 2.9 5.4 2.9 12.1 8.3 24.2 15 24.2zM4.4 13.7c2.5 3.4 6.3 3.9 8.3 3.9h.3c-.8 1.3-2 2.6-3.9 3.4-2.5-1.6-4.2-4.2-4.7-7.3zm6.2-3.1c-.1-1.4-.1-6.7 4.9-9.2 1.4.1 2.7.4 3.9.9-4.5 2.4-5.1 7.1-5.1 9-.8.1-2.2-.1-3.7-.7zm14.1 5.9c-2.4-4.5-7.1-5.1-9-5.1 0-.8.2-2.4.9-3.9 1.5-.1 6.7 0 9.1 5-.1 1.4-.4 2.8-1 4zm-9-3.7c.8 0 2.1.2 3.6.7.1 1 .4 6.6-4.7 9.3-1.4-.1-2.8-.4-4-.9 4.5-2.5 5.1-7.2 5.1-9.1zm.3 10c3.9-2.8 4.1-7.3 4-8.9 1.5.7 3 2 3.9 4.2-1.7 2.5-4.6 4.3-7.9 4.7zm9.6-11.9c-2.6-3.8-6.8-4.2-8.7-4.2.8-1.4 2-2.7 4-3.5 2.6 1.7 4.4 4.5 4.7 7.7zM14 1.5c-3.9 2.6-4.2 7-4.2 8.8-1.4-.8-2.8-2-3.7-4.1 1.8-2.6 4.6-4.4 7.9-4.7zM5.3 7.7c2.5 4.5 7.2 5.1 9 5.1 0 .9-.2 2.4-.9 4-1.4.2-6.6.2-9.1-4.6v-.1c0-1.6.3-3.1 1-4.4zM26.4 25.2H3.6c-.4 0-.7.3-.7.7 0 .4.3.7.7.7h22.8c.4 0 .7-.3.7-.7 0-.4-.3-.7-.7-.7z'></path>
    </svg>
  )
}

const VolleyTransfers = () => {
  return (
    <svg
      className='svg-icon mr-4 h-8 w-8 fill-current text-blue-500'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 30 26.6'
    >
      <path d='M17.2 10.4c0 .4.3.7.7.7s.7-.3.7-.7V.7c0-.4-.3-.7-.7-.7H8.2c-.4 0-.7.3-.7.7s.3.7.7.7h8L1.9 15.7c-.3.3-.3.7 0 1 .1.1.3.2.5.2s.4-.1.5-.2L17.2 2.4v8zM28.1 9.9c-.3-.3-.7-.3-1 0L12.8 24.2v-8c0-.4-.3-.7-.7-.7s-.7.3-.7.7v9.7c0 .4.3.7.7.7h9.7c.4 0 .7-.3.7-.7 0-.4-.3-.7-.7-.7h-8l14.3-14.3c.3-.3.3-.7 0-1z'></path>
    </svg>
  )
}

const DefaultPhoto = ({ className }: IconProps) => {
  return (
    <svg
      className={classNames(className, 'text-gray-300')}
      fill='currentColor'
      viewBox='0 0 24 24'
    >
      <path d='M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z'></path>
    </svg>
  )
}

const ContactIcon = () => {
  return (
    <svg
      className='svg-icon mr-4 h-8 w-8 fill-current text-blue-500'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 30 26.6'
    >
      <path d='M17.8 17.4c1.9 0 3.5-1.6 3.5-3.5V5.6c0-1.9-1.6-3.5-3.5-3.5H3.5C1.6 2.1 0 3.6 0 5.6v8.3c0 1.9 1.6 3.5 3.5 3.5h3.3l-1.1 3.2c-.1.3 0 .5.2.7.1.1.3.1.4.1.1 0 .2 0 .3-.1l6-4h5.2zm-5.7-1.1-4.6 3 .8-2.3c.1-.2 0-.4-.1-.6-.1-.2-.3-.3-.5-.3H3.5c-1.2 0-2.2-1-2.2-2.2V5.6c0-1.2 1-2.2 2.2-2.2h14.3c1.2 0 2.2 1 2.2 2.2v8.3c0 1.2-1 2.2-2.2 2.2h-5.3c-.1.1-.2.1-.4.2z'></path>
      <path d='M26.5 6.2h-2.8c-.3 0-.6.3-.6.6s.3.6.6.6h2.8c1.2 0 2.2 1 2.2 2.2v8.3c0 1.2-1 2.2-2.2 2.2h-4.2c-.2 0-.4.1-.5.3-.1.2-.1.4-.1.6l.8 2.3-4.6-3c-.1-.1-.2-.1-.3-.1h-5.3c-.3 0-.6.3-.6.6s.3.6.6.6h5.1l6 4c.1.1.2.1.3.1.1 0 .3 0 .4-.1.2-.2.3-.5.2-.7l-1.1-3.2h3.3c1.9 0 3.5-1.6 3.5-3.5V9.7c0-1.9-1.6-3.5-3.5-3.5zM16.4 6.9H4.8c-.2 0-.3.1-.3.3s.1.3.3.3h11.7c.2 0 .3-.1.3-.3s-.2-.3-.4-.3zM16.4 9.5H4.8c-.2 0-.3.1-.3.3 0 .2.1.3.3.3h11.7c.2 0 .3-.1.3-.3-.1-.2-.2-.3-.4-.3zM16.4 12H4.8c-.2 0-.3.1-.3.3 0 .2.1.3.3.3h11.7c.2 0 .3-.1.3-.3-.1-.1-.2-.3-.4-.3z'></path>
    </svg>
  )
}

const EmailIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      strokeWidth={1.5}
      stroke='currentColor'
      className='h-4 w-4'
    >
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75'
      />
    </svg>
  )
}

export {
  SpinningCircle,
  SpinningCircleSmall,
  ContactIcon,
  PlayerDumbbell,
  VolleyTransfers,
  PlayerDocumentsSvg,
  TrashCan,
  Pencil,
  LinkIcon,
  VolleyballIcon,
  MagnifyingGlass,
  PlayerJersey,
  DefaultPhoto,
  EmailIcon,
}
