import { MouseEvent, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  Row,
  useReactTable,
} from '@tanstack/react-table'
import { LinkIcon, TrashCan } from '../../assets/SvgIcons'
import { Link } from 'react-router-dom'
import { Player } from '../../models/Player'
import TableImage from '../shared/TableImage'
import classNames from '../../utils/classNames'
import ConfirmationModal from '../shared/ConfirmationModal'

type CardIndexPlayersTableProps = {
  data: Player[]
}

export default function CardIndexPlayersTable({
  data,
}: CardIndexPlayersTableProps) {
  const columnVisibility = { number: false, photoId: false, id: false }
  const columnHelper = createColumnHelper<Player>()
  const navigate = useNavigate()

  const handleDelete = (id: string) => {
    console.log('delete', id)
  }

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.id, {
        id: 'id',
        header: () => <span>id</span>,
      }),
      columnHelper.accessor((row) => row.number, {
        id: 'number',
        header: () => <span>number</span>,
      }),
      columnHelper.accessor((row) => row.photoId, {
        id: 'photoId',
        header: () => <span>photoId</span>,
      }),
      columnHelper.accessor(
        (row) => `${row.firstName} ${row.middleName} ${row.lastName}`,
        {
          id: 'name',
          cell: (info) => {
            return (
              <div className='flex items-center'>
                <div className='h-11 w-11 flex-shrink-0'>
                  <TableImage
                    className='h-11 w-11 rounded-full object-cover object-center '
                    imageType='person'
                    photoId={info.row.getValue<string>('photoId')}
                  />
                </div>
                <div className='ml-4'>
                  <div className='flex cursor-pointer items-center gap-2 font-medium text-gray-700 hover:text-blue-500'>
                    <Link to={`/players/${info.row.getValue('id')}`}>
                      {info.renderValue()}
                    </Link>
                    <button
                      onClick={() =>
                        navigate(`/players/${info.row.getValue('id')}`)
                      }
                      className='rounded-full bg-blue-50 p-1 text-blue-500 transition-colors hover:bg-blue-500 hover:text-white'
                    >
                      <LinkIcon />
                    </button>
                  </div>
                  <div className='text-gray-400'>
                    СЕК Номер: {info.row.getValue('number')}
                  </div>
                </div>
              </div>
            )
          },
          header: () => <span>Име</span>,
        },
      ),
      columnHelper.accessor((row) => row.birthDate, {
        id: 'birthDate',
        cell: (info) =>
          new Date(info.getValue()).toLocaleDateString('bg-BG', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          }),
        header: () => <span>Рождена дата</span>,
      }),
      columnHelper.accessor((row) => row.nationality, {
        id: 'nationality',
        cell: (info) => info.getValue(),
        header: () => <span>Националност</span>,
      }),

      columnHelper.accessor((row) => row.lastName, {
        id: 'actions',
        cell: (info) => {
          return (
            <ConfirmationModal modalType='delete-player-from-cardindex'>
              {(confirm: any) => (
                <button
                  type='button'
                  className='rounded bg-white px-2 py-1 text-xs font-semibold text-red-300 shadow-sm ring-1 ring-inset ring-red-300 hover:bg-red-50'
                  onClick={confirm(() => handleDelete(info.row.getValue('id')))}
                >
                  <TrashCan />
                  <span className='sr-only'>
                    ,{`${info.row.getValue('name')}`}
                  </span>
                </button>
              )}
            </ConfirmationModal>
          )
        },
        header: () => <span className='sr-only'>Действия</span>,
      }),
    ],
    [],
  )

  const handleClick = (e: MouseEvent, row: Row<Player>) => {
    e.preventDefault()
    if (e.detail === 2) {
      navigate(`/players/${row.original.id}`)
    }
  }

  const table = useReactTable({
    data,
    columns,
    state: {
      columnVisibility,
    },
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <>
      <div className='px-4 py-6 sm:px-6 lg:px-6'>
        <div className='sm:flex sm:items-center'>
          <div className='sm:flex-auto'>
            <h1 className='text-base font-semibold leading-6 text-gray-900'>
              Картотекирани състезатели
            </h1>
          </div>
        </div>

        <div className='mt-2 flow-root'>
          <div className='-mx-4 -my-2 overflow-x-auto sm:-mx-6 md:-mx-6'>
            <div className='inline-block min-w-full py-2 align-middle sm:px-6 md:px-6'>
              <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg'>
                <table className='min-w-full divide-y divide-gray-300'>
                  <thead className='bg-gray-50'>
                    {table.getHeaderGroups().map((headerGroup) => (
                      <tr key={headerGroup.id}>
                        {headerGroup.headers.map((header, idx, array) => (
                          <th
                            key={header.id}
                            scope='col'
                            // className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                            className={classNames(
                              'text-left text-sm font-semibold text-gray-900',
                              idx === 0
                                ? 'py-3.5 pl-4 pr-3 text-gray-900 sm:pl-6'
                                : idx === array.length - 1
                                ? 'relative py-3.5 pl-3 pr-4 sm:pr-6'
                                : 'px-3 py-3.5',
                            )}
                          >
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                  header.column.columnDef.header,
                                  header.getContext(),
                                )}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody className='divide-y divide-gray-200 bg-white'>
                    {table.getRowModel().rows.map((row) => (
                      <tr
                        key={row.id}
                        className='cursor-pointer hover:bg-gray-50'
                        onClick={(e) => handleClick(e, row)}
                      >
                        {row.getVisibleCells().map((cell, idx, array) => (
                          <td
                            key={cell.id}
                            // className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'
                            className={classNames(
                              'whitespace-nowrap text-sm',
                              idx === 0
                                ? 'py-4 pl-4 pr-3 text-gray-900 sm:pl-6'
                                : idx === array.length - 1
                                ? 'py-4 pl-3 pr-4 text-right text-gray-500 sm:pr-6'
                                : 'px-3 py-4 text-gray-500',
                            )}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
