import { useEffect, useState } from 'react'
import { MagnifyingGlass } from '../../assets/SvgIcons'
import { useTransfersQuery } from '../../hooks/useTransfersData'
import { Transition } from '@headlessui/react'
import TablePlaceholder from '../shared/TablePlaceholder'
import TransferTable from './TransferTable'
import { useNavigate } from 'react-router'

const TransferList: React.FC = () => {
  const navigate = useNavigate()
  const { data: transfers, isError, isLoading } = useTransfersQuery()

  const [globalFilter, setGlobalFilter] = useState('')
  const [filterInput, setFilterInput] = useState('')

  useEffect(() => {
    const debounce = setTimeout(() => {
      setGlobalFilter(filterInput)
    }, 750)

    return () => clearTimeout(debounce)
  }, [filterInput])

  if (isError) return <div>Error</div>

  return (
    <>
      <div className='sm:flex sm:items-center'>
        <div className='sm:flex-auto'>
          <h1 className='text-xl font-semibold text-gray-900'>Трансфери</h1>
          <p className='mt-2 text-sm text-gray-700'>
            Списък на всички трансфери
          </p>
          <div className='relative mt-2 mr-24 w-96'>
            <div className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3'>
              <MagnifyingGlass />
            </div>
            <input
              value={filterInput ?? ''}
              onChange={(e) => setFilterInput(e.target.value)}
              type='search'
              id='main-search'
              className='block w-full rounded-lg border border-gray-300 bg-gray-50 p-2 pl-10 text-sm text-gray-900 focus:border-gray-500 focus:ring-gray-500 '
              placeholder='Търсене на трансфери...'
            />
          </div>
        </div>
        <div className='mt-4 sm:mt-0 sm:ml-16 sm:flex-none'>
          <button
            type='button'
            onClick={() => navigate('/transfers/create')}
            className='inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto'
          >
            Започни трансфер
          </button>
        </div>
      </div>

      <Transition
        appear={true}
        show={true}
        enter='transition-opacity duration-75'
        enterFrom='opacity-0'
        enterTo='opacity-100'
        leave='transition-opacity duration-150'
        leaveFrom='opacity-100'
        leaveTo='opacity-0'
      >
        {!isLoading ? (
          <TransferTable
            data={transfers}
            filter={globalFilter}
            setFilter={setGlobalFilter}
          />
        ) : (
          <TablePlaceholder />
        )}
      </Transition>
    </>
  )
}

export default TransferList
