import { useParams } from 'react-router-dom'
import { useTransferQuery } from '../../hooks/useTransfersData'
import TransferDetailsCard from './TransferDetailsCard'
import TransferDetailsClubCard from './TransferDetailsClubCard'
import TransferDetailsPlayerCard from './TransferDetailsPlayerCard'
import TransferDocumentList from './TransferDocumentList'

const TransferDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>() as { id: string }

  const { data: transfer, isLoading, isError } = useTransferQuery(id)

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (isError) {
    return <div>Грешка при извличане на данни за трансфер</div>
  }

  if (!transfer) {
    return <div>Трансферът не е открит</div>
  }

  return (
    <>
      <TransferDetailsCard transfer={transfer} />

      <div className='mt-5 flex flex-col gap-5 lg:flex-row'>
        {transfer.player && (
          <div className='w-auto lg:w-1/3'>
            <TransferDetailsPlayerCard
              cardTitle='Състезател'
              player={transfer.player}
            />
          </div>
        )}

        <div className='flex flex-grow flex-col gap-5'>
          {transfer.fromClub === null ? null : (
            <div className='flex-grow'>
              <TransferDetailsClubCard
                cardTitle='Отдаващ клуб'
                club={transfer.fromClub}
                transferId={transfer.id}
                isConfirmed={transfer.isConfirmedByFromClub}
                confirmationDate={transfer.fromClubConfirmationDate}
              />
            </div>
          )}

          {transfer.toClub === null ? null : (
            <div className='flex-grow'>
              <TransferDetailsClubCard
                cardTitle='Приемащ клуб'
                club={transfer.toClub}
                transferId={transfer.id}
                isConfirmed={transfer.isConfirmedByToClub}
                confirmationDate={transfer.toClubConfirmationDate}
              />
            </div>
          )}
        </div>
      </div>
      <TransferDocumentList id={transfer.id} />
    </>
  )
}

export default TransferDetails
