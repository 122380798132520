import { Form, Input } from '../../core/ui/TextField'
import Button from '../../core/ui/Button'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  ClubUpdateFormSchema,
  ClubUpdateFormSchemaType,
} from '../../../models/FormDataSchemas'

type ClubUpdateFormProps = {
  initialData: ClubUpdateFormSchemaType
  onSubmit: (data: ClubUpdateFormSchemaType) => void
}

const formElements = [
  { name: 'title', label: 'Правна форма', type: 'text' },
  { name: 'type', label: 'ВК/СКВ/СК', type: 'text' },
  { name: 'name', label: 'Име', type: 'text' },
  {
    name: 'licenseNumber',
    label: 'Номер в регистъра на ММС',
    type: 'text',
  },
  { name: 'bulstat', label: 'Булстат/ЕИК', type: 'text' },
  { name: 'address', label: 'Адрес', type: 'text' },
  { name: 'contactNumber', label: 'Телефон', type: 'text' },
  { name: 'contactPerson', label: 'Лице за контакт', type: 'text' },
  { name: 'email', label: 'E-mail', type: 'text' },
  { name: 'president', label: 'Президент', type: 'text' },
  { name: 'websiteUrl', label: 'URL към уебсайта на клуба', type: 'text' },
  { name: 'facebookUrl', label: 'URL към Facebook страницата', type: 'text' },
  { name: 'xUrl', label: 'URL към Twitter страницата', type: 'text' },
  { name: 'instagramUrl', label: 'URL към Instagram страницата', type: 'text' },
  { name: 'youtubeUrl', label: 'URL към YouTube канала', type: 'text' },
  { name: 'linkedInUrl', label: 'URL към LinkedIn страницата', type: 'text' },
  { name: 'tiktokUrl', label: 'URL към TikTok страницата', type: 'text' },
]

const ClubUpdateForm = ({ initialData, onSubmit }: ClubUpdateFormProps) => {
  const validation = zodResolver(ClubUpdateFormSchema)

  return (
    <Form<ClubUpdateFormSchemaType>
      onSubmit={onSubmit}
      initialData={initialData}
      resolver={validation}
    >
      {({ register, formState: { errors, isSubmitting } }) => (
        <>
          <div className='grid grid-cols-6 gap-6'>
            {formElements.map((element, idx) => (
              <div key={idx} className='col-span-6'>
                <Input
                  {...register(element.name as keyof ClubUpdateFormSchemaType)}
                  type={element.type}
                  name={element.name}
                  label={element.label}
                  error={
                    errors[element.name as keyof ClubUpdateFormSchemaType]
                      ?.message
                  }
                />
              </div>
            ))}

            <Button type='submit' disabled={isSubmitting}>
              Запази
            </Button>
          </div>
        </>
      )}
    </Form>
  )
}

export default ClubUpdateForm
