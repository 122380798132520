import { UserFormSchemaType } from '../../models/FormDataSchemas'
import { InputField } from '../shared/Inputs'
import {
  FieldErrors,
  Path,
  SubmitHandler,
  UseFormHandleSubmit,
  UseFormRegister,
} from 'react-hook-form'

const formTemplate = {
  create: {
    heading: 'Добавяне на потребител',
    description:
      'След като добавите потребителя, ще имате възможност да добавите клубове до които той има достъп.',
    submitText: 'Добави',
  },
  update: {
    heading: 'Редактиране на потребител',
    description: "Редактирайте потребителя и натиснете 'Запази'",
    submitText: 'Запази',
  },
}

type UserFormProps<T extends UserFormSchemaType> = {
  register: UseFormRegister<T>
  handleSubmit: UseFormHandleSubmit<T>
  errors: FieldErrors<T>
  isSubmitting: boolean
  onSubmit: SubmitHandler<T>
  formType: keyof typeof formTemplate
  isLoading: boolean
}

const UserForm = function <T extends UserFormSchemaType>({
  register,
  errors,
  handleSubmit,
  isSubmitting,
  onSubmit,
  formType,
  isLoading,
}: UserFormProps<T>) {
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='shadow sm:overflow-hidden sm:rounded-md'>
        <div className='space-y-6 bg-white px-4 py-6 sm:p-6'>
          <div>
            <h3 className='text-lg font-semibold text-gray-900'>
              {formTemplate[formType].heading}
            </h3>
            <p className='mt-1 text-sm text-gray-500'>
              {formTemplate[formType].description}
            </p>
          </div>

          <div className='grid grid-cols-7 gap-6'>
            <div className='col-span-7'>
              <InputField<T>
                label='Потребителско име'
                name={'username' as Path<T>}
                type='text'
                register={register}
                error={errors.username?.message?.toString()}
              />
            </div>
            <div className='col-span-7'>
              <InputField<T>
                label='E-mail'
                name={'email' as Path<T>}
                type='email'
                register={register}
                error={errors.email?.message?.toString()}
              />
            </div>
            <div className='col-span-7'>
              <InputField<T>
                label='Име'
                name={'firstName' as Path<T>}
                type='text'
                register={register}
                error={errors.firstName?.message?.toString()}
              />
            </div>

            <div className='col-span-7'>
              <InputField<T>
                label='Фамилия'
                name={'lastName' as Path<T>}
                type='text'
                register={register}
                error={errors.lastName?.message?.toString()}
              />
            </div>

            <div className='col-span-7'>
              <InputField<T>
                label='Телефон'
                name={'phoneNumber' as Path<T>}
                type='text'
                register={register}
                error={errors.phoneNumber?.message?.toString()}
              />
            </div>
          </div>
        </div>
        <div className='bg-gray-50 px-4 py-3 text-right sm:px-6'>
          <button
            type='submit'
            disabled={isSubmitting || isLoading}
            className='inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:cursor-wait'
          >
            {formTemplate[formType].submitText}
          </button>
        </div>
      </div>
    </form>
  )
}

export default UserForm
