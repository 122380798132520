import { Dispatch, SetStateAction, memo, useEffect, useState } from 'react'
import { useUsersData } from '../../hooks/useUsersData'
import UsersTable from './UsersTable'
import { IUserSearchInput } from '../../models/User'
import { MagnifyingGlass } from '../../assets/SvgIcons'
import { useNavigate } from 'react-router-dom'
import { Transition } from '@headlessui/react'
import TablePlaceholder from '../shared/TablePlaceholder'

export default function ListUsers() {
  const navigate = useNavigate()
  const [userSearchInput, setUserSearchInput] = useState<IUserSearchInput>({
    keyword: '',
    roleid: '',
    page: 1,
    pageSize: 10,
    orderBy: '',
    desc: false,
  })

  const [userFilter, setUserFilter] = useState<string>('')

  useEffect(() => {
    const debounce = setTimeout(() => {
      setUserSearchInput((prev) => ({ ...prev, keyword: userFilter }))
    }, 500)

    return () => clearTimeout(debounce)
  }, [userFilter])

  return (
    <>
      <div className='sm:flex sm:items-center'>
        <div className='sm:flex-auto'>
          <h1 className='text-xl font-semibold text-gray-900'>Потребители</h1>
          <p className='mt-2 text-sm text-gray-700'>
            Списък на потребителите в системата
          </p>
          <div className='relative mt-2 mr-24 w-96'>
            <div className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3'>
              <MagnifyingGlass />
            </div>
            <input
              value={userFilter}
              onChange={(e) => setUserFilter(e.target.value)}
              type='search'
              id='main-search'
              className='block w-full rounded-lg border border-gray-300 bg-gray-50 p-2 pl-10 text-sm text-gray-900 focus:border-gray-500 focus:ring-gray-500 '
              placeholder='Търсене на потребител...'
            />
          </div>
        </div>
        <div className='mt-4 sm:mt-0 sm:ml-16 sm:flex-none'>
          <button
            type='button'
            onClick={() => navigate('/users/create')}
            className='inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto'
          >
            Добави потребител
          </button>
        </div>
      </div>

      <FetchUsers
        userSearchInput={userSearchInput}
        setUserSearchInput={setUserSearchInput}
      />
    </>
  )
}

type FetchUsersProps = {
  userSearchInput: IUserSearchInput
  setUserSearchInput: Dispatch<SetStateAction<IUserSearchInput>>
}

const FetchUsers = memo(
  ({ userSearchInput, setUserSearchInput }: FetchUsersProps) => {
    const { data: users, isLoading, isError } = useUsersData(userSearchInput)

    if (isError) {
      return <div>Error fetching users!</div>
    }

    return (
      <>
        <Transition
          appear={true}
          show={true}
          enter='transition-opacity duration-75'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='transition-opacity duration-150'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          {!isLoading ? (
            <UsersTable
              userSearchResult={users}
              userSearchInput={userSearchInput}
              setUserSearchInput={setUserSearchInput}
            />
          ) : (
            <TablePlaceholder />
          )}
        </Transition>
      </>
    )
  },
)
