import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { transferService } from '../services/TransferService'
import { IPlayerTransferCreateV2 } from '../models/PlayerTransfer'
import { notify } from '../components/shared/Notification'
import { useNavigate } from 'react-router'
import { ICoachTransferCreate } from '../models/CoachTransfer'

export function useTransfersQuery() {
  return useQuery({
    queryKey: ['fetch-transfers'],
    queryFn: transferService.get,
  })
}

export function useTransferQuery(id: string) {
  return useQuery({
    queryKey: ['fetch-transfer', id],
    queryFn: () => transferService.getById(id),
  })
}

export function useTransferDocumentsQuery(id: string) {
  return useQuery({
    queryKey: ['player-transfer-documents-fetch', id],
    queryFn: () => transferService.getDocuments(id),
  })
}

export function usePlayerTransferDocumentTypesData() {
  return useQuery({
    queryKey: ['fetch-player-document-types'],
    queryFn: transferService.getPlayerTransferDocumentTypes,
  })
}

export function useAddPlayerTransferDocument() {
  const client = useQueryClient()

  return useMutation({
    mutationKey: ['player-transfer-document-create'],
    mutationFn: (document: FormData) =>
      transferService.addPlayerTransferDocument(document),
    onSuccess: (res) => {
      client.invalidateQueries({
        queryKey: ['player-transfer-documents-fetch', res.playerTransferId],
      })
      notify.success('Документът е добавен успешно!')
    },
    onError: (err) => {
      console.log(err)
      notify.error('Възникна грешка при добавяне на документ!')
    },
  })
}

export function useTransferCreateQuery() {
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  return useMutation({
    mutationKey: ['transfer-create'],
    mutationFn: (transfer: IPlayerTransferCreateV2) =>
      transferService.create(transfer),
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: ['fetch-transfers'],
      })
      notify.success('Трансферът е добавен успешно!')
      navigate(`/transfers/${res.id}`)
    },
    onError: (err) => {
      console.log(err)
      notify.error('Възникна грешка при добавяне на трансфер!')
    },
  })
}

export function useCoachTransferCreateQuery() {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (coach: ICoachTransferCreate) =>
      transferService.coachTransferCreate(coach),
    onSuccess: (res) => {
      const id = res.coachId.toString()
      queryClient.refetchQueries({
        queryKey: ['fetch-coach', id],
      })
      notify.success('Трансферът е осъществен успешно!')
      navigate('/coaches/' + res.coachId)
    },
    onError: (err) => {
      console.log(err)
      notify.error('Възникна грешка при трансфериране на треньора!')
    },
  })
}

export function useTransferConfirmationAsAdminQuery() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (id: string) => transferService.confirmTransferAsAdmin(id),
    onSuccess: (res) => {
      queryClient.refetchQueries({
        queryKey: ['fetch-transfer', res.id],
      })
      notify.success('Трансферът е потвърден успешно!')
    },
    onError: (err) => {
      console.log(err)
      notify.error(err as string)
    },
  })
}

export function useTransferConfirmationAsClubQuery() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ id, clubId }: { id: string; clubId: number }) =>
      transferService.confirmTransferAsClub(id, clubId),
    onSuccess: (res) => {
      queryClient.refetchQueries({
        queryKey: ['fetch-transfer', res.id],
      })
      notify.success('Трансферът е потвърден успешно!')
    },
    onError: (err) => {
      console.log(err)
      notify.error('Възникна грешка при потвърждаване на трансфера!')
    },
  })
}
