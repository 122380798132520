import { Fragment } from 'react'
import { LinkIcon, PlayerJersey } from '../../assets/SvgIcons'
import Image from '../shared/Image'
import { Link } from 'react-router-dom'
import { Player } from '../../models/Player'

type TransferDetailsPlayerCardProps = {
  player: Player
  cardIcon?: React.ReactNode
  cardTitle?: string
}

const TransferDetailsPlayerCard = ({
  player,
  cardIcon,
  cardTitle,
}: TransferDetailsPlayerCardProps) => {
  return (
    <div className='px-4 sm:px-6 md:px-0'>
      <section className='relative rounded-lg bg-white shadow'>
        <header className='flex items-center justify-between border-b border-gray-200 px-4 py-5 sm:px-6'>
          <div className='inline-flex items-center'>
            {cardIcon ? cardIcon : <PlayerJersey />}
            <h3 className='text-2xl font-medium leading-6 text-gray-900'>
              {cardTitle ? cardTitle : 'Информация за състезателя'}
            </h3>
          </div>
        </header>
        <div className='px-4 py-5 sm:p-6'>
          <div className='grid grid-cols-1 gap-2'>
            <div className='col-span-1 grid items-center justify-center'>
              <Image
                imageType='person'
                photoId={player.photoId}
                className='h-[100px] w-[100px] rounded-full object-cover'
              />
            </div>
            <div className='col-span-1'>
              <span className='block text-sm font-medium text-gray-500'>
                Име на състезтел
              </span>
              <span className='mt-1 block rounded-md bg-gray-50 p-2'>
                <span className='inline-flex items-center gap-1'>
                  <Fragment>
                    {`${player.firstName} ${player.middleName} ${player.lastName}`}
                    <Link to={`/players/${player.id}`}>
                      <div className='transition-color rounded-full bg-blue-50 p-1 text-blue-500 hover:bg-blue-500 hover:text-white'>
                        <LinkIcon />
                      </div>
                    </Link>
                  </Fragment>
                </span>
              </span>
            </div>
            <div className='col-span-1'>
              <span className='block text-sm font-medium text-gray-500'>
                Рождена дата
              </span>
              <span className='mt-1 block rounded-md bg-gray-50 p-2'>
                {player.birthDate
                  ? new Date(player.birthDate).toLocaleDateString('bg-BG', {
                      day: '2-digit',
                      year: 'numeric',
                      month: '2-digit',
                    })
                  : '-'}
              </span>
            </div>
            <div className='col-span-1'>
              <span className='block text-sm font-medium text-gray-500'>
                Националност
              </span>
              <span className='mt-1 block rounded-md bg-gray-50 p-2'>
                {player.nationality || '-'}
              </span>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default TransferDetailsPlayerCard
