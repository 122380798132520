import { useEffect, useState } from 'react'
import PlayerSearch from '../../core/ui/PlayerSearch'
import { PlayerDetailsT } from '../../../models/Player'
import Button from '../../core/ui/Button'
import { useSearchParams } from 'react-router-dom'

const PlayerSelect = () => {
  const [selected, setSelected] = useState<PlayerDetailsT | null>(null)
  const [, setSearchParams] = useSearchParams()

  const handleRemove = () => {
    setSelected(null)
  }

  useEffect(() => {
    if (selected) {
      setSearchParams((prev) => {
        prev.set('playerId', String(selected.id))
        return Array.from(prev.entries())
      })
    } else {
      setSearchParams((prev) => {
        prev.delete('playerId')
        return Array.from(prev.entries())
      })
    }
  }, [selected])

  return (
    <>
      <div className='mt-4'>
        <h2 className='mb-2 text-base font-semibold leading-6 text-gray-900'>
          Избери състезател
        </h2>

        <PlayerSearch selected={selected} setSelected={setSelected} />

        <div className='mt-2 flex gap-1'>
          {selected && <Button onClick={handleRemove}>Промени</Button>}
        </div>
      </div>
    </>
  )
}

export default PlayerSelect
