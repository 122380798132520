import { Link } from 'react-router-dom'
import { LinkIcon, VolleyballIcon } from '../../assets/SvgIcons'
import { usePlayerCardIndexesData } from '../../hooks/usePlayersData'
import { useState } from 'react'

type PlayerCardIndexesProps = {
  id: number
}

const PlayerCardIndexes = ({ id }: PlayerCardIndexesProps) => {
  const {
    isLoading,
    data: PlayerCardIndexes,
    isError,
  } = usePlayerCardIndexesData(id)

  const [showModal, setShowModal] = useState(false)

  if (isError) return <div>Unable to fetch Player Documents data</div>

  if (isLoading) return <div>Loading...</div>

  return (
    <>
      <div className='mt-10 px-4 pb-16 sm:px-6 md:px-0'>
        <section className='relative rounded-lg bg-white shadow'>
          {/* Card Header */}
          <header className='flex items-center justify-between border-b border-gray-200 px-4 py-5 sm:px-6'>
            <div className='inline-flex items-center'>
              <VolleyballIcon />
              <h3 className='text-2xl font-medium leading-6 text-gray-900'>
                Лицензи
              </h3>
            </div>
            <div>
              <button
                type='button'
                onClick={() => setShowModal(true)}
                className='inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto'
              >
                Добави
              </button>
            </div>
          </header>

          {/* Card Body */}
          <div className='px-4 sm:px-6'>
            <div className='flex flex-col'>
              <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-6'>
                <div className='inline-block min-w-full py-2 align-middle'>
                  <div className='overflow-hidden rounded-lg'>
                    <table className='min-w-full divide-y divide-gray-200'>
                      <thead className='bg-gray-50'>
                        <tr className='divide-x divide-gray-200'>
                          <th
                            scope='col'
                            className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8'
                          >
                            Лиценз #
                          </th>
                          <th
                            scope='col'
                            className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                          >
                            Сезон
                          </th>
                          <th
                            scope='col'
                            className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                          >
                            Възраст
                          </th>
                          <th
                            scope='col'
                            className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                          >
                            Клуб
                          </th>
                        </tr>
                      </thead>
                      <tbody className='divide-y divide-gray-200 bg-white'>
                        {PlayerCardIndexes.length > 0 ? (
                          PlayerCardIndexes.map((cardIndex) => (
                            <tr key={cardIndex.id}>
                              <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-500 sm:pl-6 lg:pl-8'>
                                <div className='text-sm'>
                                  <div className='peer flex gap-2 text-gray-700'>
                                    <div className='font-medium transition-colors'>
                                      {cardIndex.id}
                                    </div>
                                    <Link to={`/cardIndexes/${cardIndex.id}`}>
                                      <div className='rounded-full bg-blue-50 p-1 text-blue-500 transition-colors hover:bg-blue-500 hover:text-white'>
                                        <LinkIcon />
                                      </div>
                                    </Link>
                                  </div>
                                </div>
                              </td>
                              <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                {`${cardIndex.year} / ${cardIndex.year + 1}`}
                              </td>
                              <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                {cardIndex.ageGroup}
                              </td>
                              <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                <span className='cursor-pointer rounded-full bg-blue-50 px-3 py-2 text-xs font-semibold text-blue-500 hover:underline'>
                                  <Link to={`/clubs/${cardIndex.clubId}`}>
                                    {cardIndex.club?.fullName}
                                  </Link>
                                </span>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr className='divide-x divide-gray-200'>
                            <td
                              colSpan={7}
                              className='whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500'
                            >
                              <span className='text-center'>
                                Няма добавени данни за лицензи
                              </span>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default PlayerCardIndexes
