import { Transition } from '@headlessui/react'
import { useEffect, useState } from 'react'
import { MagnifyingGlass } from '../../assets/SvgIcons'
import { useClubsData } from '../../hooks/useClubsData'
import ClubsTable from './ClubsTable'
import TablePlaceholder from '../shared/TablePlaceholder'
import { useNavigate } from 'react-router'

const ListClubs = () => {
  const { isLoading, data: clubsData, isError } = useClubsData()
  const [globalFilter, setGlobalFilter] = useState('')
  const [filterInput, setFilterInput] = useState('')
  const navigate = useNavigate()

  useEffect(() => {
    const debounce = setTimeout(() => {
      setGlobalFilter(filterInput)
    }, 750)

    return () => clearTimeout(debounce)
  }, [filterInput])

  if (isError) return <div>Error</div>

  return (
    <>
      <div className='sm:flex sm:items-center'>
        <div className='sm:flex-auto'>
          <h1 className='text-xl font-semibold text-gray-900'>Клубове</h1>
          <p className='mt-2 text-sm text-gray-700'>
            Списък на клубовете в системата
          </p>
          <div className='relative mt-2 mr-24 w-96'>
            <div className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3'>
              <MagnifyingGlass />
            </div>
            <input
              value={filterInput ?? ''}
              onChange={(e) => setFilterInput(e.target.value)}
              type='search'
              id='main-search'
              className='block w-full rounded-lg border border-gray-300 bg-gray-50 p-2 pl-10 text-sm text-gray-900 focus:border-gray-500 focus:ring-gray-500 '
              placeholder='Търсене на клубове...'
            />
          </div>
        </div>
        <div className='mt-4 sm:mt-0 sm:ml-16 sm:flex-none'>
          <button
            type='button'
            className='inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto'
            onClick={() => navigate('/clubs/create')}
          >
            Добави клуб
          </button>
        </div>
      </div>
      <Transition
        appear={true}
        show={true}
        enter='transition-opacity duration-75'
        enterFrom='opacity-0'
        enterTo='opacity-100'
        leave='transition-opacity duration-150'
        leaveFrom='opacity-100'
        leaveTo='opacity-0'
      >
        {!isLoading ? (
          <ClubsTable
            data={clubsData}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        ) : (
          <TablePlaceholder />
        )}
      </Transition>
    </>
  )
}

export default ListClubs
