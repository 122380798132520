type TransferProgressProps = {
  data: {
    isConfirmedByAdmin: boolean
    isConfirmedByPlayer: boolean
    isConfirmedByFromClub: boolean
    isConfirmedByToClub: boolean
  }
}

const TransferProgress = ({ data }: TransferProgressProps) => {
  let progress = 0

  if (data.isConfirmedByAdmin) progress += 25
  if (data.isConfirmedByPlayer) progress += 25
  if (data.isConfirmedByFromClub) progress += 25
  if (data.isConfirmedByToClub) progress += 25

  return (
    <div className='flex gap-2'>
      <div className='w-full self-center rounded-full bg-gray-200'>
        <div
          className='rounded-full bg-blue-500 p-1'
          style={{ width: progress + '%' }}
        ></div>
      </div>
      <span className='font-normal text-gray-500'>{progress}%</span>
    </div>
  )
}

export default TransferProgress
