import logo from '../../assets/logo-gold.png'
import { CardIndexT } from '../../models/CardIndex'
import React from 'react'

type CardIndexPrintProps = {
  cardIndex: CardIndexT
}

const CardIndexPrintContent = React.forwardRef<
  HTMLDivElement,
  CardIndexPrintProps
>(({ cardIndex }: CardIndexPrintProps, ref) => {
  return (
    <div ref={ref} className='p-5'>
      <header className='flex justify-between py-5 text-xs'>
        <img src={logo} alt='Logo' />
        <div className='flex flex-col text-center font-bold uppercase'>
          <h3>
            Отборен лиценз за {cardIndex.year} / {cardIndex.year + 1}
          </h3>
          <h3>{cardIndex.club?.fullName}</h3>
        </div>
        <img src={logo} alt='Logo' />
      </header>

      <header className='py-5 text-center text-xs font-bold uppercase'>
        <h3>Държавно първенство</h3>
        <h3>{cardIndex.ageGroup}</h3>
      </header>

      <section className='max-w-full py-5'>
        <table className='min-w-full border-gray-900 text-xs'>
          <thead>
            <tr>
              <th scope='col' className='border'>
                №
              </th>
              <th scope='col' className='border'>
                Име
              </th>
              <th scope='col' className='border'>
                Презиме
              </th>
              <th scope='col' className='border'>
                Фамилия
              </th>
              <th scope='col' className='border'>
                Рождена дата
              </th>
              <th scope='col' className='border'>
                СЕК Номер
              </th>
              <th scope='col' className='border'>
                Първи периодичен преглед
              </th>
              <th scope='col' className='border'>
                Втори периодичен преглед
              </th>
            </tr>
          </thead>
          <tbody>
            {cardIndex.players?.length ?? 0 > 0 ? (
              cardIndex.players?.map((player, index) => (
                <tr key={index}>
                  <td className='border px-2'>{index + 1}</td>
                  <td className='border px-2'>{player.firstName}</td>
                  <td className='border px-2'>{player.middleName}</td>
                  <td className='border px-2'>{player.lastName}</td>
                  <td className='border px-2'>
                    {new Date(player.birthDate).toLocaleDateString('bg-BG', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    })}
                  </td>
                  <td className='border px-2'>{player.number}</td>
                  <td className='border'></td>
                  <td className='border'></td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={8} className='border text-center'>
                  Няма добавени състезатели
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </section>

      <footer>
        <div className='flex justify-between py-5'>
          <table className='text-xs'>
            <tbody>
              <tr>
                <th className='border p-1 text-right'>Старши треньор:</th>
                <td className='border p-1 text-left'>
                  {cardIndex.seniorCoach?.name}
                </td>
              </tr>
              <tr>
                <th className='border p-1 text-right'>Треньор:</th>
                <td className='border p-1 text-left'>
                  {cardIndex.coach?.name}
                </td>
              </tr>
              <tr>
                <th className='border p-1 text-right'>Втори треньор:</th>
                <td className='border p-1 text-left'>
                  {cardIndex.coach2?.name}
                </td>
              </tr>
            </tbody>
          </table>
          <table className='text-xs'>
            <tbody>
              <tr>
                <th className='border p-1 text-right'>Лекар:</th>
                <td className='border p-1 text-left'>{cardIndex.medic}</td>
              </tr>
              <tr>
                <th className='border p-1 text-right'>Масажист:</th>
                <td className='border p-1 text-left'>{cardIndex.masseur}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className='flex justify-between py-5'>
          <span>Заверил:</span>
          <span>Печат:</span>
          <span>Дата:</span>
        </div>
      </footer>
    </div>
  )
})

export default CardIndexPrintContent
