import { useParams } from 'react-router'
import { usePlayerData, usePlayerUpdate } from '../../hooks/usePlayersData'
import PlayerUpdateForm from './Forms/PlayerUpdateForm'
import { PlayerUpdateFormSchemaType } from '../../models/FormDataSchemas'

const PlayerUpdate = () => {
  const { id } = useParams<{ id: string }>()
  const { data: playerData, isLoading, isError } = usePlayerData(id)

  const playerUpdate = usePlayerUpdate(id as string)

  const handleSubmit = (data: PlayerUpdateFormSchemaType): void => {
    const formData = new FormData()
    for (const key in data) {
      formData.append(key, data[key as keyof PlayerUpdateFormSchemaType])
    }

    playerUpdate.mutate(formData)
  }

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (isError) {
    return <div>Error fetching player</div>
  }

  const initialData = {
    firstName: playerData.firstName,
    middleName: playerData.middleName,
    lastName: playerData.lastName,
    egn: playerData.egn,
    nationality: playerData.nationality,
    cityName: playerData.city.name,
  } as PlayerUpdateFormSchemaType

  return (
    <div>
      <div className='shadow sm:overflow-hidden sm:rounded-md'>
        <div className='space-y-6 bg-white px-4 py-6 sm:p-6'>
          <div>
            <h3 className='text-base font-semibold leading-6 text-gray-900'>
              Редактиране на състезател
            </h3>
            <p className='mt-1 text-sm text-gray-500'>
              Моля използвайте валидни данни по лична карта.
            </p>
          </div>
          <PlayerUpdateForm initialData={initialData} onSubmit={handleSubmit} />
        </div>
      </div>
    </div>
  )
}

export default PlayerUpdate
